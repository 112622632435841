<template>
  <div class="merite-block-textimage">
    <div class="merite-block-textimage-flex">
      <SiteHeading :title="title" :color="color" />

      <div class="merite-block-textcontainer">
        <slot></slot>
      </div>
    </div>
    <div class="merite-block-textimage-flex"></div>
  </div>
</template>

<script>
import SiteHeading from "@/components/widgets/SiteHeading.vue";
import SiteText from "@/components/widgets/SiteText.vue";

export default {
  name: "BlockTextImage",
  props: {
    title: {
      type: String,
      default: "undefined",
    },
    color: {
      type: String,
      default: "#FF0000",
    },
  },
  components: {
    SiteHeading,
    SiteText,
  },
};
</script>

<style>
.merite-block-textimage {
  min-height: 5vh;

  display: flex;
  flex-direction: row;

  margin: 6em 0 6em 0;
}

.merite-block-textimage-flex {
  flex: 1;
}

.merite-block-textcontainer {
  margin: 0em 1em 4em 15vw;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  padding-right: 5vw;
  padding-bottom: 5em;

  color: var(--charte-color-grey-2);
}

.merite-block-textimage-flex .merite-siteheading-container {
  margin: 6em 0 4em 0 !important;
}

.merite-block-textimage-flex:nth-child(1) {
  flex-grow: 1.5;
}

@media only screen and (max-width: 600px) {
  .merite-block-textimage {
    flex-direction: column !important;
    margin: 0 !important;
  }
}
</style>