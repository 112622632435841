<template>
  <div class="merite-calendar">
    <Container>
      <div class="yearlist-desktop">
        <div class="merite-calendar-year arrow" @click="changeYear(-1)">&lt;</div>
        <div class="merite-calendar-year">{{ currentYear }}</div>
        <div class="merite-calendar-year arrow" @click="changeYear(1)">&gt;</div>
      </div>
      <div class="yearlist-mobile">
        <div class="merite-calendar-year arrow" @click="changeMonthMobile(-1)">&lt;</div>
        <div class="merite-calendar-year month">
          <h2>{{ currentMonth }}</h2>
          <h3>{{ currentYear }}</h3>
        </div>
        <div class="merite-calendar-year arrow" @click="changeMonthMobile(1)">&gt;</div>
      </div>
      <div class="merite-calendar-container">
        <div class="merite-calendar-inner-container">
          <div class="merite-calendar-inner-flex monthlist-desktop">
            <ul class="merite-calendar-month-list">
              <li :class="{ active: currentMonth == 1 }" @click="changeMonth(1)">{{ schema.month1 }}</li>
              <li :class="{ active: currentMonth == 2 }" @click="changeMonth(2)">{{ schema.month2 }}</li>
              <li :class="{ active: currentMonth == 3 }" @click="changeMonth(3)">{{ schema.month3 }}</li>
              <li :class="{ active: currentMonth == 4 }" @click="changeMonth(4)">{{ schema.month4 }}</li>
              <li :class="{ active: currentMonth == 5 }" @click="changeMonth(5)">{{ schema.month5 }}</li>
              <li :class="{ active: currentMonth == 6 }" @click="changeMonth(6)">{{ schema.month6 }}</li>
              <li :class="{ active: currentMonth == 7 }" @click="changeMonth(7)">{{ schema.month7 }}</li>
              <li :class="{ active: currentMonth == 8 }" @click="changeMonth(8)">{{ schema.month8 }}</li>
              <li :class="{ active: currentMonth == 9 }" @click="changeMonth(9)">{{ schema.month9 }}</li>
              <li :class="{ active: currentMonth == 10 }" @click="changeMonth(10)">{{ schema.month10 }}</li>
              <li :class="{ active: currentMonth == 11 }" @click="changeMonth(11)">{{ schema.month11 }}</li>
              <li :class="{ active: currentMonth == 12 }" @click="changeMonth(12)">{{ schema.month12 }}</li>
            </ul>
          </div>
          <div class="merite-calendar-inner-flex">
            <div class="merite-calendar-upper">
              <div class="merite-calendar-inner-day-column">{{ schema.week1 }}</div>
              <div class="merite-calendar-inner-day-column">{{ schema.week2 }}</div>
              <div class="merite-calendar-inner-day-column">{{ schema.week3 }}</div>
              <div class="merite-calendar-inner-day-column">{{ schema.week4 }}</div>
              <div class="merite-calendar-inner-day-column">{{ schema.week5 }}</div>
              <div class="merite-calendar-inner-day-column">{{ schema.week6 }}</div>
              <div class="merite-calendar-inner-day-column">{{ schema.week7 }}</div>
            </div>
            <div class="merite-calendar-lower">
              <div class="merite-calendar-inner-day-column" v-for="day in currentDays" :key="day"
                :class="{ active: day['active'], greyed: day['greyed'] }">
                <ul @click="getAgendaData(day['date'])">
                  <li class="benevolat" v-if="day['colors']['benevolat']">.</li>
                  <li class="talent" v-if="day['colors']['talent']">.</li>
                  <li class="sport" v-if="day['colors']['sport']">.</li>
                  <li class="residentiel" v-if="day['colors']['residentiel']">.</li>
                  <li class="expedition" v-if="day['colors']['expedition']">.</li>
                </ul>
                <h2 class="merite-calendar-day" :class="{ selected: day['date'] == selectedDate }"
                  @click="getAgendaData(day['date'])">
                  {{ day["day"] }}
                </h2>
              </div>
            </div>
          </div>
          <div class="merite-calendar-inner-flex">
            <ul class="merite-calendar-fullitem-list">
              <li v-for="agendaData in displayAgendaData" :key="agendaData">
                <div class="merite-calendar-fullitem">
                  <h2 class="merite-calendar-fullitem-date" :class="agendaData['category']">{{ agendaData["date"] }}</h2>
                  <h2 class="merite-calendar-fullitem-title">{{ agendaData["title"] }}</h2>
                  <div class="merite-calendar-fullitem-paragraph" v-html="agendaData['description']"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "@/components/widgets/Container.vue";
import { marked } from 'marked';

export default {
  name: "Calendar",
  components: {
    Container,
  },
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      currentMonth: 0,
      currentYear: 0,
      currentDays: [],
      storedDocuments: [],
      selectedDate: 0,
      displayAgendaData: [],
    };
  },
  created() {
    var date = new Date();
    var month = date.getMonth();

    this.currentYear = date.getFullYear();
    this.currentMonth = month + 1;

    let locale = this.$t("locale");
    fetch(process.env.VUE_APP_MERITECMS_URL + "page-agenda?_locale=" + locale)
      .then((res) => res.json())
      .then((data) => {
        this.displayDays(data);
      });
  },
  methods: {
    changeMonth: function (month) {
      this.currentMonth = month;
      this.displayDays();
    },
    displayDays: function (documents) {
      this.currentDays = [];

      var date = new Date();
      var month = this.currentMonth - 1;

      var firstDay = new Date(this.currentYear, month, 1);
      var lastDay = new Date(this.currentYear, month + 1, 0);

      var firstWeekDay = firstDay.getDay() - 1;
      var lastWeekDay = lastDay.getDay() - 1;

      if (firstWeekDay < 0) firstWeekDay = 6;
      if (lastWeekDay < 0) lastWeekDay = 6;

      var date = new Date(this.currentYear, this.currentMonth - 1, 1);
      var currentDays = [];
      while (date.getMonth() === this.currentMonth - 1) {
        currentDays.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }

      if (this.currentMonth - 2 == -1) {
        var date = new Date(this.currentYear - 1, 11, 1);
        var lastDays = [];
        while (date.getMonth() === 11) {
          lastDays.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      } else {
        var date = new Date(this.currentYear, this.currentMonth - 2, 1);
        var lastDays = [];
        while (date.getMonth() === this.currentMonth - 2) {
          lastDays.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      }

      if (this.currentMonth == 12) {
        var date = new Date(this.currentYear + 1, 0, 1);
        var nextDays = [];
        while (date.getMonth() === 0) {
          nextDays.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      } else {
        var date = new Date(this.currentYear, this.currentMonth, 1);
        var nextDays = [];
        while (date.getMonth() === this.currentMonth) {
          nextDays.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      }

      for (var i = 0; i < firstWeekDay; i++) {
        currentDays.unshift(lastDays[lastDays.length - i - 1]);
      }

      var lastI = 0;
      for (var i = 0; i < 6 - lastWeekDay; i++) {
        currentDays.push(nextDays[i]);
        lastI = i;
      }

      var tempLength = currentDays.length;
      if (tempLength < 40) {
        for (var i = 0; i < 42 - tempLength; i++) {
          currentDays.push(nextDays[lastI + i]);
        }
      }

      if (documents != null) {
        this.storedDocuments = documents;
      }

      var tempDocuments = this.storedDocuments;

      var dayGreyed = true;
      var lastDayMarked = 0;
      var nextMonth = false;
      for (var i = 0; i < currentDays.length; i++) {
        var currentDay = {
          day: 0,
          active: false,
          greyed: false,
          selected: false,
          colors: {
            benevolat: false,
            talent: false,
            sport: false,
            residentiel: false,
            expedition: false,
          },
        };
        currentDay["day"] = currentDays[i].getDate();
        currentDay["date"] = currentDays[i];

        if (lastDayMarked > currentDay["day"] && dayGreyed == false) {
          dayGreyed = true;
          nextMonth = true;
        }
        if (nextMonth == false) {
          if (currentDay["day"] == 1) dayGreyed = false;
        }

        lastDayMarked = currentDay["day"];

        if (dayGreyed) currentDay["greyed"] = true;

        var today = new Date();
        var someDate = currentDays[i];
        if (
          someDate.getDate() == today.getDate() &&
          someDate.getMonth() == today.getMonth() &&
          someDate.getFullYear() == today.getFullYear()
        ) {
          currentDay["active"] = true;
        }

        this.currentDays.push(currentDay);
      }

      for (var j = 0; j < tempDocuments.length; j++) {
        var documentDateFrom = new Date(tempDocuments[j].datefrom);
        var documentTag = tempDocuments[j].tag;

        for (var i = 0; i < this.currentDays.length; i++) {
          var currentDay = this.currentDays[i];

          if (
            currentDay["date"].getFullYear() == documentDateFrom.getFullYear() &&
            currentDay["date"].getMonth() == documentDateFrom.getMonth() &&
            currentDay["date"].getDate() == documentDateFrom.getDate()
          ) {
            currentDay["colors"][documentTag] = true;
          }
        }
      }
    },
    getAgendaData: function (date) {
      this.selectedDate = date;
      var selectedDate = this.selectedDate;

      this.displayAgendaData = [];

      var tempDocuments = this.storedDocuments;
      var cntDocs = 0;
      for (var i = 0; i < tempDocuments.length; i++) {
        var documentDateFrom = new Date(tempDocuments[i].datefrom);
        var documentTag = tempDocuments[i].tag;

        if (
          selectedDate.getFullYear() == documentDateFrom.getFullYear() &&
          selectedDate.getMonth() == documentDateFrom.getMonth() &&
          selectedDate.getDate() == documentDateFrom.getDate()
        ) {
          var displayDocument = {
            date: selectedDate.getDate() + "/" + (selectedDate.getMonth() + 1),
            title: tempDocuments[i].title,
            description: marked(tempDocuments[i].description),
            category: tempDocuments[i].tag,
          };

          this.displayAgendaData.push(displayDocument);
          cntDocs++;
        }
      }

      if (cntDocs == 0) {
        var displayDocument = {
          date: selectedDate.getDate() + "/" + (selectedDate.getMonth() + 1),
          title: "No events at this date",
          description: "",
          category: "nocategory",
        };

        this.displayAgendaData.push(displayDocument);
      }
    },
    changeYear(direction) {
      if (direction > 0) {
        this.currentYear++;
      } else {
        this.currentYear--;
      }
      this.displayDays();
    },
    changeMonthMobile(direction) {
      var gotoMonth = 0;
      if (direction > 0) {
        if (this.currentMonth < 12) {
          gotoMonth = this.currentMonth + 1;
        } else {
          gotoMonth = 1;
          this.currentYear++;
        }
      } else {
        if (this.currentMonth > 1) {
          gotoMonth = this.currentMonth - 1;
        } else {
          gotoMonth = 12;
          this.currentYear--;
        }
      }
      this.changeMonth(gotoMonth);
    },
  },
  computed: {},
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.merite-calendar-year {
  padding: 0.75em 1em 0.75em 1em;
  background-color: var(--charte-color-expedition);

  display: inline-block;

  color: white;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 22pt;
}

.merite-calendar-container {
  background-color: var(--charte-color-grey-1);

  border-left: 5px solid var(--charte-color-expedition);

  min-height: 30em;
  height: 50vh;
}

.merite-calendar-year.arrow {
  background-color: var(--charte-color-grey-1);
  color: #777;

  transition: all 200ms;
}

.merite-calendar-year.arrow:hover {
  background-color: var(--charte-color-expedition);
  color: white;

  cursor: pointer;
}

.merite-calendar-inner-container {
  display: flex;

  height: 100%;
}

.merite-calendar-inner-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.merite-calendar-inner-flex:nth-child(1) {
  width: 14vw;
  border-right: 1px solid var(--charte-color-grey-2);
  padding: 2em;
}

.merite-calendar-inner-flex:nth-child(2) {
  flex: 1;
  padding: 2em;
}

.merite-calendar-inner-flex:nth-child(3) {
  width: 20vw;
  background-color: #f9f9f9;

  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;

  position: relative;
}

.merite-calendar-month-list {
  list-style-type: none;

  margin: 0;
  padding: 0;

  font-family: "Meta Pro";
  font-weight: 200;
  font-size: 16pt;

  line-height: 24pt;

  color: #a7a9ac;
}

.merite-calendar-month-list li:hover {
  cursor: pointer;
}

.merite-calendar-month-list .active {
  font-size: 26pt;

  line-height: 36pt;

  color: #3c3c3b;
}

.merite-calendar-upper .merite-calendar-inner-day-column {
  flex: 1;

  font-family: "Meta Pro";
  font-weight: 200;
  font-size: 12pt;

  line-height: 46pt;

  text-align: center;

  flex: 1 0 14%;

  text-transform: uppercase;
}

.merite-calendar-lower .merite-calendar-inner-day-column {
  flex: 1;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 16pt;

  line-height: 46pt;

  color: var(--charte-color-grey-2);

  text-align: center;

  flex: 1 0 13%;
}

.nocategory {
  color: var(--charte-color-grey-2) !important;
}

.merite-calendar-upper {
  display: flex;
}

.merite-calendar-lower {
  display: flex;
  flex-wrap: wrap;
}

.merite-calendar-upper div {
  color: var(--charte-color-expedition);
}

.merite-calendar-inner-day-column {
  color: #3c3c3b;
  border: 1px solid transparent;

  transition: all 200ms;
}

.merite-calendar-inner-day-column.active {
  border: 1px solid #a7a9ac;
  border-radius: 10em;
}

.merite-calendar-inner-day-column.greyed {
  color: #a7a9ac !important;
}

.merite-calendar-inner-day-column {
  position: relative;

  z-index: 10;
}

.merite-calendar-inner-day-column:hover {
  background-color: #f0f0f0;

  border-radius: 10em;
  cursor: pointer;
}

.merite-calendar-day.selected {
  background-color: #f0f0f0;

  border-radius: 10em;
  cursor: pointer;
}

.merite-calendar-inner-day-column ul {
  margin: 0;
  padding: 0;

  list-style-type: none;

  position: absolute;

  z-index: 0;

  left: 50%;
  top: 10pt;

  transform: translateX(-50%);
}

.merite-calendar-inner-day-column ul li {
  margin: 0;
  padding: 0;

  display: inline-block;

  font-size: 28pt;
}

.merite-calendar-inner-day-column ul li.benevolat {
  color: var(--charte-color-benevolat);
}

.merite-calendar-inner-day-column ul li.talent {
  color: var(--charte-color-talent);
}

.merite-calendar-inner-day-column ul li.sport {
  color: var(--charte-color-sport);
}

.merite-calendar-inner-day-column ul li.residentiel {
  color: var(--charte-color-residentiel);
}

.merite-calendar-inner-day-column ul li.expedition {
  color: var(--charte-color-expedition);
}

.merite-calendar-fullitem-date.benevolat {
  color: var(--charte-color-benevolat);
}

.merite-calendar-fullitem-date.talent {
  color: var(--charte-color-talent);
}

.merite-calendar-fullitem-date.sport {
  color: var(--charte-color-sport);
}

.merite-calendar-fullitem-date.residentiel {
  color: var(--charte-color-residentiel);
}

.merite-calendar-fullitem-date.expedition {
  color: var(--charte-color-expedition);
}

.merite-calendar-fullitem-list {
  list-style-type: none;

  margin: 0;
  padding: 4em;

  overflow-y: scroll;

  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
}

.merite-calendar-fullitem {
  list-style-type: none;
}

.merite-calendar-fullitem-date {
  color: #0090ba;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 22pt;

  margin: 0;
  padding: 0;
}

.merite-calendar-fullitem-title {
  color: #3c3c3b;

  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 22pt;

  margin: 1em 0 0 0;
  padding: 0;
}

.merite-calendar-fullitem-paragraph {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;

  padding-bottom: 4em;
}

.merite-calendar-day {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 14pt;

  margin: 0;
  padding: 0;
}

.yearlist-mobile {
  display: none;
}

.merite-calendar-fullitem-title {
  word-wrap: break-word;
}

@media only screen and (max-width: 600px) {
  .merite-calendar-inner-container {
    display: flex;
    flex-direction: column;

    height: 100%;
  }

  .merite-calendar-inner-flex {
    display: flex;
    justify-content: center;
  }

  .merite-calendar-container {
    height: unset;
  }

  .monthlist-desktop {
    display: none;
  }

  .yearlist-desktop {
    display: none;
  }

  .yearlist-mobile {
    display: flex;
    background-color: red;
  }

  .yearlist-mobile .month {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
  }

  .yearlist-mobile .month h2 {
    margin: 0;
    padding: 0;

    font-family: "Meta Pro";
    font-weight: 300;
    font-size: 32pt;
    line-height: 24pt;
  }

  .yearlist-mobile .month h3 {
    margin: 0;
    padding: 0;

    font-family: "Meta Pro";
    font-weight: 300;
    font-size: 14pt;
  }

  .merite-calendar-year.arrow:hover {
    background-color: var(--charte-color-grey-1);
    color: #777;
  }

  .merite-calendar-fullitem-list {
    position: relative;
    padding: 2em;
  }
}</style>
