<template>
  <div class="merite-form-participants">
    <Container>
      <div class="merite-form-participants-container" v-show="!formcomplete">
        <div class="merite-form-participants-flex">
          <div class="merite-form-participants-element">
            <label class="merite-form-participants-label">{{ this.schema.textfirstname }}</label>
            <input type="text" class="merite-form-participants-text" :class="{ inputerror: formfirstnameerror }"
              v-model="formfirstname" />
          </div>
          <div class="merite-form-participants-element">
            <label class="merite-form-participants-label">{{ this.schema.textemail }}</label>
            <input type="text" class="merite-form-participants-text" :class="{ inputerror: formemailerror }"
              v-model="formemail" />
          </div>
          <div class="merite-form-participants-element">
            <label class="merite-form-participants-label">{{ this.schema.awardunittext }}</label>
            <select id="levelcomplete" name="levelcomplete" class="merite-form-participants-text"
              :class="{ inputerror: formuniterror }" @change="onAwardUnitChange($event)">
              <option value="-">-</option>
              <option v-for="unit in this.schema.award_units" :key="unit" :value="unit.UnitName">{{ unit.UnitName }}
              </option>
            </select>
          </div>
        </div>
        <div class="merite-form-participants-flex">
          <div class="merite-form-participants-element">
            <label class="merite-form-participants-label">{{ this.schema.textlastname }}</label>
            <input type="text" class="merite-form-participants-text" :class="{ inputerror: formlastnameerror }"
              v-model="formlastname" />
          </div>
          <div class="merite-form-participants-element">
            <label class="merite-form-participants-label">{{ this.schema.levelcompletetext }}</label>
            <select id="levelcomplete" name="levelcomplete" class="merite-form-participants-text"
              :class="{ inputerror: formlevelerror }" @change="onLevelChange($event)">
              <option v-for="level in levelcompleted" :key="level.id" :value="level.name">{{ level.name }}</option>
            </select>
          </div>
          <div class="merite-form-participants-element">
            <label class="merite-form-participants-label">{{ this.schema.multiplelevelcompletetext }}</label>
            <select id="levelcomplete" name="levelcomplete" class="merite-form-participants-text"
              @change="onMultiLevelChange($event)">
              <option value="No">{{ notext }}</option>
              <option v-for="level in multilevelcompleted" :key="level.id" :value="level.name">{{ level.name }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="merite-form-participants-container" v-show="!formcomplete">
        <div class="merite-form-participants-flex">
          <div class="merite-form-participants-element">
            <label class="merite-form-participants-label">{{ this.schema.guest1name }}</label>
            <input type="checkbox" class="merite-form-participants-checkbox" v-model="guest1" />
          </div>
<!--
          <div class="merite-form-participants-element">
            <label class="merite-form-participants-label">{{ this.schema.guest2name }}</label>
            <input type="checkbox" class="merite-form-participants-checkbox" v-model="guest2" />
          </div>
-->
        </div>
        <div class="merite-form-participants-flex"></div>
      </div>

      <div class="merite-form-participants-container" v-show="guest1 && !formcomplete">
        <div class="merite-form-participants-flex">
          <div class="merite-form-participants-element">
            <label class="merite-form-participants-label">{{ this.schema.textfirstname }}</label>
            <input type="text" class="merite-form-participants-text" v-model="formguest1firstname"
              :class="{ inputerror: formguest1firstnameerror }" />
          </div>
        </div>
        <div class="merite-form-participants-flex">
          <div class="merite-form-participants-element">
            <label class="merite-form-participants-label">{{ this.schema.textlastname }}</label>
            <input type="text" class="merite-form-participants-text" v-model="formguest1lastname"
              :class="{ inputerror: formguest1lastnameerror }" />
          </div>
        </div>
      </div>


      <div class="merite-form-participants-container" v-show="guest2 && !formcomplete">
        <div class="merite-form-participants-flex">
          <div class="merite-form-participants-element">
            <label class="merite-form-participants-label">{{ this.schema.textfirstname }}</label>
            <input type="text" class="merite-form-participants-text" v-model="formguest2firstname"
              :class="{ inputerror: formguest2firstnameerror }" />
          </div>
        </div>
        <div class="merite-form-participants-flex">
          <div class="merite-form-participants-element">
            <label class="merite-form-participants-label">{{ this.schema.textlastname }}</label>
            <input type="text" class="merite-form-participants-text" v-model="formguest2lastname"
              :class="{ inputerror: formguest2lastnameerror }" />
          </div>
        </div>
      </div>

      <div class="merite-form-participants-container spacer" v-show="!formcomplete">
        <div class="merite-form-participants-flex"></div>
        <div class="merite-form-participants-flex">
          <div class="merite-form-disclaimer" v-html="generateTextDisclaimer()"></div>
          <div class="merite-form-participants-element">
            {{ formerrortext }}
            <input type="submit" class="merite-form-participants-button" v-on:click="submitData()"
              :value="this.schema.buttontext" />
          </div>
        </div>
      </div>

      <div class="merite-form-donate-section-outer" v-show="formcomplete">
        <div class="merite-form-donate-section" v-html="formerrortext">
        </div>
        <!--
          <div class="merite-supportus-box-container">
            <div class="merite-supportus-box-flex">
              <h2 class="merite-supportus-heading">{{ $t("supportus-heading-1") }}</h2>
              <p class="merite-supportus-paragraph">
                Mérite Jeunesse Luxembourg<br />
                IBAN LU59 1111 2137 4756 0000<br />
                BIC: CCPLLULL
              </p>
            </div>
            <div class="merite-supportus-box-flex">
              <h2 class="merite-supportus-heading">Payconiq</h2>
              <a href="https://payconiq.com/T/1/61BB1E71DFB2940006F7B793?D=Thank%20you%20for%20your%20donation">
                <img
                  src="https://portal.payconiq.com/qrcode?f=svg&c=https://payconiq.com/T/1/61BB1E71DFB2940006F7B793"
                  class="merite-supportus-qrcode"
                />

              </a>
            </div>
          </div>
          -->
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "@/components/widgets/Container.vue";
import { marked } from 'marked';
import emailjs from "emailjs-com";

export default {
  name: "pb-formparticipants",
  components: { Container },
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      levelcompleted: Object,
      multilevelcompleted: Object,
      guest1: false,
      guest2: false,
      formfirstname: "",
      formlastname: "",
      formemail: "",
      formunit: "",
      formlevel: "Bronze",
      formmultilevel: "No",
      formguest1firstname: "",
      formguest1lastname: "",
      formguest2firstname: "",
      formguest2lastname: "",

      formfirstnameerror: false,
      formlastnameerror: false,
      formemailerror: false,
      formuniterror: false,
      formlevelerror: false,
      formguest1firstnameerror: false,
      formguest1lastnameerror: false,
      formguest2firstnameerror: false,
      formguest2lastnameerror: false,

      formerrortext: "",
      formcomplete: false,

      notext: "Nee",
    };
  },
  mounted() {
    this.schema.award_units.sort(this.compare);

    let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, "$1");

    console.log("Schema");
    console.log(this.schema);

    if (locale == "en") {
      this.notext = "No";
      this.levelcompleted = [
        { id: 0, name: "Bronze" },
        { id: 1, name: "Silver" },
        { id: 2, name: "Gold" },
      ];
    } else if (locale == "fr") {
      this.notext = "Non";
      this.levelcompleted = [
        { id: 0, name: "Bronze" },
        { id: 1, name: "Argent" },
        { id: 2, name: "Or" },
      ];
    } else if (locale == "lu") {
      this.notext = "Nee";
      this.levelcompleted = [
        { id: 0, name: "Bronze" },
        { id: 1, name: "Sëlwer" },
        { id: 2, name: "Gold" },
      ];
    }

    if (locale == "en") {
      this.multilevelcompleted = [
        { id: 0, name: "Bronze & Silver" },
        { id: 1, name: "Silver & Gold" },
        { id: 2, name: "Bronze & Silver & Gold" },
      ];
    } else if (locale == "fr") {
      this.multilevelcompleted = [
        { id: 0, name: "Bronze & Argent" },
        { id: 1, name: "Argent & Or" },
        { id: 2, name: "Bronze & Argent & Or" },
      ];
    } else if (locale == "lu") {
      this.multilevelcompleted = [
        { id: 0, name: "Bronze & Sëlwer" },
        { id: 1, name: "Sëlwer & Gold" },
        { id: 2, name: "Bronze & Sëlwer & Gold" },
      ];
    }
  },
  updated() { },
  methods: {
    generateTextDisclaimer() {
      return marked(this.schema.privacy);
    },
    generateText(text) {
      if (text) {
        return marked(text);
      }
    },
    compare(a, b) {
      if (a.UnitName < b.UnitName) {
        return -1;
      }
      if (a.UnitName > b.UnitName) {
        return 1;
      }
      return 0;
    },
    onAwardUnitChange(e) {
      this.formunit = e.target.value;
    },
    onLevelChange(e) {
      this.formlevel = e.target.value;
    },
    onMultiLevelChange(e) {
      this.formmultilevel = e.target.value;
    },
    submitData() {
      let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, "$1");

      if (this.guest1 == false) {
        if (this.guest2 == true) {
          this.formguest1firstname = this.formguest2firstname;
          this.formguest1lastname = this.formguest2lastname;
          this.formguest2firstname = "";
          this.formguest2lastname = "";

          this.guest1 = true;
          this.guest2 = false;
        } else {
          this.formguest1firstname = "";
          this.formguest1lastname = "";
          this.formguest2firstname = "";
          this.formguest2lastname = "";
        }
      }

      if (this.guest2 == false) {
        this.formguest2firstname = "";
        this.formguest2lastname = "";
      }
      var forminputerror = false;

      this.formfirstnameerror = false;
      this.formlastnameerror = false;
      this.formemailerror = false;
      this.formuniterror = false;
      this.formlevelerror = false;

      this.formguest1firstnameerror = false;
      this.formguest1lastnameerror = false;
      this.formguest2firstnameerror = false;
      this.formguest2lastnameerror = false;

      if (this.formfirstname == "") {
        forminputerror = true;
        this.formfirstnameerror = true;
      }

      if (this.formlastname == "") {
        forminputerror = true;
        this.formlastnameerror = true;
      }

      if (this.formemail == "") {
        forminputerror = true;
        this.formemailerror = true;
      }

      if (this.formunit == "" || this.formunit == "-") {
        forminputerror = true;
        this.formuniterror = true;
      }

      if (this.formlevel == "" || this.formlevel == "-") {
        forminputerror = true;
        this.formlevelerror = true;
      }

      if (this.guest1) {
        if (this.formguest1firstname == "") {
          forminputerror = true;
          this.formguest1firstnameerror = true;
        }
        if (this.formguest1lastname == "") {
          forminputerror = true;
          this.formguest1lastnameerror = true;
        }
      }

      if (this.guest2) {
        if (this.formguest2firstname == "") {
          forminputerror = true;
          this.formguest2firstnameerror = true;
        }

        if (this.formguest2lastname == "") {
          forminputerror = true;
          this.formguest2lastnameerror = true;
        }
      }

      if (forminputerror == false) {
        let that = this;

        this.axios
          .post("https://mdb.imagify.design/formemailparticipants", {
            email: this.formemail,
          })
          .then(function (response) {
            if (response.data.length == 0) {
              that.formcomplete = true;
              that.axios
                .post("https://mdb.imagify.design/formparticipants", {
                  firstname: that.formfirstname,
                  lastname: that.formlastname,
                  email: that.formemail,
                  unit: that.formunit,
                  level: that.formlevel,
                  multilevel: that.formmultilevel,
                  guest1firstname: that.formguest1firstname,
                  guest1lastname: that.formguest1lastname,
                  guest2firstname: that.formguest2firstname,
                  guest2lastname: that.formguest2lastname,
                })
                .then(function (response) {
                  that.formcomplete = true;

                  const payload = {
                    email: that.formemail,
                    emailcontent: that.schema.emailconfirmation,
                  };

                  emailjs.send("service_ixwgcl8", "template_frnlzqj", payload, "hZu8PletaDsGb3ktM");

                  if (locale == "en") {
                    that.formerrortext = that.generateText(that.schema.confirmation);
                  } else if (locale == "fr") {
                    that.formerrortext = that.generateText(that.schema.confirmation);
                  } else if (locale == "lu") {
                    that.formerrortext = that.generateText(that.schema.confirmation);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            } else {
              that.formemailerror = true;

              if (locale == "en") {
                that.formerrortext = "The email address has already been used.";
              } else if (locale == "fr") {
                that.formerrortext = "L'adresse mail a déjà été utilisé.";
              } else if (locale == "lu") {
                that.formerrortext = "D'Email address gouf schon benotzt.";
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        if (locale == "en") {
          this.formerrortext = "All fields have to be filled.";
        } else if (locale == "fr") {
          this.formerrortext = "Tous les champs doivent être remplis.";
        } else if (locale == "lu") {
          this.formerrortext = "All felder mussen ausgefëllt ginn.";
        }
      }
    },
  },
};
</script>

<style>
.merite-form-participants {
  padding: 0 0 4em 0;
}

.merite-form-participants-container {
  display: flex;
}

.merite-form-participants-flex {
  flex: 1;
}

.merite-form-participants-flex:nth-child(1) {
  padding: 0 1em 0 0;
}

.merite-form-participants-flex:nth-child(2) {
  padding: 0 0 0 1em;
}

.merite-form-participants-element {
  display: flex;
  flex-direction: column;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  align-items: flex-start;
}

.merite-form-participants-text {
  border: none;
  border-bottom: 2px solid var(--charte-color-residentiel);
  background-color: transparent;

  height: 2em;
  margin: 0 0 4em 0;

  width: 100%;
}

.merite-form-participants-button {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  border: none;
  background-color: var(--charte-color-residentiel);

  color: white;

  height: 3em;
  margin: 0 0 4em 0;

  width: 100%;
}

.merite-form-participants-container.spacer {
  margin: 4em 0 0 0;
}

.merite-form-participants-checkbox {
  border: none;
  border-bottom: 1px solid grey;
  background-color: transparent;

  height: 2em;
  margin: 0 0 2em 0;
}

.merite-form-participants-text:focus {
  outline: none;
}

.inputerror {
  border-bottom: 2px solid red !important;
}

.merite-form-donate-section-outer {
  display: flex;

  align-items: center;
  align-content: center;
  justify-content: center;
}

.merite-form-donate-section {
  width: 50%;
}

.merite-form-donate-success-text {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;
}

.merite-supportus-box-flex {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;
}

.merite-form-disclaimer {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;
}

.merite-form-donate-section {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

}

@media only screen and (max-width: 600px) {
  .merite-form-participants-container {
    flex-direction: column;
  }

  .merite-form-participants-flex:nth-child(1) {
    padding: 0;
  }

  .merite-form-participants-flex:nth-child(2) {
    padding: 0;
  }
}
</style>
