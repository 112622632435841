<template>
  <div class="merite-form-tutors">
    <Container>
      <div class="merite-form-tutors-container" v-show="!formcomplete">
        <div class="merite-form-tutors-flex">
          <div class="merite-form-tutors-element">
            <label class="merite-form-tutors-label">{{ this.schema.textfirstname }}</label>
            <input type="text" class="merite-form-tutors-text" :class="{ inputerror: formfirstnameerror }"
              v-model="formfirstname" />
          </div>
          <div class="merite-form-tutors-element">
            <label class="merite-form-tutors-label">{{ this.schema.textemail }}</label>
            <input type="text" class="merite-form-tutors-text" :class="{ inputerror: formemailerror }"
              v-model="formemail" />
          </div>

          <div class="merite-form-tutors-element">
            <label class="merite-form-tutors-label">{{ $t("form-orb-selecttime-1") }}</label>
            <select id="langselect" name="langselect" class="merite-form-tutors-text" @change="onSlotChange($event)">
              <option value="lu">{{ $t("form-orb-selecttime-2") }}</option>
              <option value="en">{{ $t("form-orb-selecttime-3") }}</option>
            </select>
          </div>
        </div>
        <div class="merite-form-tutors-flex">
          <div class="merite-form-tutors-element">
            <label class="merite-form-tutors-label">{{ this.schema.textlastname }}</label>
            <input type="text" class="merite-form-tutors-text" :class="{ inputerror: formlastnameerror }"
              v-model="formlastname" />
          </div>
          <div class="merite-form-tutors-element">
            <label class="merite-form-tutors-label">{{ this.schema.textunits }}</label>
            <select id="levelcomplete" name="levelcomplete" class="merite-form-tutors-text"
              :class="{ inputerror: formuniterror }" @change="onAwardUnitChange($event)">
              <option value="-">-</option>
              <option value="n-a">n/a</option>
              <option v-for="unit in this.schema.award_units" :key="unit" :value="unit.UnitName">{{ unit.UnitName }}
              </option>
            </select>
          </div>

          <!--
          <div class="merite-form-tutors-element">
            <label class="merite-form-tutors-label">{{ $t("form-exp-food-5") }}</label>
            <select id="langselect" name="langselect" class="merite-form-tutors-text" @change="onVeganChange($event)">
              <option value="no">{{ $t("form-exp-food-6") }}</option>
              <option value="vegetarian">{{ $t("form-exp-food-7") }}</option>
              <option value="vegan">{{ $t("form-exp-food-8") }}</option>
            </select>
          </div>
          -->
        </div>
      </div>

      <div class="merite-form-tutors-container spacer" v-show="!formcomplete">
        <div class="merite-form-tutors-flex"></div>

        <div class="merite-form-tutors-flex">
          <p class="merite-form-disclaimer">{{ $t("form-disclaimer") }}</p>
          <div class="merite-form-tutors-element">
            {{ formerrortext }}
            <input type="submit" class="merite-form-tutors-button" v-on:click="submitData()"
              :value="this.schema.textbutton" />
          </div>
        </div>
      </div>

      <div class="merite-form-donate-section-outer" v-show="formcomplete">
        <div class="merite-form-donate-section">
          <p class="merite-form-donate-success-text">
            {{ formerrortext }}
          </p>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "@/components/widgets/Container.vue";
import emailjs from "emailjs-com";
import { marked } from 'marked';

export default {
  name: "pb-formtraining",
  components: { Container },
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      levelcompleted: Object,
      multilevelcompleted: Object,
      guest1: false,
      guest2: false,
      formfirstname: "",
      formlastname: "",
      formemail: "",
      formunit: "",

      formfirstnameerror: false,
      formlastnameerror: false,
      formemailerror: false,
      formuniterror: false,

      formerrortext: "",
      formcomplete: false,

      formslot: "lu",
    };
  },
  mounted() {
    this.schema.award_units.sort(this.compare);
  },
  updated() { },
  methods: {
    compare(a, b) {
      if (a.UnitName < b.UnitName) {
        return -1;
      }
      if (a.UnitName > b.UnitName) {
        return 1;
      }
      return 0;
    },
    onAwardUnitChange(e) {
      this.formunit = e.target.value;
    },

    onVeganChange(e) {
      this.formvegan = e.target.value;
    },
    onSlotChange(e) {
      this.formslot = e.target.value;
    },
    submitData() {
      let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, "$1");

      var forminputerror = false;

      this.formfirstnameerror = false;
      this.formlastnameerror = false;
      this.formemailerror = false;
      this.formuniterror = false;

      if (this.formfirstname == "") {
        forminputerror = true;
        this.formfirstnameerror = true;
      }

      if (this.formlastname == "") {
        forminputerror = true;
        this.formlastnameerror = true;
      }

      if (this.formemail == "") {
        forminputerror = true;
        this.formemailerror = true;
      }

      if (this.formunit == "" || this.formunit == "-") {
        forminputerror = true;
        this.formuniterror = true;
      }

      if (forminputerror == false) {
        let that = this;

        this.axios
          .post("https://mdb.imagify.design/formtraining", {
            firstname: that.formfirstname,
            lastname: that.formlastname,
            email: that.formemail,
            unit: that.formunit,
            slot: that.formslot,
          })
          .then(function (response) {
            that.formcomplete = true;

            let confirmation = that.schema.TextconfirmLU;
            if (that.formslot == "lu") {
              confirmation = that.schema.TextconfirmLU;
            } else {
              confirmation = that.schema.TextconfirmEN;
            }

            const payload = {
              email: that.formemail,
              emailcontent: confirmation,
            };

            emailjs.send("service_ixwgcl8", "template_frnlzqj", payload, "hZu8PletaDsGb3ktM");

            if (locale == "en") {
              that.formerrortext = "You are registered successfully for the event.";
            } else if (locale == "fr") {
              that.formerrortext = "Vous êtes inscrit avec succès à l'événement.";
            } else if (locale == "lu") {
              that.formerrortext = "Dir sidd erfollegreich fir d'Veranstaltung registréiert.";
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        if (locale == "en") {
          this.formerrortext = "All fields have to be filled.";
        } else if (locale == "fr") {
          this.formerrortext = "Tous les champs doivent être remplis.";
        } else if (locale == "lu") {
          this.formerrortext = "All felder mussen ausgefëllt ginn.";
        }
      }
    },
  },
};
</script>

<style>
.merite-form-tutors {
  padding: 0 0 4em 0;
}

.merite-form-tutors-container {
  display: flex;
}

.merite-form-tutors-flex {
  flex: 1;
}

.merite-form-tutors-flex:nth-child(1) {
  padding: 0 1em 0 0;
}

.merite-form-tutors-flex:nth-child(2) {
  padding: 0 0 0 1em;
}

.merite-form-tutors-element {
  display: flex;
  flex-direction: column;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  align-items: flex-start;
}

.merite-form-tutors-text {
  border: none;
  border-bottom: 2px solid grey;
  background-color: transparent;

  height: 2em;
  margin: 0 0 4em 0;

  width: 100%;
}

.merite-form-tutors-button {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  border: none;
  background-color: var(--charte-color-benevolat);

  color: white;

  height: 3em;
  margin: 0 0 4em 0;

  width: 100%;
}

.merite-form-tutors-container.spacer {
  margin: 4em 0 0 0;
}

.merite-form-tutors-checkbox {
  border: none;
  border-bottom: 1px solid grey;
  background-color: transparent;

  height: 2em;
  margin: 0 0 4em 0;
}

.merite-form-tutors-text:focus {
  outline: none;
}

.inputerror {
  border-bottom: 2px solid red !important;
}

.merite-form-donate-section-outer {
  display: flex;

  align-items: center;
  align-content: center;
  justify-content: center;
}

.merite-form-donate-section {
  width: 50%;
}

.merite-form-donate-success-text {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;
}

.merite-supportus-box-flex {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;
}

@media only screen and (max-width: 600px) {
  .merite-form-tutors-container {
    flex-direction: column;
  }

  .merite-form-tutors-flex:nth-child(1) {
    padding: 0;
  }

  .merite-form-tutors-flex:nth-child(2) {
    padding: 0;
  }
}
</style>
