<template>
  <div class="merite-horizontal-card">
    <div class="merite-horizontal-card-inner">
      <div class="merite-horizontal-card-inner-flex left">
        <h2 class="animate__animated">{{ textTitle }}</h2>
        <p class="animate__animated">
          {{ textParagraph }}
        </p>
        <ul>
          <li v-for="link in linksArray" :key="link.message" class="animate__animated">
            <router-link :to="{ name: link[1] }">{{ link[0] }}</router-link>
          </li>
        </ul>
      </div>
      <div class="merite-horizontal-card-inner-flex right" :style="backgroundImage"></div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import AnimationScroller from "@/lib/AnimationScroller";

export default {
  name: "HorizontalCard",
  props: {
    imageLocation: {
      type: String,
      default: "unbound",
    },
    textTitle: {
      type: String,
      default: "title",
    },
    textParagraph: {
      type: String,
      default: "title",
    },
    linksArray: {
      type: Array,
      default: [],
    },
  },
  computed: {
    backgroundImage() {
      return {
        "--backgroundimg": "url(" + this.imageLocation + ")",
        background: "url(" + this.imageLocation + ")",
        "background-position": "center",
        "background-size": "cover",
      };
    },
  },
  mixins: [AnimationScroller],
  mounted() {
    /*
    for (var i = 1; i < 6; i++) {
      var cls = ".hc" + i;

      this.viewportAnimationScroller($(cls + " .merite-horizontal-card-inner-flex.left h2"), "animate__fadeInUp");
      this.viewportAnimationScroller($(cls + " .merite-horizontal-card-inner-flex.left p"), "animate__fadeInUp");
      this.viewportAnimationScroller($(cls + " .merite-horizontal-card-inner-flex.left li"), "animate__fadeInLeft");
    }
    */
  },
};
</script>

<style>
.merite-horizontal-card {
  width: 115%;
  min-height: 20vh;

  margin: 0 15%;
  padding: 2em 0 2em 0;
}

.merite-horizontal-card-inner {
  display: flex;

  align-content: center;
  justify-content: center;
}

.merite-horizontal-card-inner-flex.left {
  flex: 1;

  display: flex;
  flex-direction: column;

  justify-content: center;

  background-color: #f9f9f9;

  padding: 5em 6em 5em 5vw;

  transition: all 400ms;

  overflow-x: hidden;
}

.merite-horizontal-card-inner-flex.right {
  flex-grow: 1.5;
  background-color: teal;
}

.merite-horizontal-card-inner-flex h2 {
  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 18pt;

  color: #3c3c3b;
}

.merite-horizontal-card-inner-flex p {
  width: 50%;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  margin: 0 0 4em 0;
}

.merite-horizontal-card-inner-flex ul {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  list-style-type: none;
  margin: 0;
  padding: 0;

  line-height: 28pt;
}

.merite-horizontal-card-inner-flex a {
  color: #3c3c3b;
  text-decoration: none;
}

.merite-horizontal-card-inner-flex a:hover {
  color: #3c3c3b;
  text-decoration: underline;
}

.merite-horizontal-card-inner-flex ul li:nth-child(1) {
  animation-delay: 0;
}

.merite-horizontal-card-inner-flex ul li:nth-child(2) {
  animation-delay: 200ms;
}

.merite-horizontal-card-inner-flex ul li:nth-child(3) {
  animation-delay: 400ms;
}

.merite-horizontal-card-inner-flex ul li:nth-child(4) {
  animation-delay: 600ms;
}

@media only screen and (max-width: 600px) {
  .merite-horizontal-card-inner {
    flex-direction: column;
    flex-flow: column-reverse;
    box-shadow: 0 0 2em 0 rgba(0, 0, 0, 0.15);
  }

  .merite-horizontal-card-inner-flex.right {
    flex: 1;
    min-height: 40vh;
  }

  .merite-horizontal-card-inner-flex p {
    width: 100%;
  }

  .merite-horizontal-card-inner-flex.left {
    padding: 5em 10vw 5em 10vw;
  }
}
</style>