<template>
  <Container>
    <!--
    <div class="merite-resourcecenter-underlay">
      <h2 class="merite-resourcecenter-underlay-title">{{ schema.directsearch }}</h2>
    </div>
    <br />
    <input type="text" :placeholder="schema.inputplaceholder" class="merite-resourcecenter-input" />
    <br />
    -->

    <div class="merite-resourcecenter-underlay">
      <h2 class="merite-resourcecenter-underlay-title">{{ schema.filtertext }}</h2>
    </div>

    <ul class="merite-resourcecenter-list">
      <li v-for="cat in resourceCategories" :key="cat" :class="{ tagActive: cat[1] }" v-on:click="changeCat($event, cat)">
        {{ cat[0] }}
      </li>
    </ul>

    <div class="merite-resourcecenter-downloads-list-container">
      <ul class="merite-resourcecenter-downloads-list">
        <li class="merite-resourcecenter-downloads-item" v-for="res in displayItems" :key="res">
          <div class="merite-resourcecenter-flex-inner-max">
            {{ res.ResourceName }}
            <ul class="merite-resourcecenter-item-category-list notmobile">
              <li v-for="tag in res.ResourceType" :key="tag">{{ tag.ResourceTypeName }}</li>
            </ul>
          </div>
          <div class="merite-resourcecenter-flex-inner notmobile">
            <img class="merite-resourcecenter-flex-inner-type" src="/assets/icons/pdf.svg" />
          </div>
          <div class="merite-resourcecenter-flex-inner notmobile">
            <a :href="generateLink(res.ResourceMedia.url)" target="_blank">{{ schema.downloadtext }}</a>
          </div>
          <div class="merite-resourcecenter-flex-inner">
            <a :href="generateLink(res.ResourceMedia.url)" target="_blank"><img src="/assets/icons/download.svg"
                class="merite-resourcecenter-downloadicon" /></a>
          </div>
        </li>
      </ul>
    </div>
  </Container>
</template>

<script>
import Container from "@/components/widgets/Container.vue";

var resourceItems = [];

export default {
  name: "pb-resources",
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      resourceCategories: [],
      displayItems: [],
      filterCategory: null,
    };
  },
  components: { Container },
  created() {
    console.log("Get resources");

    let locale = this.$t("locale");
    fetch(process.env.VUE_APP_MERITECMS_URL + "resource-center-items")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        var resourceTypes = [];
        var resourceDataArray = [];

        for (var i = 0; i < data.length; i++) {
          var types = data[i].ResourceType;
          for (var j = 0; j < types.length; j++) {
            var currentType = types[j];
            var currentTypeName = currentType.ResourceTypeName;
            if (!resourceTypes.includes(currentTypeName)) {
              resourceTypes.push(currentTypeName);
            }
          }

          var resourceData = data[i];
          resourceDataArray.push(resourceData);
        }

        var newresourceTypes = [];
        for (var i = 0; i < resourceTypes.length; i++) {
          newresourceTypes.push([resourceTypes[i], false]);
        }

        resourceItems = resourceDataArray;

        this.resourceCategories = newresourceTypes;
        this.displayItems = resourceDataArray;
      });
  },
  methods: {
    generateLink(link) {
      if (link.startsWith("/")) {
        return process.env.VUE_APP_MERITECMS_URL + link.substring(1);
      } else {
        return link;
      }
    },
    changeCat: function (ev, cat) {
      var arrayIndex = -1;
      var disable = false;

      for (var x = 0; x < this.resourceCategories.length; x++) {
        if (this.resourceCategories[x][1] == true) {
          if (this.resourceCategories[x][0] == cat[0]) {
            disable = true;
            this.resourceCategories[x][1] = false;
          } else {
            this.resourceCategories[x][1] = false;
            if (this.resourceCategories[x].indexOf(cat[0]) != -1) {
              arrayIndex = x;
            }
          }
        } else {
          this.resourceCategories[x][1] = false;
          if (this.resourceCategories[x].indexOf(cat[0]) != -1) {
            arrayIndex = x;
          }
        }
      }

      if (disable == false) {
        this.resourceCategories[arrayIndex][1] = true;
        this.filterCategory = cat[0];
        this.displayItems = JSON.parse(JSON.stringify(resourceItems));

        var count = this.displayItems.length;
        var tempItems = [];

        for (var i = 0; i < count; i++) {
          for (var y = 0; y < this.displayItems[i].ResourceType.length; y++) {
            if (this.displayItems[i].ResourceType[y].ResourceTypeName == cat[0]) {
              tempItems.push(this.displayItems[i]);
            }
          }
        }
        this.displayItems = tempItems;
      } else {
        this.filterCategory = null;
        this.displayItems = JSON.parse(JSON.stringify(resourceItems));
      }
    },
  },
};
</script>

<style>
.merite-resourcecenter-underlay {
  margin: 0;
  padding: 0;

  background-color: var(--charte-color-expedition);

  display: inline-block;
}

.merite-resourcecenter-underlay-title {
  margin: 0;
  padding: 1em;

  background-color: var(--charte-color-expedition);
  color: white;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 14pt;
}

.merite-resourcecenter-input {
  background-color: var(--charte-color-grey-1);
  border-radius: 10em;

  border: none;

  padding: 1.5em;
  margin: 2em 0 2em 0;
  width: 25vw;

  outline: none;
}

.merite-resourcecenter-list {
  margin: 2em 0 0 0;
  padding: 0;

  list-style-type: none;
}

.merite-resourcecenter-list li {
  display: inline-block;

  margin: 0 1em 0 0;
  padding: 1em 2em 1em 2em;
  border: 0.1em solid var(--charte-color-grey-2);
  color: var(--charte-color-grey-2);

  border-radius: 20em;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;

  transition: all 250ms;
}

.merite-resourcecenter-list li:hover {
  background-color: var(--charte-color-grey-2);
  color: white;

  cursor: pointer;
}

.merite-resourcecenter-downloads-list-container {
  margin: 2em 0 2em 0;
  padding: 2em 0 2em 0;
  border-top: 1px solid var(--charte-color-expedition);
}

.merite-resourcecenter-downloads-list {
  list-style-type: none;

  margin: 0;
  padding: 0;
}

.merite-resourcecenter-downloads-item {
  line-height: 30pt;
  padding: 1em 2em 1em 2em;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;

  display: flex;
}

.merite-resourcecenter-downloads-item:nth-child(odd) {
  background-color: var(--charte-color-grey-1);
}

.merite-resourcecenter-flex-inner-max {
  flex: 1 1 auto;
}

.merite-resourcecenter-flex-inner {
  flex: 0 0 auto;
  padding: 0 2em 0 2em;

  display: flex;
  align-items: center;

  color: var(--charte-color-grey-2);
}

.merite-resourcecenter-flex-inner a {
  color: var(--charte-color-grey-2);
  text-decoration: none;

  padding: 0;
  margin: 0;
}

.merite-resourcecenter-flex-inner a:hover {
  text-decoration: underline;
}

.merite-resourcecenter-flex-inner:nth-child(2) {
  padding: 0 2em 0 2em;
  border-left: 1px solid var(--charte-color-expedition);
  border-right: 1px solid var(--charte-color-expedition);
}

.merite-resourcecenter-flex-inner:nth-child(3) {
  padding: 0 0 0 2em;
}

.merite-resourcecenter-downloadicon {
  height: 20pt;
}

.merite-resourcecenter-flex-inner-type {
  height: 20pt;
}

.merite-resourcecenter-item-category-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.merite-resourcecenter-item-category-list li {
  display: inline-block;

  padding: 0.1em 1em 0.1em 1em;

  border-radius: 10em;
  border: 1px solid #dddddc;
  color: #dddddc;

  margin-right: 1em;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 10pt;
}

.tagActive {
  background-color: var(--charte-color-grey-2);
  color: white !important;
}

.tagNotActive {
  background-color: green;
}

@media only screen and (max-width: 600px) {
  .notmobile {
    display: none;
  }
}
</style>
