<template>
  <Container>
    <div class="merite-text-dual-container">
      <div class="merite-text-dual-flex">
        <div class="merite-text-dual-textbackground violet">
          <h2 class="merite-text-dual-title">
            {{ schema.title }}
          </h2>
        </div>
      </div>
      <div class="merite-text-dual-flex">
        <p class="merite-text-dual-paragraph" v-html="generateText()"></p>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from "@/components/widgets/Container.vue";

import { marked } from 'marked';

export default {
  name: "pb-textdual",
  components: { Container },
  props: {
    schema: {
      type: Object,
    },
  },
  updated() {
    this.generateText();
  },
  methods: {
    generateText() {
      return marked(this.schema.text);
    },
  },
};
</script>

<style>
.merite-text-dual-container {
  margin: 8em 0 2em 0;
  display: flex;
}

.merite-text-dual-flex {
  flex: 1;
}

.merite-text-dual-textbackground {
  padding: 1em;

  display: inline-block;
}

.merite-text-dual-textbackground.violet {
  background-color: var(--charte-color-residentiel);
}

.merite-text-dual-flex .merite-text-dual-title {
  margin: 0;

  font-family: "Meta Pro";
  font-weight: 400;
  font-size: 22pt;

  color: white;
}

.merite-text-dual-paragraph {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;

  padding: 0;
  margin: 0;

  color: var(--charte-color-grey-2);
}

@media only screen and (max-width: 600px) {
  .merite-text-dual-container {
    display: block;
  }
}
</style>