<template>
  <ContactForm
    :title="schema.Title"
    :textblock1="schema.textblock1"
    :textblock2="schema.textblock2"
    :contactname="schema.name"
    :contactsurname="schema.surname"
    :contactemail="schema.Email"
    :contactsubject="schema.Subject"
    :contactmessage="schema.Message"
    :contactsend="schema.Send"
  ></ContactForm>
</template>

<script>
import ContactForm from "@/components/widgets/ContactForm.vue";

export default {
  name: "pb-contactform",
  components: {
    ContactForm,
  },
  props: {
    schema: {
      type: Object,
    },
  },
};
</script>

<style>
</style>