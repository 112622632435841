<template>
  <LinksContainer />
</template>

<script>
import LinksContainer from "@/components/widgets/LinksContainer.vue";

export default {
  name: "pb-links",
  components: {
    LinksContainer,
  },
};
</script>

<style>
</style>