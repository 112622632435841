<template>
  <div class="merite-agenda-item-container">
    <AgendaPopup v-show="popup" :title="title" :date="date" @closePopup="popup = false">
      <slot></slot>
    </AgendaPopup>
    <div class="merite-agenda-item-inner-container animate__animated" :class="element">
      <h3 class="merite-agenda-next-date">{{ date }}</h3>
      <h2 class="merite-agenda-next-title">{{ title }}</h2>
      <p class="merite-agenda-next-exerp">
        {{ description }}
      </p>
      <br />
      <a class="merite-agenda-next-link" @click="popup = true">See more</a>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import AnimationScroller from "@/lib/AnimationScroller";

import AgendaPopup from "@/components/widgets/Agenda/AgendaPopup.vue";

export default {
  name: "AgendaItem",
  components: {
    AgendaPopup,
  },
  data() {
    return {
      popup: false,
    };
  },
  props: {
    date: {
      type: String,
      default: "Undefined",
    },
    title: {
      type: String,
      default: "Undefined",
    },
    subtitle: {
      type: String,
      default: "Undefined",
    },
    description: {
      type: String,
      default: "Undefined",
    },
    element: {
      type: String,
      default: "undefined",
    },
  },
  mixins: [AnimationScroller],
  mounted() {
    var agendaItem = "." + this.element;

    this.viewportAnimationScroller($("." + this.element), "animate__fadeInUp");
  },
};
</script>

<style>
.merite-agenda-next-date {
  margin: 1em 0 0 0;
  padding: 0;

  font-family: "Meta Pro";
  font-weight: 100;
  font-size: 28pt;

  color: #3c3c3b;
}

.merite-agenda-next-title {
  margin: 0.1em 0 0 0;
  padding: 0;

  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 28pt;
  line-height: 30pt;

  color: #3c3c3b;
  word-wrap: break-word;
}

.merite-agenda-next-subtitle {
  margin: 0.1em 0 0 0;
  padding: 0;

  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 16pt;
  line-height: 20pt;

  color: #3c3c3b;
}

.merite-agenda-next-exerp {
  margin: 2em 0 0 0;
  padding: 0;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  color: #3c3c3b;
}

.merite-agenda-next-link {
  margin: 2em 0 0 0;
  padding: 0;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  color: #3c3c3b;

  bottom: 0;
}

.merite-agenda-next-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.merite-agenda-item-container {
  height: 100%;
}

.merite-agenda-item-inner-container {
  height: 100%;
  width: 12.5vw;
}

@media only screen and (max-width: 600px) {
  .merite-agenda-next-title {
    word-wrap: break-word;
  }

  .merite-agenda-item-inner-container {
    width: 50vw;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 600px) {
  .merite-agenda-next-title {
    word-wrap: break-word;
  }

  .merite-agenda-item-inner-container {
    width: 20vw;
  }

  /* Added */
}
</style>