<template>
  <div class="merite-slider-background" :style="backgroundImage">
    <Container>
      <Drawer v-if="drawerOpen" @closeDrawer="closeDrawerFunc"></Drawer>
      <div class="merite-header-container">
        <div class="merite-header-part left">
          <router-link :to="{ name: 'home' }" v-if="$t('locale') == 'lu'"
            ><img src="/assets/logo/merite-logo-lu.png" class="merite-header-logo"
          /></router-link>
          <router-link :to="{ name: 'home' }" v-if="$t('locale') == 'en'"
            ><img src="/assets/logo/merite-logo-en.png" class="merite-header-logo"
          /></router-link>
          <router-link :to="{ name: 'home' }" v-if="$t('locale') == 'fr'"
            ><img src="/assets/logo/merite-logo-fr.png" class="merite-header-logo"
          /></router-link>
        </div>
        <div class="merite-header-part center">
          <ul class="merite-header-menu">
            <li v-for="(menu, index) in menuArray" :key="index" class="merite-header-menu-link">
              <router-link :to="{ name: menu.page.description }">{{ menu.title }}</router-link>
              <ul class="merite-header-menu-dropdown" v-if="menu.menuitem != 0">
                <router-link v-for="(menuitem, menuindex) in menu.menuitem" :key="menuindex" :to="{ name: menuitem.page.description }">
                  <li :style="buttonColorStyle">{{ menuitem.title }}</li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
        <div class="merite-header-part rightfront">
          <ul class="merite-header-buttons">
            <router-link :to="{ name: 'supportus' }">
              <li :style="buttonColorStyle" class="merite-header-button-donate">Donate</li>
            </router-link>
            <a href="https://www.onlinerecordbook.org/fo/" target="_blank">
              <li :style="secondbuttonColorStyle" class="merite-header-button-orb">ORB</li>
            </a>
          </ul>
        </div>
        <div class="merite-header-part right">
          <ul class="merite-header-languageswitcher">
            <li @click="changeLocale('lu')">LU</li>
            <li>|</li>
            <li @click="changeLocale('en')">EN</li>
            <li>|</li>
            <li @click="changeLocale('fr')">FR</li>
          </ul>
        </div>
      </div>
      <div class="merite-header-container-mobile">
        <div class="merite-header-container-mobile-inner">
          <router-link :to="{ name: 'home' }" v-if="$t('locale') == 'lu'"
            ><img src="/assets/logo/merite-logo-lu.png" class="merite-header-logo"
          /></router-link>
          <router-link :to="{ name: 'home' }" v-if="$t('locale') == 'en'"
            ><img src="/assets/logo/merite-logo-en.png" class="merite-header-logo"
          /></router-link>
          <router-link :to="{ name: 'home' }" v-if="$t('locale') == 'fr'"
            ><img src="/assets/logo/merite-logo-fr.png" class="merite-header-logo"
          /></router-link>
        </div>
        <div class="merite-header-container-mobile-inner">
          <img src="/assets/icons/menu.svg" class="merite-header-mobile-menuicon" v-on:click="openDrawerFunc" />
        </div>
      </div>
    </Container>
    <br />
    <div class="merite-header-largetext-container">
      <Container>
        <div class="merite-header-largetext-outer">
          <div class="merite-header-largetext-inner">
            <div class="merite-header-largetext-borders animate__animated">
              <slot name="title"></slot>
            </div>
            <slot name="subtitle"></slot>
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import AnimationScroller from "@/lib/AnimationScroller";

import Container from "@/components/widgets/Container.vue";
import Drawer from "@/components/widgets/Drawer.vue";

export default {
  name: "SiteSlider",
  props: {
    imageLocation: {
      type: String,
      default: "unbound",
    },
    imageOverlayColor: {
      type: String,
      default: "#ff0000",
    },
    imageOverlayOpacity: {
      type: Number,
      default: 0.85,
    },
    buttonColor: {
      type: String,
      default: "#FF0000",
    },
  },
  data() {
    return {
      drawerOpen: false,
      menuArray: [],
    };
  },
  components: {
    Container,
    Drawer,
  },
  computed: {
    backgroundImage() {
      return {
        "background-color": this.imageOverlayColor,
        "background-image": "url(" + this.imageLocation + ")",
        "background-position": "center",
        "background-size": "cover",
      };
    },
    buttonColorStyle() {
      return {
        "background-color": this.buttonColor,
        color: "#FFFFFF",
      };
    },
    secondbuttonColorStyle() {
      return {
        color: this.imageOverlayColor,
      };
    },
    buttonColorStyle() {
      return {
        "background-color": this.imageOverlayColor,
      };
    },
  },
  mixins: [AnimationScroller],
  methods: {
    openDrawerFunc() {
      this.drawerOpen = true;
    },
    closeDrawerFunc() {
      this.drawerOpen = false;
    },
    changeLocale(locale) {
      let page = window.location.pathname.substring(3);
      window.location = "/" + locale + page;
    },
  },
  mounted() {
    this.viewportAnimationScroller($(".merite-header-largetext-borders"), "animate__fadeInLeft");

    let locale = this.$t("locale");
    fetch(process.env.VUE_APP_MERITECMS_URL + "menus?_locale=" + locale)
      .then((res) => res.json())
      .then((data) => {
        var sortedData = data.sort(function (a, b) {
          return a.id - b.id;
        });

        for (var m = 0; m < data.length; m++) {
          var menulvl1item = data[m];

          this.menuArray.push(menulvl1item);
        }

        if (this.menuArray.length >= 6) {
          let newSort = [this.menuArray[0], this.menuArray[5], this.menuArray[1], this.menuArray[2], this.menuArray[3], this.menuArray[4]];
          this.menuArray = newSort;
        }
      });
  },
};
</script>

<style>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.merite-header-container-mobile {
  display: none;
}

.merite-slider-background {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
}

.merite-header-container {
  display: flex;
  margin: 2em 0;
}

.merite-header-part.center {
  flex-basis: 100%;
}

.merite-header-part.left:hover {
  cursor: pointer;
}

.merite-header-part.right {
  flex-wrap: wrap;
  display: flex;

  justify-content: center;
  align-items: center;

  padding: 0 1em 0 1em;
}

.merite-header-part.center {
  flex-wrap: wrap;
  display: flex;

  justify-content: flex-end;
  align-items: center;

  padding: 0 1em 0 1em;
}

.merite-header-part.rightfront {
  flex-wrap: wrap;
  display: flex;

  justify-content: flex-end;
  align-items: center;

  padding: 0 1em 0 1em;
}

.merite-header-logo {
  height: 5em;
}

.merite-header-languageswitcher {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.merite-header-languageswitcher li {
  display: inline;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 14pt;

  padding: 0 0.25em 0 0.25em;

  color: white;
}

.merite-header-languageswitcher li:nth-child(odd):hover {
  cursor: pointer;
}

.merite-header-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;

  z-index: 200;
}

.merite-header-menu li {
  display: inline;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 14pt;

  margin: 0 0.5em 0 0.5em;
  padding: 0;

  color: white;

  position: relative;
  z-index: 999;
}

.merite-header-buttons {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.merite-header-buttons li {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  padding: 0.25em 1em 0.25em 1em;

  color: white;
}

.merite-header-buttons a {
  text-decoration: none !important;
}

.merite-header-button-donate {
  display: inline;
  background-color: var(--charte-color-residentiel);
  padding: 0;
  margin: 0.5em;
}

.merite-header-button-donate:hover {
  cursor: pointer;
}

.merite-header-button-orb {
  display: inline;
  background-color: white;
  padding: 0;
  margin: 0.5em;
}

.merite-header-largetext-container {
  flex-basis: 100%;

  width: 55vw;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex-wrap: wrap;

  position: relative;
  z-index: 10;
}

.merite-header-largetext-text {
  color: white;

  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 4.5em;

  text-transform: uppercase;
}

.merite-header-largetext-borders {
  border-top: 0.5em solid white;
  border-bottom: 0.5em solid white;

  padding: 2em 0 2em 0;

  position: relative;
  z-index: 10;
}

.merite-header-largetext-outer {
  display: flex;

  position: relative;
  z-index: 999;
}

.merite-header-menu-link:hover .merite-header-menu-dropdown {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  margin: -1.5em 0 0 0;
  padding: 2em 0 0 0;
}

.merite-header-menu-dropdown {
  display: none;
  position: absolute;
  padding: 0 0 0 0;
  z-index: 100;

  left: 0;
}

.merite-header-menu-dropdown li {
  background-color: rgba(0, 0, 0, 0.7);
  display: block;
  padding: 0.4em 1em 0.4em 1em;
  color: white;
  margin: 2px 0 2px 0;
  white-space: nowrap;

  min-width: 5em;

  position: relative;
  z-index: 999;
}

.merite-header-menu-dropdown li a {
  font-family: "Meta Pro";
  font-weight: 200;
  font-size: 12pt;

  letter-spacing: normal;
}

.merite-header-menu-dropdown a li {
  font-family: "Meta Pro";
  font-weight: 200;
  font-size: 12pt;

  letter-spacing: normal;
}

.merite-header-menu-dropdown li:hover {
  cursor: pointer;
}

.merite-header-menu-link a {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;
  color: white;
  text-decoration: none;
}

.merite-header-menu-link a {
  z-index: 100;
}

/* Responsive design */
@media only screen and (max-width: 1400px) {
  .merite-header-container {
    display: none;
  }

  .merite-header-largetext-container {
    width: 100vw;
  }

  .merite-header-container-mobile {
    display: flex;
    padding: 2em 0 0 0;
  }

  .merite-header-container-mobile-inner {
    flex: 1;
  }

  .merite-header-container-mobile-inner:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .merite-header-mobile-menuicon {
    height: 1.5em;
  }

  .merite-header-mobile-logo {
    height: 4em;
  }
}
</style>
