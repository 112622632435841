import $ from "jquery";

export default {
  methods: {
    viewportAnimationScroller(element, animation) {
      let _element = element;
      let _animation = animation;

      $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
      };

      if (_animation != "none") {
        window.addEventListener("scroll", function() {
          let cls = _element;
          let anim = _animation;

          if (cls.isInViewport()) {
            if (!cls.hasClass(anim)) {
              cls.addClass(anim);
            }
          } else {
            if (cls.hasClass(anim)) {
              //cls.removeClass(anim);
            }
          }
        });
        // Self Test

        let cls = _element;
        let anim = _animation;

        if (cls.isInViewport()) {
          if (!cls.hasClass(anim)) {
            cls.addClass(anim);
          }
        } else {
          if (cls.hasClass(anim)) {
            //cls.removeClass(anim);
          }
        }
      }
    }
  },
  mounted() {}
};
