<template>
  <GradientContainer primaryColor="#0591B2" secondaryColor="#329868" angle="-5">
    <div class="merite-horizontalcard-container">
      <div class="merite-horizontalcard-underlay">
        <slot></slot>
      </div>
    </div>
  </GradientContainer>
</template>

<script>
import Container from "@/components/widgets/Container.vue";
import GradientContainer from "@/components/widgets/GradientContainer.vue";

export default {
  name: "HorizontalCardContainer",
  components: {
    Container,
    GradientContainer,
  },
};
</script>

<style>
.merite-horizontalcard-container {
  padding: 6em 2em 6em 0;
}

.merite-horizontalcard-underlay {
  width: 70%;
  background-color: white;

  padding: 6em 0 6em 0;
}

@media only screen and (max-width: 600px) {
  .merite-horizontalcard-container {
    width: 100%;
    overflow: hidden;
  }
}
</style>