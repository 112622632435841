<template>
  <Container>
    <div class="merite-texttilecontainer-outer">
      <slot></slot>
    </div>
  </Container>
</template>

<script>
import Container from "@/components/widgets/Container.vue";

export default {
  name: "TextTileContainer",
  props: {},
  components: {
    Container,
  },
};
</script>

<style>
.merite-texttilecontainer-outer {
  display: flex;
  margin: 0em 0 0em 0;
}

@media only screen and (max-width: 600px) {
  .merite-texttilecontainer-outer {
    flex-direction: column;
  }
}
</style>