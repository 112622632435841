<template>
  <div class="merite-form-sponsors">
    <Container>
      <div class="merite-form-sponsors-container" v-show="!formcomplete">
        <div class="merite-form-sponsors-flex">
          <div class="merite-form-sponsors-element">
            <label class="merite-form-sponsors-label">{{ this.schema.textfirstname }}</label>
            <input type="text" class="merite-form-sponsors-text" :class="{ inputerror: formfirstnameerror }"
              v-model="formfirstname" />
          </div>
          <div class="merite-form-sponsors-element">
            <label class="merite-form-sponsors-label">{{ this.schema.textemail }}</label>
            <input type="text" class="merite-form-sponsors-text" :class="{ inputerror: formemailerror }"
              v-model="formemail" />
          </div>
        </div>
        <div class="merite-form-sponsors-flex">
          <div class="merite-form-sponsors-element">
            <label class="merite-form-sponsors-label">{{ this.schema.textlastname }}</label>
            <input type="text" class="merite-form-sponsors-text" :class="{ inputerror: formlastnameerror }"
              v-model="formlastname" />
          </div>
        </div>
      </div>

      <!--
      <div class="merite-form-sponsors-container" v-show="!formcomplete">
        <div class="merite-form-sponsors-flex">
          <div class="merite-form-sponsors-element">
            <label class="merite-form-sponsors-label">{{ this.schema.guesttext }}</label>
            <input type="checkbox" class="merite-form-sponsors-checkbox" v-model="guest1" />
          </div>
        </div>
      </div>-->

      <div class="merite-form-sponsors-container" v-show="guest1 && !formcomplete">
        <div class="merite-form-sponsors-flex">
          <div class="merite-form-sponsors-element">
            <label class="merite-form-sponsors-label">{{ this.schema.textfirstname }}</label>
            <input type="text" class="merite-form-sponsors-text" v-model="formguest1firstname"
              :class="{ inputerror: formguest1firstnameerror }" />
          </div>
        </div>
        <div class="merite-form-sponsors-flex">
          <div class="merite-form-sponsors-element">
            <label class="merite-form-sponsors-label">{{ this.schema.textlastname }}</label>
            <input type="text" class="merite-form-sponsors-text" v-model="formguest1lastname"
              :class="{ inputerror: formguest1lastnameerror }" />
          </div>
        </div>
      </div>

      <div class="merite-form-sponsors-container spacer" v-show="!formcomplete">
        <div class="merite-form-sponsors-flex"></div>
        <div class="merite-form-sponsors-flex">
          <div class="merite-form-disclaimer" v-html="generateTextDisclaimer()"></div>
          <div class="merite-form-sponsors-element">
            {{ formerrortext }}
            <input type="submit" class="merite-form-sponsors-button" v-on:click="submitData()"
              :value="this.schema.textbutton" />
          </div>
        </div>
      </div>

      <div class="merite-form-donate-section-outer" v-show="formcomplete">
        <div class="merite-form-donate-section" v-html="formerrortext">
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "@/components/widgets/Container.vue";
import { marked } from 'marked';
import emailjs from "emailjs-com";

export default {
  name: "pb-formsponsors",
  components: { Container },
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      levelcompleted: Object,
      multilevelcompleted: Object,
      guest1: false,
      guest2: false,
      formfirstname: "",
      formlastname: "",
      formemail: "",
      formguest1firstname: "",
      formguest1lastname: "",

      formfirstnameerror: false,
      formlastnameerror: false,
      formemailerror: false,
      formguest1firstnameerror: false,
      formguest1lastnameerror: false,

      formerrortext: "",
      formcomplete: false,
    };
  },
  mounted() { },
  updated() { },
  methods: {
    generateTextDisclaimer() {
      return marked(this.schema.privacy);
    },
    generateText(text) {
      if (text) {
        return marked(text);
      }
    },
    submitData() {
      let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, "$1");

      if (this.guest1 == false) {
        this.formguest1firstname = "";
        this.formguest1lastname = "";
      }
      var forminputerror = false;

      this.formfirstnameerror = false;
      this.formlastnameerror = false;
      this.formemailerror = false;

      this.formguest1firstnameerror = false;
      this.formguest1lastnameerror = false;

      if (this.formfirstname == "") {
        forminputerror = true;
        this.formfirstnameerror = true;
      }

      if (this.formlastname == "") {
        forminputerror = true;
        this.formlastnameerror = true;
      }

      if (this.formemail == "") {
        forminputerror = true;
        this.formemailerror = true;
      }

      if (this.formunit == "" || this.formunit == "-") {
        forminputerror = true;
        this.formuniterror = true;
      }

      if (this.formlevel == "" || this.formlevel == "-") {
        forminputerror = true;
        this.formlevelerror = true;
      }

      if (this.guest1) {
        if (this.formguest1firstname == "") {
          forminputerror = true;
          this.formguest1firstnameerror = true;
        }
        if (this.formguest1lastname == "") {
          forminputerror = true;
          this.formguest1lastnameerror = true;
        }
      }

      if (forminputerror == false) {
        let that = this;

        this.axios
          .post("https://mdb.imagify.design/formemailsponsors", {
            email: this.formemail,
          })
          .then(function (response) {
            if (response.data.length == 0) {
              that.formcomplete = true;
              that.axios
                .post("https://mdb.imagify.design/formsponsors", {
                  firstname: that.formfirstname,
                  lastname: that.formlastname,
                  email: that.formemail,
                  unit: that.formunit,
                  guest1firstname: that.formguest1firstname,
                  guest1lastname: that.formguest1lastname,
                })
                .then(function (response) {
                  that.formcomplete = true;

                  const payload = {
                    email: that.formemail,
                    emailcontent: that.schema.emailconfirmation,
                  };

                  emailjs.send("service_ixwgcl8", "template_frnlzqj", payload, "hZu8PletaDsGb3ktM");

                  if (locale == "en") {
                    that.formerrortext = that.generateText(that.schema.confirmation);
                  } else if (locale == "fr") {
                    that.formerrortext = that.generateText(that.schema.confirmation);
                  } else if (locale == "lu") {
                    that.formerrortext = that.generateText(that.schema.confirmation);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            } else {
              that.formemailerror = true;

              if (locale == "en") {
                that.formerrortext = "The email address has already been used.";
              } else if (locale == "fr") {
                that.formerrortext = "L'adresse mail a déjà été utilisé.";
              } else if (locale == "lu") {
                that.formerrortext = "D'Email address gouf schon benotzt.";
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        if (locale == "en") {
          this.formerrortext = "All fields have to be filled.";
        } else if (locale == "fr") {
          this.formerrortext = "Tous les champs doivent être remplis.";
        } else if (locale == "lu") {
          this.formerrortext = "All felder mussen ausgefëllt ginn.";
        }
      }
    },
  },
};
</script>

<style>
.merite-form-sponsors {
  padding: 0 0 4em 0;
}

.merite-form-sponsors-container {
  display: flex;
}

.merite-form-sponsors-flex {
  flex: 1;
}

.merite-form-sponsors-flex:nth-child(1) {
  padding: 0 1em 0 0;
}

.merite-form-sponsors-flex:nth-child(2) {
  padding: 0 0 0 1em;
}

.merite-form-sponsors-element {
  display: flex;
  flex-direction: column;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  align-items: flex-start;
}

.merite-form-sponsors-text {
  border: none;
  border-bottom: 2px solid var(--charte-color-talent);
  background-color: transparent;

  height: 2em;
  margin: 0 0 4em 0;

  width: 100%;
}

.merite-form-sponsors-button {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  border: none;
  background-color: var(--charte-color-talent);

  color: white;

  height: 3em;
  margin: 0 0 4em 0;

  width: 100%;
}

.merite-form-sponsors-container.spacer {
  margin: 4em 0 0 0;
}

.merite-form-sponsors-checkbox {
  border: none;
  border-bottom: 1px solid grey;
  background-color: transparent;

  height: 2em;
  margin: 0 0 4em 0;
}

.merite-form-sponsors-text:focus {
  outline: none;
}

.inputerror {
  border-bottom: 2px solid red !important;
}

.merite-form-donate-section-outer {
  display: flex;

  align-items: center;
  align-content: center;
  justify-content: center;
}

.merite-form-donate-section {
  width: 50%;
}

.merite-form-donate-success-text {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;
}

.merite-supportus-box-flex {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;
}

.merite-form-disclaimer {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;
}

.merite-form-donate-section {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

}

@media only screen and (max-width: 600px) {
  .merite-form-sponsors-container {
    flex-direction: column;
  }

  .merite-form-sponsors-flex:nth-child(1) {
    padding: 0;
  }

  .merite-form-sponsors-flex:nth-child(2) {
    padding: 0;
  }
}
</style>
