<template>
  <BlockImageTextOffset :title="schema.title" color="var(--charte-color-residentiel)"
    :background="generateLink(schema.image[0].formats.medium.url)">
    <template v-slot:description>
      <div v-html="generateText0()"></div>
    </template>
    <template v-slot:main>
      <div class="merite-blockimagetext-grey-container-flex">
        <div class="merite-blockimagetext-grey-inner-container">
          <h2>{{ schema.subtitle1 }}</h2>
          <div v-html="generateText1()"></div>
        </div>
      </div>
      <div class="merite-blockimagetext-grey-container-flex">
        <div class="merite-blockimagetext-grey-inner-container">
          <h2>{{ schema.subtitle2 }}</h2>
          <div v-html="generateText2()"></div>
        </div>
      </div>
      <div class="merite-blockimagetext-grey-container-flex">
        <div class="merite-blockimagetext-grey-inner-container">
          <h2>{{ schema.subtitle3 }}</h2>
          <div v-html="generateText3()"></div>
        </div>
      </div>
    </template>
  </BlockImageTextOffset>
</template>

<script>
import BlockImageTextOffset from "@/components/blocks/BlockImageTextOffset.vue";
import { marked } from 'marked';

export default {
  name: "pb-participantstextblock",
  components: {
    BlockImageTextOffset,
  },
  props: {
    schema: {
      type: Object,
    },
  },
  updated() {
    this.generateText1();
    this.generateText2();
    this.generateText2();
    this.generateText3();
  },
  methods: {
    generateLink(link) {
      console.log("generate link");
      if (link.startsWith("/")) {
        return process.env.VUE_APP_MERITECMS_URL + link;
      } else {
        return link;
      }
    },
    generateText0() {
      return marked(this.schema.text);
    },
    generateText1() {
      return marked(this.schema.subtext1);
    },
    generateText2() {
      return marked(this.schema.subtext2);
    },
    generateText3() {
      return marked(this.schema.subtext3);
    },
  },
};
</script>

<style>
.merite-blockimagetext-grey-container-flex {
  flex: 1;
  display: flex;
}

.merite-blockimagetext-grey-container-flex:nth-child(1) .merite-blockimagetext-grey-inner-container {
  border-top: 5px solid #ba8748;
}

.merite-blockimagetext-grey-container-flex:nth-child(2) .merite-blockimagetext-grey-inner-container {
  border-top: 5px solid #a7a9ac;
}

.merite-blockimagetext-grey-container-flex:nth-child(3) .merite-blockimagetext-grey-inner-container {
  border-top: 5px solid #b4975a;
}

.merite-blockimagetext-grey-container-flex:nth-child(1) h2 {
  color: #ba8748;
}

.merite-blockimagetext-grey-container-flex:nth-child(2) h2 {
  color: #a7a9ac;
}

.merite-blockimagetext-grey-container-flex:nth-child(3) h2 {
  color: #b4975a;
}

.merite-blockimagetext-grey-inner-container {
  background-color: white;
  margin: 1vw;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  padding: 4em 2em 4em 2em;

  flex-wrap: wrap;
}

.merite-blockimagetext-grey-inner-container h2 {
  padding: 0;
  margin: 0 0 1em 0;

  font-family: "Meta Pro";
  font-weight: 400;
  font-size: 22pt;

  text-transform: uppercase;
}

.merite-blockimagetext-grey-inner-label {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;
  color: var(--charte-color-grey-2);
}

.merite-blockimagetext-grey-inner-paragraph {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;
  color: var(--charte-color-grey-2);

  margin: 2em 0 0 0;
}

@media only screen and (max-width: 600px) {
  .merite-blockimagetext-grey-container-flex-overflow-inner p {
    padding: 0 10vw 0 10vw;
  }

  .merite-blockimagetext-grey-container {
    flex-direction: column !important;
  }

  .merite-blockimagetext-grey-container-flex {
    margin-bottom: 2em;
  }

  .merite-blockimagetext-grey-container {
    padding-bottom: 2em;
  }
}
</style>
