<template>
  <div class="merite-alumni">
    <div class="merite-alumni-title-container" :id="schema.Name.split(' ')[0].toLowerCase() + schema.Name.split(' ')[1].toLowerCase()">
      <div :id="uid" class="merite-alumni-title-thin animate__animated" v-html="schema.Name.split(' ')[0]"></div>
      <div :id="uid" class="merite-alumni-title animate__animated" v-html="schema.Name.split(' ')[1]"></div>
      <div class="merite-alumni-title-line"></div>
      <div :id="uid" class="merite-alumni-title-desc animate__animated" v-html="schema.Title"></div>
      <img :src="getPhotoLink(schema.Profile.url)" class="merite-alumni-profile" alt="" />
    </div>

    <div class="merite-alumni-main">
      <div class="merite-alumni-flex">
        <div class="merite-alumni-text" v-html="generateText()"></div>
        <h2 class="merite-alumni-audio-text">{{ schema.Audio1Title }}</h2>
        <audio class="merite-alumni-audio" controls>
          <source :src="getAudioLink(schema.Audio1.url)" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <h2 class="merite-alumni-audio-text">{{ schema.Audio2Title }}</h2>
        <audio class="merite-alumni-audio" controls>
          <source :src="getAudioLink(schema.Audio2.url)" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
      <div class="merite-alumni-flex">
        <h2 class="merite-alumni-quote" v-if="schema.Quote1 != null">{{ schema.Quote1 }}</h2>
        <h2 class="merite-alumni-quote" v-if="schema.Quote2 != null">{{ schema.Quote2 }}</h2>
        <h2 class="merite-alumni-quote" v-if="schema.Quote3 != null">{{ schema.Quote3 }}</h2>
        <h2 class="merite-alumni-quote" v-if="schema.Quote4 != null">{{ schema.Quote4 }}</h2>

        <div class="merite-alumni-photo-container">
          <div class="merite-alumni-photo" v-for="(photo, index) in schema.Gallery" :key="index">
            <img class="merite-alumni-photo-item" :src="getPhotoLink(photo.url)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { marked } from "marked";

export default {
  name: "pb-alumni",
  props: {
    schema: {
      type: Object,
    },
  },
  mounted() {
    if (window.location.hash != "") {
      console.log(window.location.hash.substring(1));
      const element = document.getElementById(window.location.hash.substring(1));
      setTimeout(function () {
        element.scrollIntoView({ behavior: "smooth" });
      }, 250);
    }
  },
  methods: {
    generateText() {
      return marked(this.schema.MainText);
    },
    getAudioLink(link) {
      return process.env.VUE_APP_MERITECMS_URL.slice(0, -1) + link;
    },
    getPhotoLink(link) {
      return process.env.VUE_APP_MERITECMS_URL.slice(0, -1) + link;
    },
  },
};
</script>

<style>
.merite-alumni {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.merite-cms-container div:nth-child(even) .merite-alumni {
  background-color: var(--charte-color-grey-1);
}

.merite-alumni-title-container {
  width: intrinsic;
  width: -moz-max-content;
  width: -webkit-max-content;

  margin: 8em 0 4em 0;
}

.merite-alumni-profile {
  width: 10vw;

  margin: -100% 0 0 15vw;
}

.merite-alumni-title {
  margin: 0 0 0 26vw;
  font-size: 2em;

  font-family: "Meta Pro";
  font-weight: 500;

  text-transform: uppercase;

  color: var(--charte-color-talent);
}

.merite-alumni-title-thin {
  margin: 0 0 0 26vw;
  font-size: 2em;

  font-family: "Meta Pro";
  font-weight: 200;

  text-transform: uppercase;

  color: var(--charte-color-talent);
}

.merite-alumni-title-desc {
  margin: 2vh 0 0 26vw;
  font-size: 1.25em;

  font-family: "Meta Pro";
  font-weight: 300;

  text-transform: uppercase;

  color: var(--charte-color-grey-2);
}

.merite-alumni-title-line {
  width: 100%;
  height: 10px;

  background-color: var(--charte-color-talent);
}

.merite-alumni-main {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin: 0 0 0 15vw;
}

.merite-alumni-flex {
  flex: 1;
}

.merite-alumni-flex:first-child {
  padding-right: 5vw;
}

.merite-alumni-text {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  color: var(--charte-color-grey-2);
  padding-bottom: 2vh;
}

.merite-alumni-audio-text {
  font-family: "Meta Pro";
  font-weight: 400;
  font-size: 14pt;

  color: var(--charte-color-grey-2);
}

.merite-alumni-quote {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 18pt;

  color: var(--charte-color-grey-2);

  padding-bottom: 2vh;
}

.merite-alumni-quote::before {
  content: '" ';
  font-weight: 700;
  color: var(--charte-color-talent);
}

.merite-alumni-quote::after {
  content: ' "';
  font-weight: 700;
  color: var(--charte-color-talent);
}

.merite-alumni-photo-item {
  width: 40%;
}

.merite-alumni-audio {
  width: 70%;
}

@media only screen and (max-width: 600px) {
  .merite-alumni-title-container {
    width: 90% !important;
  }

  .merite-alumni-title {
    margin: 0 0 0 10vw !important;
  }

  .merite-alumni-title-thin {
    margin: 0 0 0 10vw !important;
  }

  .merite-alumni-title-desc {
    margin: 3vh 0 0 10vw !important;
  }

  .merite-alumni-profile {
    margin: 5vh 0 0 10vw !important;
    width: 80% !important;
  }

  .merite-alumni-main {
    flex-direction: column;
    width: 80%;
    margin: 0 0 0 10vw;
  }

  .merite-alumni-audio {
    width: 100%;
  }

  .merite-alumni-quote {
    padding-top: 4vh;
  }

  .merite-alumni-photo-item {
    width: 100%;
    margin-bottom: 4vh;
  }
}
</style>
