<template>
  <div class="alumni-register-container" :style="backgroundImage">
    <div class="merite-contactform-outer">
      <SiteHeading :title="this.schema.Title" color="#FFFFFF" />
      <Container>
        <div class="merite-contactform-flex-container">
          <div class="merite-contactform-flex single">
            {{ this.schema.Description1 }}
          </div>
          <div class="merite-contactform-flex double">
            {{ this.schema.Description2 }}
          </div>
          <div class="merite-contactform-flex half"></div>
        </div>

        <div class="merite-contactform-flex-container">
          <div class="merite-contactform-flex single">
            <div class="merite-contactform-input-container left">
              <div class="merite-contactform-input-block">
                <label class="merite-contactform-label">{{ this.schema.Lastname }}</label><br />
                <input class="merite-contactform-input" type="text" v-model="name"
                  :class="{ required: required[0] }" /><br />
              </div>
              <div class="merite-contactform-input-block">
                <label class="merite-contactform-label">{{ this.schema.Firstname }}</label><br />
                <input class="merite-contactform-input" type="text" v-model="surname"
                  :class="{ required: required[1] }" /><br />
              </div>
              <div class="merite-contactform-input-block">
                <label class="merite-contactform-label">{{ this.schema.Birthday }}</label><br />
                <input class="merite-contactform-input" type="date" v-model="date"
                  :class="{ required: required[3] }" /><br />
              </div>
            </div>
          </div>
          <div class="merite-contactform-flex double">
            <div class="merite-contactform-input-container">
              <div class="merite-contactform-input-block half">
                <label class="merite-contactform-label">{{ this.schema.Email }}</label><br />
                <input class="merite-contactform-input" type="text" v-model="email"
                  :class="{ required: required[2] }" /><br />
              </div>
              <div class="merite-contactform-input-block half">
                <label class="merite-contactform-label">{{ this.schema.Unit }}</label><br />
                <select id="levelcomplete" name="levelcomplete"
                  class="merite-form-tutors-text merite-form-awardunits-text" :class="{ required: required[4] }"
                  @change="onAwardUnitChange($event)">
                  <option value="bronze">{{ $t("participants-framework-header-1") }}</option>
                  <option value="silver">{{ $t("participants-framework-header-2") }}</option>
                  <option value="gold">{{ $t("participants-framework-header-3") }}</option>
                </select>
                <br />
                <button class="merite-contactform-submit" type="submit" :value="contactsend" @click="validateForm">
                  {{ this.schema.Signup }}</button><br />
              </div>
            </div>
          </div>
          <div class="merite-contactform-flex half"></div>
        </div>
        <div class="merite-disclaimer" v-html="generateDisclaimer()"></div>
      </Container>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked';
import Container from "@/components/widgets/Container.vue";

import emailjs from "emailjs-com";
import SiteHeading from "@/components/widgets/SiteHeading.vue";
import SiteText from "@/components/widgets/SiteText.vue";
export default {
  name: "pb-alumniregister",
  components: {
    Container,
    SiteHeading,
    SiteText,
  },
  props: {
    schema: {
      type: Object,
    },
  },
  computed: {
    backgroundImage() {
      return {
        background: "url(" + this.schema.Cover.url + ")",
        "background-position": "right",
        "background-size": "cover",
        "background-position": "right",
      };
    },
  },
  data() {
    return {
      name: "",
      surname: "",
      email: "",
      date: "",
      message: "",
      btnText: this.contactsend,
      required: [false, false, false, false, false],
      emailre: false,
      formunit: "",
    };
  },
  mounted() {
    /*
    
    */
  },
  methods: {
    generateDisclaimer() {
      return marked(this.schema.Disclaimer);
    },
    onAwardUnitChange(e) {
      this.formunit = e.target.value;
    },
    getRequired(re) {
      console.log("required");
      if (this.required[re]) {
        return true;
      } else {
        return false;
      }
    },
    getAge(DOB) {
      var today = new Date();
      var birthDate = new Date(DOB);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    validateForm() {
      var data = [this.name, this.surname, this.email, this.date, this.formunit];
      let validationFailed = false;
      for (let i = 0; i < data.length; i++) {
        let dataItem = data[i];
        if (dataItem == "") {
          this.required[i] = true;
          validationFailed = true;
        } else {
          if (i == 4) {
            if (dataItem == "-") {
              this.required[i] = true;
            } else {
              this.required[i] = false;
            }
          } else {
            this.required[i] = false;
          }
        }
      }

      var enteredAge = this.getAge(this.date);
      if (enteredAge < 18) {
        validationFailed = true;
        this.required[3] = true;
      }

      if (validationFailed == false) {
        this.$recaptchaLoaded().then(() => {
          console.log("recaptcha loaded");
          this.$recaptcha("login").then((token) => {
            console.log("submit");

            const payload = {
              name: this.name,
              surname: this.surname,
              email: this.email,
              date: this.date,
              unit: this.formunit,
            };

            var result = emailjs.send("service_ixwgcl8", "template_5ox02vw", payload, "hZu8PletaDsGb3ktM");

            const payload2 = {
              email: this.email,
            };

            // Confirmation
            var result = emailjs.send("service_gnuqk61", "template_qd6hmbs", payload2, "mk25z6_MgqdZHbrZY");

            alert("Your message has been send");
            window.location.reload();
          });
        });
      }
    },
  },
};
</script>

<style>
.alumni-register-container {
  width: 100%;
  min-height: 100vh;

  background-color: red;

  margin-bottom: 10vh;
}

.merite-form-awardunits-text {
  color: white;

  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.merite-form-awardunits-text.required {
  color: white;

  border-bottom: 1px solid rgba(255, 0, 0, 0.4);
}

.merite-contactform-outer {
  height: auto;
  min-height: 100vh;
}

.merite-disclaimer {
  color: white;
  padding: 5vh 0 5vh 0;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 10pt;
}
</style>
