<template>
  <div class="merite-siteheading-expandable-faq-flex">
    <div class="merite-siteheading-expandable-faq-flex-child">
      <h2 class="merite-siteheadingfaq-title" @click="changeVisibility()">{{ titleText }}</h2>
    </div>

    <div class="merite-siteheading-expandable-faq-flex-child">
      <div class="merite-siteheading-expandable-faq-arrowicon" @click="changeVisibility()">
        <svg
          class="merite-siteheading-expandable-faq-arrowicon-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 7.41"
          :class="{ active: isVisible }"
        >
          <path d="M10.59,0,6,4.58,1.41,0,0,1.41l6,6,6-6Z" />
        </svg>
      </div>
    </div>
  </div>

  <div class="merite-siteheading-expandable-faq-content-container" :class="{ active: isVisible }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SiteHeadingExpandable",
  data: function () {
    return {
      isVisible: false,
      titleText: "asd",
    };
  },
  props: {
    titleOpen: {
      type: String,
      default: "Undefined",
    },
    titleClose: {
      type: String,
      default: "Undefined",
    },
    color: {
      type: String,
      default: "#FF0000",
    },
  },
  created() {
    this.titleText = this.titleOpen;
  },
  methods: {
    changeVisibility: function () {
      this.isVisible = !this.isVisible;
      if (!this.isVisible) {
        this.titleText = this.titleOpen;
      } else {
        this.titleText = this.titleClose;
      }
    },
  },
  computed: {
    fontColor() {
      return {
        color: this.color,
      };
    },
    lineColor() {
      return {
        "background-color": this.color,
      };
    },
  },
};
</script>

<style>
.merite-siteheading-container {
  width: intrinsic;
  width: -moz-max-content;
  width: -webkit-max-content;

  margin: 8em 0 4em 0;
}

.merite-siteheading-title-line {
  width: 100%;
  height: 10px;
}

.merite-siteheading-expandable-faq-flex {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid var(--charte-color-sport);

  margin: 0 15vw 0 15vw;
}

.merite-siteheading-expandable-faq-flex-child:nth-child(2) {
  flex: 1;

  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
}

.merite-siteheading-expandable-faq-arrowicon {
  margin: 0 0 1em 0;
  padding: 1em;

  transition: all 250ms;
}

.merite-siteheading-expandable-faq-arrowicon:hover {
  background-color: #f9f9f9;

  cursor: pointer;
}

.merite-siteheading-expandable-faq-arrowicon-svg {
  width: 2em;
  height: 1em;

  fill: #a7a9ac;
  transition: all 250ms;
}

.merite-siteheading-expandable-faq-arrowicon:hover .merite-siteheading-expandable-faq-arrowicon-svg {
  fill: #3c3c3b;
}

.mertie-siteheading-expandable-faq-content-container-flex {
  background-color: orange;

  height: 40vh;
}

.merite-siteheading-expandable-faq-content-container {
  transition: max-height 1s cubic-bezier(0, 1, 0, 1);
  max-height: 0;

  overflow: hidden;
}

.merite-siteheading-expandable-faq-content-container.active {
  max-height: 500%;
  transition: max-height 1s ease-in-out;
}

.merite-siteheading-expandable-faq-arrowicon-svg.active {
  transform: rotateZ(180deg);
}

.merite-siteheadingfaq-title {
  margin: 0;

  font-size: 16pt;

  font-family: "Meta Pro";
  font-weight: 300;
}

.merite-siteheadingfaq-title:hover {
  cursor: pointer;
}
</style>