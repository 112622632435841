<template>
  <div class="merite-texttile">
    <div class="merite-texttitle-container" :style="backgroundImage">
      <div class="merite-texttitle-container-overlay">
        <h2>{{ primaryText }}</h2>
        <span class="merite-texttitle-underline"></span>
        <h3>{{ secondaryText }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextTile",
  props: {
    primaryText: {
      type: String,
      default: "undefined",
    },
    secondaryText: {
      type: String,
      default: "undefined",
    },
    imageLocation: {
      type: String,
      default: "",
    },
  },
  computed: {
    backgroundImage() {
      return {
        "background-image": "url(" + this.imageLocation + ")",
      };
    },
  },
};
</script>

<style>
.merite-texttile {
  flex: 1;
  margin: 0.25em;
}
.merite-texttitle-container {
  position: relative;

  background-position: center;
  background-size: cover;
}

.merite-texttitle-container::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  background-color: var(--bg-overlay-color);
  mix-blend-mode: multiply;
}

.merite-texttitle-container-overlay {
  padding: 7em 1em 3em 4em;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  z-index: 1;
}

.merite-texttitle-container-overlay h2 {
  margin: 0;
  padding: 0;

  color: white;

  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 64pt;

  flex: 1;

  z-index: 2;
}

.merite-texttitle-container-overlay h3 {
  margin: 0;
  padding: 0;

  color: white;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 20pt;
  flex: 1;
  z-index: 2;
}

.merite-texttitle-underline {
  width: 50%;
  height: 2px;

  background-color: white;
  margin: 0pt 0 10pt 0;
  z-index: 2;
}
</style>