<template>
  <BlockText :title="schema.title" :color="pbblocktextcolor">
    <div v-html="generateText()"></div>
  </BlockText>
</template>

<script>
import BlockText from "@/components/blocks/BlockText.vue";

import { marked } from 'marked';

export default {
  name: "pb-blocktext",
  components: {
    BlockText,
  },
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      pbblocktextcolor: "red",
    };
  },
  mounted() {
    this.generateColor();
  },
  updated() {
    this.generateColor();
    this.generateText();
  },
  methods: {
    generateText() {
      return marked(this.schema.text);
    },
    generateColor() {
      if (this.schema.color == "benevolat") {
        this.pbblocktextcolor = "var(--charte-color-benevolat)";
      } else if (this.schema.color == "talent") {
        this.pbblocktextcolor = "var(--charte-color-talent)";
      } else if (this.schema.color == "sport") {
        this.pbblocktextcolor = "var(--charte-color-sport)";
      } else if (this.schema.color == "residentiel") {
        this.pbblocktextcolor = "var(--charte-color-residentiel)";
      } else if (this.schema.color == "expedition") {
        this.pbblocktextcolor = "var(--charte-color-expedition)";
      } else {
        this.pbblocktextcolor = "var(--charte-color-talent)";
      }
    },
  },
};
</script>

<style></style>