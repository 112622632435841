<template>
  <BlockImageTextOffset :title="schema.title" :color="pbblocktextimagecolor"
    :background="generateLink(schema.background.formats.medium.url)">
    <template v-slot:description>
      <div v-html="generateText()"></div>
    </template>
  </BlockImageTextOffset>
</template>

<script>
import BlockImageTextOffset from "@/components/blocks/BlockImageTextOffset.vue";

import { marked } from 'marked';

export default {
  name: "pb-blockimagetextoffset",
  components: {
    BlockImageTextOffset,
  },
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      pbblocktextimagecolor: "red",
    };
  },
  mounted() {
    this.generateColor();
  },
  updated() {
    this.generateColor();
    this.generateText();
  },
  methods: {
    generateLink(link) {
      console.log("generate link");
      if (link.startsWith("/")) {
        return process.env.VUE_APP_MERITECMS_URL + link.substring(1);
      } else {
        return link;
      }
    },
    generateText() {
      return marked(this.schema.text);
    },
    generateColor() {
      if (this.schema.color == "benevolat") {
        this.pbblocktextimagecolor = "var(--charte-color-benevolat)";
      } else if (this.schema.color == "talent") {
        this.pbblocktextimagecolor = "var(--charte-color-talent)";
      } else if (this.schema.color == "sport") {
        this.pbblocktextimagecolor = "var(--charte-color-sport)";
      } else if (this.schema.color == "residentiel") {
        this.pbblocktextimagecolor = "var(--charte-color-residentiel)";
      } else if (this.schema.color == "expedition") {
        this.pbblocktextimagecolor = "var(--charte-color-expedition)";
      } else {
        this.pbblocktextimagecolor = "var(--charte-color-talent)";
      }
    },
  },
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .merite-participants-subtext {
    background-color: var(--charte-color-grey-1);

    padding: 2em 10vw 2em 10vw;
    margin: 0 0 0 0;
  }
}
</style>
