<template>
  <div class="merite-siteheading-expandable-flex">
    <div class="merite-siteheading-expandable-flex-child">
      <div class="merite-siteheading-container">
        <h2 class="merite-siteheading-title" :style="fontColor" @click="isVisible = !isVisible">{{ title }}</h2>
        <div class="merite-siteheading-title-line" :style="lineColor"></div>
      </div>
    </div>

    <div class="merite-siteheading-expandable-flex-child">
      <div class="merite-siteheading-expandable-arrowicon" @click="isVisible = !isVisible">
        <svg
          class="merite-siteheading-expandable-arrowicon-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 7.41"
          :class="{ active: isVisible }"
        >
          <path d="M10.59,0,6,4.58,1.41,0,0,1.41l6,6,6-6Z" />
        </svg>
      </div>
    </div>
  </div>

  <div class="merite-siteheading-expandable-content-container" :class="{ active: isVisible }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SiteHeadingExpandable",
  data: function () {
    return {
      isVisible: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "Undefined",
    },
    color: {
      type: String,
      default: "#FF0000",
    },
  },
  computed: {
    fontColor() {
      return {
        color: this.color,
      };
    },
    lineColor() {
      return {
        "background-color": this.color,
      };
    },
  },
};
</script>

<style>
.merite-siteheading-container {
  width: intrinsic;
  width: -moz-max-content;
  width: -webkit-max-content;

  margin: 8em 0 4em 0;
}

.merite-siteheading-title {
  white-space: pre-line;
}

.merite-siteheading-title:hover {
  cursor: pointer;
}

.merite-siteheading-title-line {
  width: 100%;
  height: 10px;
}

.merite-siteheading-expandable-flex {
  display: flex;
  justify-content: flex-start;
}

.merite-siteheading-expandable-flex-child:nth-child(2) {
  flex: 1;

  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
}

.merite-siteheading-expandable-arrowicon {
  margin: 0 15vw 4em 0;
  padding: 1em;

  transition: all 250ms;
}

.merite-siteheading-expandable-arrowicon:hover {
  background-color: #f9f9f9;

  cursor: pointer;
}

.merite-siteheading-expandable-arrowicon-svg {
  width: 2em;
  height: 1em;

  fill: #a7a9ac;
  transition: all 250ms;
}

.merite-siteheading-expandable-arrowicon:hover .merite-siteheading-expandable-arrowicon-svg {
  fill: #3c3c3b;
}

.mertie-siteheading-expandable-content-container-flex {
  background-color: orange;

  height: 40vh;
}

.merite-siteheading-expandable-content-container {
  transition: max-height 1s cubic-bezier(0, 1, 0, 1);
  max-height: 0;

  overflow: hidden;
}

.merite-siteheading-expandable-content-container.active {
  max-height: 500%;
  transition: max-height 1s ease-in-out;
}

.merite-siteheading-expandable-arrowicon-svg.active {
  transform: rotateZ(180deg);
}
</style>