<template>
	<div class="merite-news-latest-full-container">
		<img :src="image" class="merite-news-latest-full-image" />
		<h2 class="merite-news-latest-full-title">{{ title }}</h2>
		<label class="merite-news-latest-full-date">{{ date }}</label>
		<p class="merite-news-latest-full-text">
			<slot></slot>
		</p>
		<a class="merite-news-latest-full-link" :href="link">Read the article</a>
	</div>
</template>

<script>
export default {
	name: "NewsFullItem",
	props: {
		title: {
			type: String,
			default: "undefined",
		},
		date: {
			type: String,
			default: "undefined",
		},
		image: {
			type: String,
			default: "undefined",
		},
		link: {
			type: String,
			default: "undefined",
		},
	},
};
</script>

<style>
.merite-news-latest-full-container {
	display: flex;
	flex-direction: column;
}
.merite-news-latest-full-image {
	width: 100%;
}
.merite-news-latest-full-title {
	font-size: 24pt;

	font-family: "Meta Pro";
	font-weight: 500;

	color: var(--charte-color-grey-2);

	margin: 1em 0 0 0;
	padding: 0;
}

.merite-news-latest-full-date {
	font-size: 10pt;

	font-family: "Meta Pro";
	font-weight: 500;

	color: var(--charte-color-grey-2);

	margin: 1em 0 0 0;
	padding: 0;
}

.merite-news-latest-full-text {
	font-size: 11pt;

	font-family: "Meta Pro";
	font-weight: 300;
}

.merite-news-latest-full-link {
	font-size: 11pt;

	font-family: "Meta Pro";
	margin: 1em 0 0 0;
	font-weight: 300;

	color: var(--charte-color-grey-2);
	text-decoration: none;
}

.merite-news-latest-full-link:hover {
	cursor: pointer;
	text-decoration: underline;
}
</style>