<template>
  <div class="merite-drawer">
    <Container>
      <div class="merite-header-container-mobile">
        <div class="merite-header-container-mobile-inner">
          <router-link :to="{ name: 'home' }"><img src="/assets/logow.svg"
              class="merite-header-mobile-logo" /></router-link>
        </div>
        <div class="merite-header-container-mobile-inner">
          <img src="/assets/icons/close.svg" class="merite-header-mobile-menuicon" v-on:click="closeDrawerFuncLocal" />
        </div>
      </div>

      <ul class="merite-header-menu-mobile">
        <li v-for="(menu, index) in menuArray" :key="index">
          <router-link :to="{ name: menu.page.description }">{{ menu.title }}</router-link>
          <ul class="merite-header-menu-dropdown" v-if="menu.menuitem != 0">
            <router-link v-for="(menuitem, menuindex) in menu.menuitem" :key="menuindex"
              :to="{ name: menuitem.page.description }">
              <li>{{ menuitem.title }}</li>
            </router-link>
          </ul>
        </li>
        <li>
          <router-link :to="{ name: 'supportus' }">Donate</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'participants' }">ORB</router-link>
        </li>
      </ul>

      <ul class="merite-header-languageswitcher-mobile">
        <li @click="changeLocale('lu')">LU</li>
        <li>|</li>
        <li @click="changeLocale('en')">EN</li>
        <li>|</li>
        <li @click="changeLocale('fr')">FR</li>
      </ul>
    </Container>
  </div>
</template>

<script>
import Container from "@/components/widgets/Container.vue";

export default {
  name: "Drawer",
  components: {
    Container,
  },
  data() {
    return {
      drawerOpen: false,
      menuArray: [],
    };
  },
  mounted() {
    let locale = this.$t("locale");
    fetch(process.env.VUE_APP_MERITECMS_URL + "menus?_locale=" + locale)
      .then((res) => res.json())
      .then((data) => {
        var sortedData = data.sort(function (a, b) {
          return a.id - b.id;
        });

        for (var m = 0; m < data.length; m++) {
          var menulvl1item = data[m];

          this.menuArray.push(menulvl1item);
        }
      });
  },
  methods: {
    closeDrawerFuncLocal() {
      console.log("trying");
      this.$emit("closeDrawer", "someValue");
    },
    closeDrawerFunc() {
      this.drawerOpen = false;
    },
    changeLocale(locale) {
      let page = window.location.pathname.substring(3);
      window.location = "/" + locale + page;
    },
  },
};
</script>

<style>
.merite-drawer {
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  overflow: hidden;

  background-image: url("@/assets/slider/header-blue.jpg");
  background-size: cover;

  z-index: 100;

  display: none;
}

/* Responsive design */
@media only screen and (max-width: 1400px) {
  .merite-drawer {
    display: block;
  }

  .merite-header-container {
    display: none;
  }

  .merite-header-largetext-container {
    width: 100vw;
  }

  .merite-header-container-mobile {
    display: flex;
    padding: 2em 0 0 0;
  }

  .merite-header-container-mobile-inner {
    flex: 1;
  }

  .merite-header-container-mobile-inner:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .merite-header-mobile-menuicon {
    height: 1.5em;
  }

  .merite-header-mobile-logo {
    height: 4em;
  }

  .merite-header-menu-mobile {
    list-style-type: none;

    color: white;

    padding: 0;
    margin: 1.5em 0 0 0;
  }

  .merite-header-menu-mobile li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    font-family: "Meta Pro";
    font-weight: 300;
    font-size: 12pt;

    padding-top: 1em;

    color: white;
    text-decoration: none;
  }

  .merite-header-menu-mobile a {
    color: white;
    text-decoration: none;
  }

  .merite-header-menu-link {
    position: relative;
  }

  .merite-header-menu-dropdown {
    display: block !important;
    position: unset !important;
    padding: 0 0 0 0;
    z-index: 900;
  }

  .merite-header-menu-dropdown li {
    background: none !important;
    display: block;
    padding: 0.4em 1em 0.4em 1em;
    color: white;
    margin: 2px 0 2px 0;
    white-space: nowrap;

    font-size: 11pt !important;

    min-width: 5em;

    position: relative;
    z-index: 999;
  }

  .merite-header-menu-mobile li:nth-child(1) .merite-header-menu-dropdown a:nth-child(6) li {
    border: none !important;
  }

  .merite-header-menu-mobile li:nth-child(2) .merite-header-menu-dropdown a:nth-child(2) li {
    border: none !important;
  }

  .merite-header-menu-dropdown li a {
    font-family: "Meta Pro";
    font-weight: 200;
    font-size: 11pt !important;

    letter-spacing: normal;
  }

  .merite-header-menu-dropdown li:hover {
    cursor: pointer;
  }

  .merite-header-languageswitcher-mobile {
    list-style-type: none;
    margin: 0;
    padding: 0;

    position: absolute;
    bottom: 2em;
    left: 50%;

    transform: translateX(-50%);
  }

  .merite-header-languageswitcher-mobile li {
    display: inline;

    font-family: "Meta Pro";
    font-weight: 300;
    font-size: 14pt;

    padding: 0 0.25em 0 0.25em;

    color: white;
  }
}</style>
