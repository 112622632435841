<template>
  <div class="merite-cookienotice" v-if="cookieAccepted == false">
    <div class="merite-cookie-innercontainer">
      <div class="merite-cookie-flex">
        <h3 class="merite-cookie-message">This website uses cookies to ensure you get the best experience on our website.</h3>
      </div>
      <div class="merite-cookie-flex">
        <button class="merite-cookie-button" @click="acceptCookies()">Accept</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieNotice",
  data() {
    return {
      cookieAccepted: false,
    };
  },
  mounted() {
    console.log(localStorage.getItem("cookiesaccepted"));
    if (localStorage.getItem("cookiesaccepted") == "true") {
      this.cookieAccepted = true;
    }
  },
  methods: {
    acceptCookies() {
      this.cookieAccepted = true;
      localStorage.setItem("cookiesaccepted", "true");
    },
  },
};
</script>

<style>
.merite-cookienotice {
  position: relative;
  width: 100%;

  background-color: #3c3c3b;

  z-index: 900;
}

.merite-cookie-innercontainer {
  display: flex;
  padding: 1em 20vw 1em 20vw;
  height: 100%;
}

.merite-cookie-flex:nth-child(1) {
  flex-grow: 1;
}

.merite-cookie-flex {
  display: flex;

  align-items: center;
  justify-content: left;
}

.merite-cookie-message {
  color: white;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;

  padding: 0;
  margin: 0;
}

.merite-cookie-button {
  background-color: #97d058;
  border: none;
  color: white;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;
  padding: 0.5em 2em 0.5em 2em;
}

.merite-cookie-button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .merite-cookie-innercontainer {
    flex-direction: column;

    padding: 2em;
  }
}
</style>