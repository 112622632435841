<template>
  <div class="pb-calendar">
    <SiteHeading :uid="7777" :title="schema.title" color="var(--charte-color-expedition)" animation="animate__fadeInLeft" />
    <Calendar :schema="schema"></Calendar>
  </div>
</template>

<script>
import Calendar from "@/components/widgets/Calendar.vue";
import SiteHeading from "@/components/widgets/SiteHeading.vue";

export default {
  name: "pb-calendar",
  components: {
    Calendar,
    SiteHeading,
  },
  props: {
    schema: {
      type: Object,
    },
  },
};
</script>

<style>
</style>