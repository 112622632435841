export default {
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
  "slider-merite-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An international youth organisation, open to any young person aged 14-25"])},
  "slider-faq-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’ll find the most frequently asked questions below"])},
  "slider-home-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your\npassion,\n purpose and\n place in the world"])},
  "page-usefullinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful Links"])},
  "home-get-engaged-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participate!"])},
  "home-get-involved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get involved"])},
  "home-get-involved-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joining the Mérite Jeunesse means, that you are partnering with the largest international pursuit of youth development in the world."])},
  "home-latest-news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest News"])},
  "home-card-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "home-card-text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to do your Award? Click here for details"])},
  "home-card-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Leaders"])},
  "home-card-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to become an Award Leader? Click here for details"])},
  "home-card-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Units"])},
  "home-card-text-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to start the Award at your school or youth organisation? Click here for details"])},
  "home-card-title-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoring & Support"])},
  "home-card-text-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Mérite Jeunesse supports the youth and we need your help to be able to realise projects and support young people to find their purpose, passion and place in the world."])},
  "home-card-title-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources Centre"])},
  "home-card-text-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you’ll find important and useful documents."])},
  "home-agenda-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Events"])},
  "home-agenda-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find our agenda with the most important events below"])},
  "meet-the-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet the Team"])},
  "merite-in-numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mérite Jeunesse in numbers"])},
  "merite-numers-active-participants-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1000"])},
  "merite-numers-active-participants-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active participants per year"])},
  "merite-numers-active-tutors-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["140"])},
  "merite-numers-active-tutors-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Leaders"])},
  "merite-numers-units-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["41"])},
  "merite-numers-units-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units"])},
  "merite-numers-award-years-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31"])},
  "merite-numers-award-years-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award years in Luxembourg"])},
  "agenda-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda"])},
  "agenda-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find our agenda with the most important events below"])},
  "resources-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources Centre"])},
  "resources-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you’ll find important and useful documents"])},
  "participants-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "participants-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to do your Award? Details below!"])},
  "participants-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why participate?"])},
  "participants-paragraph-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are many reasons for joining in the program:"])},
  "participants-paragraph-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the first day to the last it's a real adventure"])},
  "participants-paragraph-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll meet a lot of people and experience teamwork"])},
  "participants-paragraph-1-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll develop new skills and get physically active"])},
  "participants-paragraph-1-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll have fun with your own chosen program"])},
  "participants-paragraph-1-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll enjoy lots of new experiences and adventures"])},
  "participants-paragraph-1-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll discover talents you never thought you had"])},
  "participants-paragraph-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll do things you love and get a kick out of it"])},
  "participants-paragraph-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll receive a certificate which is recognized worldwide"])},
  "participants-paragraph-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenge yourself and join the Award!"])},
  "participants-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to?"])},
  "participants-paragraph-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To register for the Award you have to find out if your school or a local organisation is a licenced Award Unit."])},
  "participants-paragraph-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At your Award unit an Award leader will help you plan your individual program and set your goals. Once you have decided which activities you want to do for your Award, you have to sign the registration form and return it to your Award Leader."])},
  "participants-paragraph-2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If there is no local Award Unit around you, you can contact the National Office and maybe we can find a freelance Award Leader who can support you."])},
  "participants-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Framework"])},
  "participants-paragraph-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Award is about discovering yourself and your talents. Therefore, there are different levels and sections that need to be completed during the Award. You can choose your level and every section activity as it suits you best."])},
  "participants-paragraph-3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The three Award levels are:"])},
  "participants-framework-header-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze"])},
  "participants-framework-header-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silver"])},
  "participants-framework-header-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
  "participants-framework-header-1-label-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum age: 14"])},
  "participants-framework-header-1-label-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum participation: 6 months"])},
  "participants-framework-header-2-label-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum age: 15"])},
  "participants-framework-header-2-label-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum participation: 6 months"])},
  "participants-framework-header-3-label-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum age: 16"])},
  "participants-framework-header-4-label-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum participation: 12 months"])},
  "participants-framework-header-1-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Bronze level you need to complete one section during 6 months and the other sections during 3 months."])},
  "participants-framework-header-1-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Adventurous Journey lasts 1 night and 2 days"])},
  "participants-framework-header-2-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Silver level you need to complete 6 months in every section. If you are a non Bronze holder, you need to complete one section during 12 months and the others during 6 months."])},
  "participants-framework-header-2-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Adventurous Journey lasts 2 nights and 3 days."])},
  "participants-framework-header-3-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Gold level you need to complete 12 months in every section. If you a non Silver holder, you need to complete one section during 18 months and the others during 12 months."])},
  "participants-framework-header-3-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Adventurous Journey lasts 3 nights and 4 days."])},
  "participants-skill-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill"])},
  "participants-skill-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voluntary Service"])},
  "participants-skill-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical Recreation"])},
  "participants-skill-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adventurous Journey"])},
  "participants-skill-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold Residential Project"])},
  "participants-skill-description-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "participants-skill-description-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time invest"])},
  "participants-skill-description-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examples"])},
  "participants-skill-1-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose and / or improve a new or old hobby - anything except a sport"])},
  "participants-skill-1-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invest at least one hour per week"])},
  "participants-skill-1-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning a new instrument or improving one you already play"])},
  "participants-skill-1-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singing"])},
  "participants-skill-1-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speech and drama"])},
  "participants-skill-1-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chess"])},
  "participants-skill-1-items-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Aid"])},
  "participants-skill-1-items-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snail farming"])},
  "participants-skill-1-items-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journalism"])},
  "participants-skill-2-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You should invest your time in something useful in order to develop a sense of responsibility. You should not be paid for your service, however, if you actually are paid, you should donate the money earned"])},
  "participants-skill-2-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invest at least one hour per week"])},
  "participants-skill-2-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helping people (volunteering at a Crèche, helping older people or people with special needs, …)"])},
  "participants-skill-2-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litter picking"])},
  "participants-skill-2-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animal Welfare"])},
  "participants-skill-2-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporting a charity like Red-Cross, Caritas …"])},
  "participants-skill-2-items-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching in a sport or other activity (scouting)"])},
  "participants-skill-3-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You should invest your time in a sport. Physical activity helps you not only to establish a healthy lifestyle, but also to find a balance between body and mind."])},
  "participants-skill-3-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invest at least one hour per week"])},
  "participants-skill-3-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All kind of ball games"])},
  "participants-skill-3-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running"])},
  "participants-skill-3-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golf"])},
  "participants-skill-3-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dance"])},
  "participants-skill-3-items-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yoga, Pilates"])},
  "participants-skill-3-items-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martial Arts"])},
  "participants-skill-4-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is the only section of the Award that needs to be completed in a team. It should not only encourage a sensibility for the nature and the environment, but also help developing a team spirit. In order to complete this section of the Award you need to undertake four main steps:"])},
  "participants-skill-4-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The length of your Adventurous Journey depends on the level you are completing."])},
  "participants-skill-4-items-ol-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparation workshop"])},
  "participants-skill-4-items-ol-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice Journey"])},
  "participants-skill-4-items-ol-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualifying Journey"])},
  "participants-skill-4-items-ol-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliver a report to you assessor"])},
  "participants-skill-4-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By foot"])},
  "participants-skill-4-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By bicycle"])},
  "participants-skill-4-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By kayak "])},
  "participants-skill-4-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By horse"])},
  "participants-skill-5-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undertake a shared purposeful activity with people who are not your usual colleagues or friends, in an unfamiliar residential setting. "])},
  "participants-skill-5-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 4 nights and 5 consecutive days"])},
  "participants-skill-5-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helping deafblind young people and adults to enjoy a holiday"])},
  "participants-skill-5-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Being an assistant to support an eco-friendly waste project at an outdoor education centre "])},
  "participants-skill-5-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assisting at a summer camp "])},
  "participants-skill-5-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helping at an orphanage"])},
  "participants-skill-5-items-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improving your Spanish language skills on a course in Madrid (or any other language in any other country)"])},
  "participants-framework-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every Adventurous Journey needs to be declared and accepted at least 3 weeks in advance."])},
  "participants-framework-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every Gold Residential Project needs to be declared and accepted at least 6 weeks in advance."])},
  "participants-framework-paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mérite Jeunesse reserves the right to refuse inadequate Gold Residential Projects."])},
  "participants-framework-paragraph-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Mérite Jeunesse declines every responsibility for Adventurous Journeys and Gold Residential Projects, which have not been declared properly."])},
  "participants-title-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information for Parents"])},
  "participants-parents-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not all learning happens in the classroom. Young people need experiences outside the classroom to become committed, responsible and fulfilled citizens of the world. "])},
  "participants-parents-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a non-formal education framework, the Award can play a vital role in providing opportunities for young people to develop essential life skills, increase their employability and foster their creativity and innovation."])},
  "participants-parents-paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Mérite Jeunesse is part of an international Award programme. The Award is about personal challenge and development and is adaptable to each participant's interests and abilities. It presents young people with a balanced programme of voluntary activities, encouraging personal discovery and growth, self-reliance, perseverance, responsibility and service to their community."])},
  "participants-parents-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPEN TO EVERY YOUNG PERSON"])},
  "participants-parents-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GREAT FOR YOUR CV"])},
  "participants-parents-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT'S A PROCESS, NOT A PRICE"])},
  "participants-parents-paragraph-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any young person aged 14-24 can do the Award, no matter their gender, cultural background, religious or political affiliation or physical ability. An Award is earned through individual improvement and achievement, which means that disadvantaged young people and those with special needs are able to participate fully!"])},
  "participants-parents-paragraph-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having your Award looks great on enrolments for study courses, scholarships and CVs. The Award shows commitment and achievement, as well as dedication to a variety of activities. It also gives young people an opportunity to talk about themselves and their passions outside of their formal education."])},
  "participants-parents-paragraph-2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Award is a process of personal and social development; the activities which make up the Award, are a means to this end. Youngsters learn from the new experiences they make and discover hidden capabilities and talents."])},
  "participants-parents-paragraph-2-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our ambition is that every young person aged 14 to 24 in Luxembourg should have the opportunity to participate in the Award."])},
  "awardleaders-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Leaders"])},
  "awardleaders-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to become an Award Leader? Details below!"])},
  "awardleaders-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why participate?"])},
  "awardleaders-paragraph-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The role of an Award Leader is to guide and mentor young participants. Award Leaders help to set achievable goals and to offer encouragement. They are the support system for participants who offer motivation and inspiration."])},
  "awardleaders-paragraph-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyone who is willing to support the Mérite Jeunesse and wants to guide young people can be an Award Leader. You can either be an Award Leader in a Unit or a Freelance Award Leader."])},
  "awardleaders-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to?"])},
  "awardleaders-paragraph-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to become an Award Leader, you need to complete the Training. If you want to assess and supervise the Adventurous Journey, you need to complete the Adventurous Journey Supervisor and Assessor Training."])},
  "awardleaders-paragraph-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both trainings are held in Luxembourgish and English at least once a year."])},
  "awardleaders-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Leader"])},
  "awardleaders-paragraph-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Award Leader is a volunteer who is guiding their participants through the Award program. Every Award Unit shall have at least two Award Leaders to spread the workload fairly. One of the "])},
  "awardleaders-paragraph-3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaders is the Coordinator, who ensures that the program is running well and who is the main contact person of the National Office."])},
  "awardleaders-title-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adventurous Journey Assessor and Supervisors"])},
  "awardleaders-paragraph-4-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Adventurous Journey Supervisor plans and prepares the expedition together with their participants. During the Adventurous Journey, the assessor and supervisor are close to the area of the Journey."])},
  "awardleaders-paragraph-4-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They contact the expedition group at least once a day and are checking on them to see, if they have no struggles with the time requirements or other problems. Once the entire journey is completed, the Assessor should debrief the team."])},
  "awardleaders-title-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainings"])},
  "awardleaders-paragraph-5-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These trainings are designed for teachers and youth workers who want to deliver the Mérite Jeunesse program to the youth. They can be part of a school or organisation, which already is an Award Unit or interested in becoming one. "])},
  "awardleaders-paragraph-5-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is also possible to be a freelance Award Leader and to support participants, who do not find an Award Unit. "])},
  "awardleaders-paragraph-5-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any questions, please contact the National Office."])},
  "awardunits-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Units"])},
  "awardunits-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to start the Award at your school or youth organisation? Details below!"])},
  "awardunits-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why participate?"])},
  "awardunits-paragraph-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Being an Award Unit means that you are forming a partnership with the largest international pursuit of youth development in the world!"])},
  "awardunits-paragraph-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The unique flexibility of the Award makes it ideally suited to easy adaptation and integration into different cultures and societies. The basic principles of the Award remain the same but the activities and delivery continue to evolve and adapt to suit the changing demands of modern society and the varying needs of young people."])},
  "awardunits-paragraph-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The flexibility and sustainability of the International Award has allowed for steady growth over the 60 years that it has successfully operated internationally. By now, the Award operates in more than 130 countries worldwide."])},
  "awardunits-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefits for Award Units:"])},
  "awardunits-benefits-list-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within the program you can make use of the activities you already offer to young people "])},
  "awardunits-benefits-list-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a chance to boost personal development of young people through a time proven and worldwide program with measurable results and international prestige "])},
  "awardunits-benefits-list-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can offer young people educational goals that are based on their own interests and abilities and help them use their leisure time productively"])},
  "awardunits-benefits-list-1-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can support personal and social responsibility of the youth"])},
  "awardunits-benefits-list-1-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By leading the young participants in the program you will improve your own organizational and professional skills"])},
  "awardunits-benefits-list-1-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will get in touch with other Award Leaders participating in the program"])},
  "awardunits-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to?"])},
  "awardunits-how-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are interested in setting up an Award Unit, you need to complete several steps:"])},
  "awardunits-how-list-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A member of the National Office will visit your Award Unit and clarifies the details with you and the interested staff"])},
  "awardunits-how-list-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least two people need to complete the Award Leader Training"])},
  "awardunits-how-list-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to sign a sublicense"])},
  "awardunits-how-list-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now, you are an Award Unit and allowed to guide young people through their program "])},
  "awardunits-how-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no more to it than this. If you want to set up an Award Unit, feel free to contact us at"])},
  "supportus-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support us"])},
  "supportus-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Mérite Jeunesse supports the youth, and we need your help to be able to realise projects and support young people to find their purpose, passion and place in the world"])},
  "supportus-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support us"])},
  "supportus-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not all learning happens in the classroom. Young people need experiences outside the classroom to become committed, responsible and fulfilled citizens of the world."])},
  "supportus-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Mérite Jeunesse supports the youth, and as a foundation we need your help to be able to realise projects and to support our participants in their individual program. With your donation you support our long-term ambition to give every young person in Luxembourg the possibility to participate in the Mérite Jeunesse program."])},
  "supportus-paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matter how big or small, every donation will make a real difference to the young people who want to do their Award. It is with your support, that our young people will be ready to face the challenges of the modern world."])},
  "supportus-paragraph-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can donate by Digicash or by bank transfer to our account:"])},
  "supportus-heading-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank transfer"])},
  "supportus-label-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the yearly sum to associations exceeds 120 €, the donation can be deducted from your taxes in accordance with existing regulations. You will receive a tax certificate for your donation."])},
  "supportus-label-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With agreement and convention of"])},
  "supportus-label-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported by"])},
  "faq-description-text-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please keep in mind that the Award is a marathon and not a sprint. In circumstances like these, some activities need to be postponed and other activities can be done from home."])},
  "faq-description-text-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you a participant? Then you might have asked yourself these questions!"])},
  "faq-description-text-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does your child want to do the Mérite Jeunesse? Then you might have asked yourself these questions!"])},
  "faq-description-text-4-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you an Award Leader? Then you might have asked yourself these questions!"])},
  "faq-description-text-5-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you intrested in forming an Award Unit? Then you might have asked yourself these questions!"])},
  "faq-description-text-6-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to support the Mérite Jeunesse? Then you might have asked yourself these questions!"])},
  "faq-section-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COVID 19"])},
  "faq-section-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "faq-section-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents"])},
  "faq-section-title-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Leader "])},
  "faq-section-title-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Units"])},
  "faq-section-title-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsors and Supporters"])},
  "faq-button-text-close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide all questions"])},
  "faq-button-text-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all questions"])},
  "faq-section-1-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How should I contact my Award Leader or Activity Coach while school and youth organizations are closed?"])},
  "faq-section-1-1-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may have an email address or contact number of your Award Leader or Activity Coach you can use to call or send a text. If not, please ask the National Office to put you in touch with your Award Leader."])},
  "faq-section-1-1-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You might also consider setting up a messaging group on social media with other Award participants, so you can share ideas, motivate each other, and stay in touch during this time."])},
  "faq-section-1-1-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please keep in mind that every physical contact should be avoided as far as possible."])},
  "faq-section-1-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I cannot continue my physical activity because the club has closed and/or my team’s training sessions are cancelled. How should I proceed to continue with my Physical section?"])},
  "faq-section-1-2-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please remember to continue your physical activity only if you are healthy."])},
  "faq-section-1-2-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your regular activity is not on now, there are many ways you can continue maintaining relevant strength and fitness. Whilst mass gatherings must be avoided, you could consider walking or running in your local area – or even doing a workout (or practicing your sport) in your garden. Apps like Strava or Google Fit can help you monitor your activities and provide evidence for your Assessor."])},
  "faq-section-1-2-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are also lots of fitness sessions and classes that can be followed online (YouTube) and done within your home; from Pilates to learning dance routines, boot camps to HIIT workouts. "])},
  "faq-section-1-2-description-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matter which option is most suitable for you, please talk to your Award Leader in advance, ask for his/her approval, and clarify how you will document your activity."])},
  "faq-section-1-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I continue with my Voluntary Service section?"])},
  "faq-section-1-3-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are many possibilities to continue your Voluntary Service section depending on your activity. You might ask your Award Leader or Activity Coach, if there are things you can do from home, like creating a social media campaign or preparing administrative paperwork."])},
  "faq-section-1-3-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this option is not suitable for your activity, you might consider changing your activity. During the pandemic, you can offer your help and, for example, offer grocery shopping to people at risk. This clearly counts as activity for your Voluntary Service section."])},
  "faq-section-1-3-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please speak to your Award Leader about these options. If there is no other possibility, you can pause your activity. Please keep in mind that a pause should be the very last option. Before taking a final decision, talk with your Award Leader about every possibility."])},
  "faq-section-1-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My neighbor is self-isolating; can I help do their shopping for my Voluntary Service section?"])},
  "faq-section-1-4-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you cannot continue your usual activity, this clearly is a great option and a sign of solidarity. "])},
  "faq-section-1-4-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this is an option for you now, please keep the sanitary rules and recommendations of the government in mind. Before changing your activity, please seek approval from your Award Leader."])},
  "faq-section-1-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I continue my Skills activity from home?"])},
  "faq-section-1-5-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your usual activity for this section is cancelled, you can consider what you can do from home for this section. If you are playing an instrument or singing, you might intensify your practicing at home. The most common activities for this section can easily be done from home. Ask your Award Leader or Activity Coach, for suitable activities and possible alternatives. You might also consider looking for online tutorials or online learning classes. If this is not an option for you, you can do some research about your project. "])},
  "faq-section-1-5-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before taking a final decision, please speak to your Award Leader or Activity Coach to seek their approval and to fix a suitable way of recording your progress."])},
  "faq-section-1-6-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I wanted to start my Skills section now. Which possibilities do I have?"])},
  "faq-section-1-6-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For this section, there are many possible activities, which can be done from home. Wouldn’t it be nice to learn how to cook or to bake? Or how to sew? If you are playing an instrument, you can practice at home. You can also consider learning a new language or learning how to draw/paint. You can also consider taking an online course on a certain subject."])},
  "faq-section-1-6-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A complete listing of every possible activity for the Skills section is available under this link: https://merite.jeunesse.lu/sites/default/files/pages/files/DofE-programme-ideas.pdf"])},
  "faq-section-1-6-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before starting your activity, please speak to your Award Leader and ask for approval."])},
  "faq-section-1-7-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Adventurous Journey is cancelled. What should I do now?"])},
  "faq-section-1-7-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you planned an Adventurous Journey for April, it has to be postponed. Most Adventurous Journeys will be organized later this year. The responsible Assessor will inform you as soon as possible."])},
  "faq-section-1-7-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are leaving your Award Unit, before you had the possibility to catch up on the Adventurous Journey, ask your Assessor, if you still can join the expedition. If this option is not suitable for you, you might consider taking part in the next national expedition. If not, please contact the National Office and together we will find a solution for your situation."])},
  "faq-section-1-8-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I registered for the national expedition. What should I do now?"])},
  "faq-section-1-8-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your registration is still valid, just as the registration for the preparation workshop planned for 28thMarch 2020. If the national expedition has to be postponed, we will inform you as soon as possible via email and the new date will be published on our website."])},
  "faq-section-1-8-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The preparation workshop will probably take place together with the practice expedition. More information will follow as soon as possible."])},
  "faq-section-1-9-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I had to cancel my Gold Residential Project. What should I do now?"])},
  "faq-section-1-9-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you had planned a Gold Residential Project for the upcoming weeks, it probably has been cancelled. Your planned project might be postponed to a later date and you can catch up on it as soon as the situation has calmed down. Try to prepare as much as possible from home. Please speak with your Award Leader or the person responsible of the project about your possibilities. "])},
  "faq-participants-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where can I register to join the Award?"])},
  "faq-participants-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to registerwith your Award Leader of your Unit. A list of our Units is online here."])},
  "faq-participants-8-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to do the Award, but neither my school or youth organisation is an Award Unit."])},
  "faq-participants-8-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your school or organisation is not listed here, contact the National Office. They can help you."])},
  "faq-participants-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do I need to complete every section at the same time?"])},
  "faq-participants-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. You can split your Award and do one section at a time."])},
  "faq-participants-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much does the Award cost?"])},
  "faq-participants-3-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your inscription costs 5 € per level. If you want to start with your Silver Award as a direct entrant, it costs 10 €. If you want to start with your Gold Award as direct entrant, it costs 15 €.These costs are fixed by the National Office and valid for every participant."])},
  "faq-participants-3-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["However, some Units charge extra costsfor a more intense supervision and some other Units pay the inscription fees for their participants.So,it is possible that the inscription fee varies from Unit to Unit."])},
  "faq-participants-3-3-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The participation fees can be covered by the Office Social.Next to your inscription fees, it could bepossible, that you need to pay foryour sport or skill, if you are joining a club. Often the Adventurous Journey is linked with costs too."])},
  "faq-participants-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Award Unit does not organise an Adventurous Journey. Where can I enrol for the National Expedition?"])},
  "faq-participants-4-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the Resources Centre you will find the inscription forms. Please hand them over to your Award Leader. They will forward it to the National Office."])},
  "faq-participants-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am an active scout. Do I need to complete the preparation workshop for the Adventurous Journey?"])},
  "faq-participants-5-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. However, we recommend that you prepare yourself together with your team for your Adventurous Journeyto have an effective teambuilding in advance."])},
  "faq-participants-6-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do I need to complete a preparation workshop and a preparation journey at every Award Level?"])},
  "faq-participants-6-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not change your mode of travel, you only need to complete the preparation workshop once. If you had a longer break between your levels, we recommend refreshing the workshop.The preparation journey is mandatory at every level.Due to the Covid-19 pandemic, there are some temporary changes to the preparation journeyat Bronze and Silver level."])},
  "faq-participants-7-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am I allowed to do my Gold Residential Project together with my friends?"])},
  "faq-participants-7-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Gold Residential Project should take place in an unfamiliar environment with people you do not know. Actually, it is best, if you do not know anyone who is participating. If one of your friends wantsto do the same project, please make sureto be in different groupsduring the project activities."])},
  "faq-parents-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My child wants to do the Award. Why should I encourage them?"])},
  "faq-parents-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Award encourages the individual development of each participant. As every participant can choose their own activities, theprogram is designed to suit the participant’s interests best. To complete some sections, the participant needs to leave their comfort zone and gets to know themselves better, while collecting important life experience."])},
  "faq-parents-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If my child is joining the Award, does they still have enough time for school?"])},
  "faq-parents-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If they are doing three activities at the same time, they might be too busyto do their homework and prepare for tests."])},
  "faq-parents-2-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Award programme is flexible and adapts to the individual needs of every participant. Your child does not have to do every activityat the same time, but is allowed todo activities one by one. If there is a time, they cannot run their activities, they are allowed to have a break of a week. That week can be added at the end."])},
  "faq-parents-2-3-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition, it is important that every child has something beyond the classroom. With this individual and varied programme, participants acquire soft skills that will be useful in their later life."])},
  "faq-parents-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who is supervising my child?"])},
  "faq-parents-3-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every participant is mentored by a trained Award Leader. The Award leaders often are teachers or educators at the SEPAS or youth houses. To guarantee the best quality, every Award Leader needs to complete our Award Leader training. The Award Leaders, who are supervising and assessing the Adventurous Journey, need to complete another more specific training."])},
  "faq-parents-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there an insurance provided by the Mérite Jeunesse?"])},
  "faq-parents-4-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, the participantsare insuredforall their activities as soon as they have their inscription form handed in. If their activities include extra risk, we cannot provide insurance."])},
  "faq-parents-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you allowed to change the activity, if you do not enjoy it?"])},
  "faq-parents-5-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is always possible that the initially chosen activity does not suit the participant. If it is not the right activity for that person,it isallowed to change, if the Award Leader agrees. If that is the case, the time spent on the activity remains valid. However, changing the activity should stay an exception, as the Award’s goal is to encourage perseverance and sticking to one’s activities until the end."])},
  "faq-leaders-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where can I registerto become an Award Leader?"])},
  "faq-leaders-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer an English and a Luxembourgish Award Leader training at least once a year. You need to enrol via the National Office. If you are working at a public school, you additionally can enrol via IFEN too."])},
  "faq-leaders-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do I need to be active in an Award Unit in order to be an Award Leader?"])},
  "faq-leaders-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Either you can be a freelance Award Leader or you can ask your school / organisation, if they are interested in becoming an Award Unit."])},
  "faq-leaders-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am an experienced scout and I want to organise the Adventurous Journey at my Award Unit. Do I need to complete the other part of the training too?"])},
  "faq-leaders-3-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. You can do the additional training to have a better insight of how the Adventurous Journey works at the Award, but it is not mandatory. Please inform the National Office about your scouting experience, before organising an Adventurous Journey."])},
  "faq-leaders-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why do the inscriptions forthe preparation workshop and the National Expeditionorganised by the National Officeneed to be handed in by the Award Leaders?"])},
  "faq-leaders-4-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the inscriptions are handled by the Award Leaders, they are automatically informed, if their participants are enrolled for the workshop and/ or the National Expeditionorganised by the National Office."])},
  "faq-leaders-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where do I find the necessary documents for the inscriptions and declarations?"])},
  "faq-leaders-5-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the necessary documents are availableat the Resources Centre."])},
  "faq-units-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are the benefits for my school or organisation, when joining the Award?"])},
  "faq-units-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joining the Award has benefits for young people as well as for Award Leaders."])},
  "faq-units-1-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The participants discover themselves through the program and at the end they receive a diploma, which is recognised and appreciated worldwide."])},
  "faq-units-1-3-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When guiding participants through their Award, Award Leaders are taking over the role of a mentor. For most of the adults in the Award, this is an exciting experience as they get to know the participants in a completely newand different way."])},
  "faq-units-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How should I proceed to set upan Award Unit?"])},
  "faq-units-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are interested in setting up an Award Unit, just write a mail to info(at)merite.lu.The person in charge for your kind of organisation will get back to you as soon as possible and will clarify the details with you."])},
  "faq-units-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does becoming an Award Unit cost?"])},
  "faq-units-3-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, there are no costs in becoming an Award Unit. However, you need to foresee some costs linked to the activities as the Award Leader training or the Adventurous Journey.Some Units alsopay the inscription fees of 5-15€ for their participants."])},
  "faq-sponsors-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why should I support the Mérite Jeunesse?"])},
  "faq-sponsors-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Mérite Jeunesse is a program where young people can set their own goals, leave their comfort zone and rise above themselves.It is a character-building experience.To ensure the best support to our participants, we rely on your support."])},
  "faq-sponsors-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What happens with my donation?"])},
  "faq-sponsors-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you decided to do a donation, you will receive a description of what happens with your money. "])},
  "faq-sponsors-2-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With donations we financeonly our activities like National Expedition, the supervision of Gold Expeditions and the organisation of the Award Ceremony."])},
  "merite-description-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Mérite Jeunesse Luxembourg is part of the Duke of Edinburgh’s International Award, the world’s leading youth achievement award, which is operating in over 130 territories. "])},
  "merite-description-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Award is available to all 14-24 year olds and equips young people for life regardless of their background, culture, physical ability, skills and interests. The Award is a flexible program, during which you can set individual goals and try to reach them with no time pressure."])},
  "merite-description-paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do your Award: Get active in 4 different sections and learn a new skill, do a voluntary service, practice a physical activity (sports) and go on an adventurous journey. You can design your own Award program, set your own goals and record your progress."])},
  "merite-description-paragraph-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Award is about personal challenge and development and is adaptable to each participant’s interests and abilities. It presents young people with a balanced program of voluntary activities, encouraging personal discovery and growth, self-reliance, perseverance, responsibility and service to their community."])},
  "merite-description-paragraph-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more about the International Award: www.intaward.org"])},
  "team-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "team-sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sections"])},
  "team-job-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrative assistant"])},
  "team-job-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National Director"])},
  "team-job-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsible for Communication and National Activities"])},
  "team-job-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adventurous Journey Expert (Freelance)"])},
  "team-text-astrid-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration of the Trainings, the Adventurous Journeys and the Gold Residential Projects"])},
  "team-text-astrid-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Record Book support"])},
  "team-text-astrid-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event management support"])},
  "team-text-astrid-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookkeeping and statistics"])},
  "team-text-astrid-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality and Development"])},
  "team-text-christine-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relations with the Board and the MENJE"])},
  "team-text-christine-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relations with the international and private schools"])},
  "team-text-christine-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relations with the sponsors and the IAF"])},
  "team-text-christine-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finances and statistics"])},
  "team-text-christine-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Trainings"])},
  "team-text-christine-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality and Development"])},
  "team-text-christine-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality and Development"])},
  "team-text-christine-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event management"])},
  "team-text-jil-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relations with the public Award Units (public schools and youth organisations)"])},
  "team-text-jil-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourgish Trainings"])},
  "team-text-jil-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration of the Adventurous Journeys"])},
  "team-text-jil-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication and PR"])},
  "team-text-jil-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event management & Workshops"])},
  "team-text-ben-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Assessment of the Adventurous Journeys organised by the Award Units"])},
  "team-text-ben-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold level Adventurous Journey:"])},
  "team-text-ben-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support during the preparation"])},
  "team-text-ben-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervision of the expedition"])},
  "team-text-ben-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support of the Adventurous Journey Assessors and Supervisors in organising their Journeys"])},
  "merite-conseil-text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An administrative council, chaired by H.R.H. Prince Guillaume, is leading the foundation Mérite Jeunesse."])},
  "merite-conseil-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members of the administrative council and their roles:"])},
  "team-timing-astrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday to Thursday (only in the mornings)"])},
  "team-timing-christine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday to Friday"])},
  "team-timing-jil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday to Friday"])},
  "team-conseil-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H.R.H. Prince Guillaume"])},
  "team-conseil-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["President"])},
  "team-conseil-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vice-President"])},
  "team-conseil-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treasurer"])},
  "team-conseil-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secretary"])},
  "team-conseil-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
  "team-conseil-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication and Marketing"])},
  "team-conseil-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resources"])},
  "team-conseil-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoring"])},
  "team-conseil-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Assurance"])},
  "team-conseil-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resources"])},
  "team-conseil-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special projects"])},
  "team-conseil-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adventurous Journeys and Trainings"])},
  "form-orb-selectlang-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred language"])},
  "form-orb-selectlang-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "form-orb-selectlang-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourgish"])},
  "form-orb-selecttime-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot"])},
  "form-orb-selecttime-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option 1: 15:30-16:30 in Luxembourgish"])},
  "form-orb-selecttime-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option 2: 17:00-18:00 in English"])},
  "form-exp-unitlist-none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit not listed"])},
  "form-exp-food-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch on Saturday"])},
  "form-exp-food-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(is included in the 50€ participation fee)"])},
  "form-exp-food-2-a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(needs to be paid for)"])},
  "form-exp-food-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "form-exp-food-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "form-exp-food-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you vegetarian or vegan?"])},
  "form-exp-food-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "form-exp-food-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegetarian"])},
  "form-exp-food-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegan"])},
  "form-exp-food-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have any food intolerances?"])},
  "form-disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By registering for the event, I give permission to Mérite Jeunesse to use any photos and/or videos of me that were taken/recorded during the event. The photos/videos may be used to illustrate publications, for promoting the program on social networks and for printed publications of Mérite Jeunesse for an undefined period of time."])},
  "form-template-unitnotlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is your unit not listed?"])}
}