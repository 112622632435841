<template>
  <div class="merite-news-latest-container">
    <Container>
      <div class="merite-news-latest-flex-container">
        <div class="merite-news-latest-flex-mobile">
          <div class="merite-news-latest-flex-mobile-item" v-for="newspagecurrent in newspagesArray"
            :key="newspagecurrent">
            <NewsFullItem :title="newspagecurrent.newspage.title" :date="newspagecurrent.newspage.date"
              :image="generateLink(newspagecurrent.newspage.image.formats.small.url)"
              :link="'news/' + newspagecurrent.newspage.description"
              class="animate__animated merite-news-latest-item-animation">
              {{ newspagecurrent.newspage.text }}
            </NewsFullItem>
          </div>
        </div>
        <div class="merite-news-latest-flex left">
          <NewsFullItem :title="newspagesFront.title" :date="newspagesFront.date"
            :image="generateLink(newspagesFront.image.formats.large.url)" :link="'news/' + newspagesFront.description">
            {{ newspagesFront.text }}
          </NewsFullItem>
        </div>
        <div class="merite-news-latest-flex right">
          <div v-for="newspagecurrent in newspagesArray" :key="newspagecurrent">
            <NewsSmallItem :title="newspagecurrent.newspage.title" :date="newspagecurrent.newspage.date"
              :image="generateLink(newspagecurrent.newspage.image.formats.small.url)"
              :link="'news/' + newspagecurrent.newspage.description"
              class="animate__animated merite-news-latest-item-animation"></NewsSmallItem>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import $ from "jquery";
import AnimationScroller from "@/lib/AnimationScroller";

import Container from "@/components/widgets/Container.vue";

import NewsFullItem from "@/components/widgets/News/NewsFullItem.vue";
import NewsSmallItem from "@/components/widgets/News/NewsSmallItem.vue";

export default {
  name: "pb-news",
  components: {
    Container,
    NewsFullItem,
    NewsSmallItem,
  },
  data() {
    return {
      newspagesArray: Object,
      newspagesFront: Object,
    };
  },
  props: {
    schema: {
      type: Object,
    },
  },
  mixins: [AnimationScroller],
  created() {
    // Get News

    var newspages = this.schema.newspages;

    var largeNews = newspages[0];

    this.newspagesFront = largeNews.newspage;
    this.newspagesArray = newspages;
  },
  mounted() {
    this.viewportAnimationScroller($(".merite-news-latest-item-animation"), "animate__fadeInRight");
  },
  methods: {
    generateLink(link) {
      console.log("generate link");
      if (link.startsWith("/")) {
        return process.env.VUE_APP_MERITECMS_URL + link.substring(1);
      } else {
        return link;
      }
    },
  },
};
</script>

<style>
.merite-news-latest-flex-mobile {
  display: none;
}

.merite-news-latest-container {
  margin: 0 0 8em 0;
  overflow-x: hidden;
}

.merite-news-latest-flex-container {
  display: flex;
}

.merite-news-latest-flex.left {
  border-right: 1px solid #3c3c3b45;
  padding: 0 4em 0 0;
}

.merite-news-latest-flex.right {
  padding: 0 0 0 4em;
}

.merite-news-latest-flex {
  flex: 1;
}

.merite-news-latest-item-animation:nth-child(1) {
  animation-delay: 0ms;
}

.merite-news-latest-item-animation:nth-child(2) {
  animation-delay: 200ms;
}

.merite-news-latest-item-animation:nth-child(3) {
  animation-delay: 400ms;
}

.merite-news-latest-item-animation:nth-child(4) {
  animation-delay: 600ms;
}

.merite-news-latest-item-animation:nth-child(5) {
  animation-delay: 800ms;
}

@media only screen and (max-width: 600px) {
  .merite-news-latest-flex-container {
    flex-direction: row;
    overflow-x: scroll;
  }

  .merite-news-latest-flex {
    display: none;
  }

  .merite-news-latest-flex-mobile {
    display: flex;
    flex-direction: row;
  }

  .merite-news-latest-flex-mobile-item {
    width: 50vw;
    margin-left: 10vw;
    padding: 15vw;

    border-radius: 1em;

    background-color: var(--charte-color-grey-1);
  }

  .merite-news-latest-flex-mobile-item:last-child {
    margin-right: 15vw;
  }

  .merite-news-latest-container .merite-container {
    margin: 0;
  }
}
</style>
