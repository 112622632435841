<template>
  <Team></Team>
</template>

<script>
import Team from "@/components/widgets/Team.vue";

export default {
  name: "pb-meriteteam",
  components: {
    Team,
  },
};
</script>

<style>
</style>