<template>
  <SiteText>
    {{ schema.text }}
  </SiteText>
</template>

<script>
import SiteText from "@/components/widgets/SiteText.vue";

export default {
  name: "pb-sitetext",
  components: {
    SiteText,
  },
  props: {
    schema: {
      type: Object,
    },
  },
};
</script>

<style>
</style>