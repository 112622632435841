<template>
  <SiteHeadingExpandable :title="schema.title" color="var(--charte-color-sport)">
    <Container>
      <ul class="merite-awardunits-list">
        <li class="merite-awardunits-list-item" v-for="unit in units" :key="unit">
          <div class="merite-awardunits-list-item-container">
            <div class="merite-awardunits-list-item-inner img">
              <img :src="generateLink(unit.UnitMedia.formats.thumbnail.url)" />
            </div>
            <div class="merite-awardunits-list-item-inner">
              {{ unit.UnitName }}
            </div>
            <div class="merite-awardunits-list-item-inner">
              {{ unit.UnitResponsable }}
            </div>
            <div class="merite-awardunits-list-item-inner">
              {{ unit.UnitResponsableEmail }}
            </div>
          </div>
        </li>
      </ul>
    </Container>
  </SiteHeadingExpandable>
</template>

<script>
import Container from "@/components/widgets/Container.vue";
import SiteHeadingExpandable from "@/components/widgets/SiteHeadingExpandable.vue";

export default {
  name: "pb-units",
  components: {
    Container,
    SiteHeadingExpandable,
  },
  data() {
    return {
      units: [],
    };
  },
  props: {
    schema: {
      type: Object,
    },
  },
  created() {
    console.log(this.schema.unittype);
    fetch(process.env.VUE_APP_MERITECMS_URL + "award-units-items")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        var sortedData = data.sort(function (a, b) {
          return a.UnitName - b.UnitName;
        });

        var unitArray = [];

        for (var i = 0; i < sortedData.length; i++) {
          if (sortedData[i].AwardType == this.schema.unittype) {
            unitArray.push(sortedData[i]);
          }
        }

        this.units = unitArray;
      });
  },
  methods: {
    generateLink(link) {
      console.log(link);
      if (link.startsWith("/")) {
        return process.env.VUE_APP_MERITECMS_URL + link.substring(1);
      } else {
        return link;
      }
    },
  },
};
</script>

<style>
.merite-awardunits-list {
  list-style-type: none;
}

.merite-awardunits-list-item {
  line-height: 32pt;
}

.merite-awardunits-list-item img {
  height: 40pt;
}

.merite-awardunits-list-item:nth-child(odd) {
  background-color: var(--charte-color-grey-1);
}

.merite-awardunits-list-item-container {
  display: flex;
}

.merite-awardunits-list-item-inner {
  flex: 1;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;

  color: var(--charte-color-grey-2);

  display: flex;
  align-items: center;

  padding: 1em 1em 1em 1em;
}

@media only screen and (max-width: 600px) {
  .merite-awardunits-list-item-inner.img {
    display: none;
  }

  .merite-awardunits-list {
    padding: 0;
  }

  .merite-awardunits-list-item-container {
    flex-direction: column;
  }

  .merite-siteheading-expandable-flex-child h2 {
    font-size: 16pt !important;
    word-break: break-word;
    display: block !important;
  }
}
</style>
