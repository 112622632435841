<template>
  <div class="merite-agenda-next-container">
    <Container>
      <div class="merite-agenda-next-flex-container">
        <div class="merite-agenda-next-flex" v-for="agItem in agendaData" :key="agItem">
          <AgendaItem v-if="agItem['title'] != 'undefined'" :date="agItem['dateText']" :title="agItem['title']"
            :element="agItem['element']" :description="agItem['description']" class="merite-agenda-item-animation">
            <div v-html="agItem['descriptionfull']"></div>
          </AgendaItem>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import AgendaItem from "@/components/widgets/Agenda/AgendaItem.vue";
import Container from "@/components/widgets/Container.vue";
import { marked } from 'marked';

export default {
  name: "AgendaNext",
  props: {},
  components: {
    AgendaItem,
    Container,
  },
  data() {
    return {
      agendaData: [],
    };
  },
  setup() { },
  created() { },
  mounted() {
    let locale = this.$t("locale");
    fetch(process.env.VUE_APP_MERITECMS_URL + "page-agenda?_locale=" + locale)
      .then((res) => res.json())
      .then((data) => {
        var documentLength = data.length;

        for (var k = 0; k < documentLength; k++) {
          data[k].datefrom = new Date(data[k].datefrom).getTime();
        }

        data.sort((a, b) => {
          return a.datefrom - b.datefrom;
        });

        for (var j = 0; j < documentLength; j++) {
          var seconds = data[j].datefrom;
          var newDate = new Date(seconds);

          data[j]["date"] = newDate;
          data[j]["dateText"] = newDate.getDate() + "/" + (newDate.getMonth() + 1);

          let agendaItemDescription = data[j]["description"];
          data[j]["descriptionfull"] = marked(agendaItemDescription);

          let agendaItemMaxLength = 200;
          if (agendaItemDescription.length > agendaItemMaxLength) {
            data[j]["description"] = agendaItemDescription.substring(0, agendaItemMaxLength) + "...";
          }
        }

        var currentDate = new Date();
        var newData = [];
        for (var j = 0; j < documentLength; j++) {
          var seconds = data[j].datefrom;
          var newDate = new Date(seconds);

          if (newDate > currentDate) {
            newData.push(data[j]);
          }
        }

        if (documentLength < 4) {
          var missingLength = 4 - documentLength;
          for (var i = 0; i < missingLength; i++) {
            newData.push({
              date: "0/0",
              title: "undefined",
              description: "undefined",
            });
          }
        }

        //this.agendaData = newData.slice(0, 4);
        this.agendaData = newData;
      });
  },
};
</script>

<style>
.merite-agenda-next-container {
  background-color: var(--charte-color-grey-1);
  padding: 4em 0 4em 0;

  overflow-y: hidden;
}

.merite-agenda-next-flex-container {
  display: flex;
  flex-direction: row;
}

.merite-agenda-next-flex:nth-child(1) {
  border-left: 1px solid #3c3c3b22;
}

.merite-agenda-next-flex {
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: 2em 3em 2em 3em;

  border-right: 1px solid #3c3c3b22;
  border: 1px solid gren;
}

.merite-agenda-item-0 {
  animation-delay: 0ms;
}

.merite-agenda-item-1 {
  animation-delay: 200ms;
}

.merite-agenda-item-2 {
  animation-delay: 400ms;
}

.merite-agenda-item-3 {
  animation-delay: 600ms;
}

.merite-agenda-item-4 {
  animation-delay: 800ms;
}

.merite-agenda-item-5 {
  animation-delay: 1000ms;
}
</style>
