<template>
	<div class="merite-news-small-container">
		<div class="merite-news-small-flex left">
			<img :src="image" class="merite-news-small-image" />
		</div>
		<div class="merite-news-small-flex right">
			<h2 class="merite-news-small-item-title">
				{{ title }}
			</h2>
			<label class="merite-news-small-item-date">{{ date }}</label>
			<a class="merite-news-latest-full-link" :href="link">Read the article</a>
		</div>
	</div>
</template>

<script>
export default {
	name: "NewsSmallItem",
	props: {
		title: {
			type: String,
			default: "undefined",
		},
		date: {
			type: String,
			default: "undefined",
		},
		image: {
			type: String,
			default: "undefined",
		},
		link: {
			type: String,
			default: "undefined",
		},
	},
};
</script>

<style>
.merite-news-small-container {
	display: flex;
	flex-direction: row;

	margin: 0 0 1.5em 0;
}

.merite-news-small-flex {
	display: flex;
	flex-direction: column;

	justify-content: center;
}

.merite-news-small-flex.right {
	padding-left: 1.5em;
}

.merite-news-small-image {
	width: 12em;
}

.merite-news-small-item-title {
	margin: 0;
	padding: 0;

	color: var(--charte-color-grey-2);
	font-family: "Meta Pro";
	font-weight: 500;
	font-size: 14pt;
}

.merite-news-small-item-date {
	margin: 0;
	padding: 0;

	font-family: "Meta Pro";
	font-weight: 500;
	font-size: 11pt;

	color: #a7a9ac;
}

.merite-news-latest-full-link {
	font-size: 11pt;

	font-family: "Meta Pro";
	margin: 1em 0 0 0;
	font-weight: 300;

	color: var(--charte-color-grey-2);
	text-decoration: none;
}
</style>