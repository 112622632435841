<template>
  <div class="merite-contactform-container">
    <div class="classmerite-contactform-background">
      <div class="merite-contactform-outer">
        <SiteHeading :title="title" color="#FFFFFF" />
        <Container>
          <div class="merite-contactform-flex-container">
            <div class="merite-contactform-flex single">
              {{ textblock1 }}
            </div>
            <div class="merite-contactform-flex double">
              {{ textblock2 }}
            </div>
            <div class="merite-contactform-flex half"></div>
          </div>

          <div class="merite-contactform-flex-container">
            <div class="merite-contactform-flex single">
              <div class="merite-contactform-input-container left">
                <div class="merite-contactform-input-block">
                  <label class="merite-contactform-label">{{ contactname }}</label
                  ><br />
                  <input class="merite-contactform-input" type="text" v-model="name" :class="{ required: required[0] }" /><br />
                </div>
                <div class="merite-contactform-input-block">
                  <label class="merite-contactform-label">{{ contactsurname }}</label
                  ><br />
                  <input class="merite-contactform-input" type="text" v-model="surname" :class="{ required: required[1] }" /><br />
                </div>
                <div class="merite-contactform-input-block">
                  <label class="merite-contactform-label">{{ contactemail }}</label
                  ><br />
                  <input class="merite-contactform-input" type="text" v-model="email" :class="{ required: required[0] }" /><br />
                </div>
              </div>
            </div>
            <div class="merite-contactform-flex double">
              <div class="merite-contactform-input-container">
                <div class="merite-contactform-input-block half">
                  <label class="merite-contactform-label">{{ contactsubject }}</label
                  ><br />
                  <input class="merite-contactform-input" type="text" v-model="subject" :class="{ required: required[0] }" /><br />
                </div>
                <div class="merite-contactform-input-block">
                  <label class="merite-contactform-label">{{ contactmessage }}</label
                  ><br />
                  <textarea class="merite-contactform-textarea" v-model="message" :class="{ required: required[0] }"></textarea>
                  <button class="merite-contactform-submit" type="submit" :value="contactsend" @click="validateForm">
                    {{ btnText }}</button
                  ><br />
                </div>
              </div>
            </div>
            <div class="merite-contactform-flex half"></div>
          </div>
        </Container>
      </div>
    </div>
  </div>
</template>

<script>
import { useReCaptcha } from "vue-recaptcha-v3";
import emailjs from "emailjs-com";

import Container from "@/components/widgets/Container.vue";

import SiteHeading from "@/components/widgets/SiteHeading.vue";
import SiteText from "@/components/widgets/SiteText.vue";

export default {
  name: "ContactForm",
  components: {
    Container,
    SiteHeading,
    SiteText,
  },
  props: {
    title: {
      type: String,
      default: "Undefined",
    },
    textblock1: {
      type: String,
      default: "Undefined",
    },
    textblock2: {
      type: String,
      default: "Undefined",
    },
    contactname: {
      type: String,
      default: "Undefined",
    },
    contactsurname: {
      type: String,
      default: "Undefined",
    },
    contactemail: {
      type: String,
      default: "Undefined",
    },
    contactsubject: {
      type: String,
      default: "Undefined",
    },
    contactmessage: {
      type: String,
      default: "Undefined",
    },
    contactsend: {
      type: String,
      default: "Undefined",
    },
  },
  data() {
    return {
      name: "",
      surname: "",
      email: "",
      subject: "",
      message: "",
      btnText: this.contactsend,
      required: [false, false, false, false, false],
      emailre: false,
    };
  },
  mounted() {
    /*
    
    */
  },
  methods: {
    getRequired(re) {
      console.log("required");
      if (this.required[re]) {
        return true;
      } else {
        return false;
      }
    },
    validateForm() {
      var data = [this.name, this.surname, this.email, this.subject, this.message];
      let validationFailed = false;
      for (let i = 0; i < data.length; i++) {
        let dataItem = data[i];
        if (dataItem == "") {
          this.required[i] = true;
          validationFailed = true;
        } else {
          this.required[i] = false;
        }
      }
      if (validationFailed == false) {
        this.$recaptchaLoaded().then(() => {
          console.log("recaptcha loaded");
          this.$recaptcha("login").then((token) => {
            console.log("submit");

            const payload = {
              name: this.name,
              surname: this.surname,
              email: this.email,
              subject: this.subject,
              text: this.message,
            };

            var result = emailjs.send("service_fnbne7l", "template_6a6wyen", payload, "user_JuNjewNde6j2lpBy0VAek");
            alert("Your message has been send");
            window.location.reload();
          });
        });
      }
    },
  },
};
</script>

<style>
.merite-contactform-container {
  height: 100vh;
}

.classmerite-contactform-background {
  /*background-image: url("/assets/contact/background.jpg");*/
  background-size: cover;
  background-position: center;
  background-color: var(--charte-color-expedition);
}

.merite-contactform-outer {
  height: 100vh;

  padding: 2em 0 0 0;
}

.merite-contactform-flex-container {
  display: flex;
}

.merite-contactform-flex {
  flex: 1;

  color: white;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  display: flex;
  flex-direction: column;

  align-items: stretch;

  white-space: pre-line;
}

.merite-contactform-flex.half {
  flex-grow: 0.5;
}

.merite-contactform-flex.single {
  flex-grow: 1;
}

.merite-contactform-flex.double {
  padding-left: 2em;
  flex-grow: 2;
}

.merite-contactform-input-container {
  margin: 2em 0 0 0;

  height: 100%;
}

.merite-contactform-input-container.left {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  padding-right: 4em;
}

.merite-contactform-input {
  padding: 1em;

  background-color: transparent;

  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);

  width: 100%;
  box-sizing: border-box;

  color: white;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;

  transition: background-color 250ms;
}

.merite-contactform-input.required {
  border-bottom: 1px solid rgba(255, 0, 0, 0.4);
}

.merite-contactform-input:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.15);
}

.merite-contactform-textarea {
  padding: 1em;

  background-color: rgba(255, 255, 255, 0.1);
  border: none;

  width: 100%;
  height: 15em;
  box-sizing: border-box;

  color: white;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  transition: background-color 250ms;
}

.merite-contactform-textarea.required {
  border: 1px solid rgba(255, 0, 0, 0.4);
}

.merite-contactform-textarea:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.2);
}

.merite-contactform-input-block {
  padding: 2em 0 1em 0;
}

.merite-contactform-input-block.half {
  width: 50%;
}

.merite-contactform-submit {
  width: 100%;
  padding: 1em;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  border: none;
  background-color: rgba(255, 255, 255, 0.25);

  color: white;

  text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
  .merite-contactform-flex-container {
    flex-direction: column;
  }

  .merite-contactform-container {
    height: auto;
  }

  .merite-contactform-outer {
    height: auto;
    padding-bottom: 4em;
  }

  .merite-contactform-flex.double {
    padding: 2em 0 0 0;
  }
  .merite-contactform-input-container.left {
    border: 0;
  }
}
</style>
