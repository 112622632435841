<template>
  <Container>
    <div class="imagelink-main">
      <a target="_blank" :href="schema.Link">
        <div
          class="imagelink-image-container"
          v-bind:style="{ backgroundImage: 'url(https://mcms.imagify.design' + schema.Image.url + ')' }"
        ></div
      ></a>
      <div class="imagelink-text-container">
        <h2 class="imagelink-text-title">{{ schema.Title }}</h2>
        <a class="imagelink-text-link" target="_blank" :href="schema.Link" v-if="schema.Description != null">{{ schema.Description }}</a>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from "@/components/widgets/Container.vue";

export default {
  name: "pb-imagelink",
  components: {
    Container,
  },
  props: {
    schema: {
      type: Object,
    },
  },
};
</script>

<style>
.imagelink-main {
  display: flex;

  margin: 5vh 0 5vh 0;
}

.imagelink-image-container {
  height: 40vh;
  width: 40vw;

  background-color: red;
  background-position: center;
  background-size: cover;
}

.imagelink-text-container {
  width: 30vw;
  height: 40vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
}

.imagelink-text-title {
  font-size: 2em;

  font-family: "Meta Pro";
  font-weight: 500;

  padding: 0 0 0 2vw;
  margin: 0;
}

.imagelink-text-link {
  font-size: 1em;

  font-family: "Meta Pro";
  font-weight: 500;

  color: var(--charte-color-talent);

  padding: 0 0 0 2vw;
  margin: 0;
}

.merite-cms-container div:nth-child(odd) .merite-outer-container .imagelink-main {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 600px) {
  .imagelink-main {
    flex-direction: column;
  }

  .imagelink-image-container {
    width: 80vw;
  }

  .imagelink-text-container {
    width: 80vw;
    height: 20vh;
  }

  .merite-cms-container div:nth-child(odd) .merite-outer-container .imagelink-main {
    flex-direction: column;
  }
}
</style>
