<template>
  <div class="merite-links-container-outer">
    <Container>
      <div class="merite-links-container">
        <div class="merite-links-row">
          <a class="merite-links-inner merite-links-yellow animate__animated" href="https://www.onlinerecordbook.org/fo/"
            target="_blank">
            <div class="merite-links-inner-block">Online Record Book</div>
          </a>
          <a class="merite-links-inner merite-links-blue animate__animated" href="https://intaward.org/" target="_blank">
            <div class="merite-links-inner-block">International Award</div>
          </a>
        </div>
        <div class="merite-links-row">
          <a class="merite-links-inner merite-links-purple animate__animated" href="https://www.awardcommunity.org/ac/"
            target="_blank">
            <div class="merite-links-inner-block">Award Community</div>
          </a>
          <a class="merite-links-inner merite-links-red animate__animated" href="https://alumni.intaward.org/"
            target="_blank">
            <div class="merite-links-inner-block">Global Award Alumni</div>
          </a>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import $ from "jquery";
import AnimationScroller from "@/lib/AnimationScroller";
import Container from "@/components/widgets/Container.vue";

export default {
  components: { Container },
  name: "LinksContainer",
  mixins: [AnimationScroller],
  mounted() {
    this.viewportAnimationScroller($(".merite-links-yellow"), "animate__fadeInLeft");
    this.viewportAnimationScroller($(".merite-links-blue"), "animate__fadeInRight");
    this.viewportAnimationScroller($(".merite-links-purple"), "animate__fadeInLeft");
    this.viewportAnimationScroller($(".merite-links-red"), "animate__fadeInRight");
  },
};
</script>

<style>
.merite-links-container {
  overflow-x: hidden;
  margin: 0 0 5em 0;
}

.merite-links-row {
  display: flex;

  --links-container-spacing: 15px;

  margin: 0 0 var(--links-container-spacing) 0;
}

.merite-links-inner {
  height: 30vh;
  transition: all 300ms;
  flex: 1;
  display: flex;

  text-decoration: none;
}

.merite-links-inner:hover {
  cursor: pointer;
}

.merite-links-yellow {
  background-image: url("@/assets/useful/useful-yellow.jpg");
  background-position: center;
  background-size: cover;

  flex-grow: 1.5;

  margin: 0 var(--links-container-spacing) 0 0;
}

.merite-links-blue {
  background-image: url("@/assets/useful/useful-blue.jpg");
  background-position: center;
  background-size: cover;
  margin: 0 0 0 0;
}

.merite-links-purple {
  background-image: url("@/assets/useful/useful-purple.jpg");
  background-position: center;
  background-size: cover;
  margin: 0 var(--links-container-spacing) 0 0;
}

.merite-links-red {
  background-image: url("@/assets/useful/useful-red.jpg");
  background-position: center;
  background-size: cover;
  flex-grow: 1.5;
  margin: 0 0 0 0;
}

.merite-links-row a div {
  display: flex;
  align-items: flex-end;

  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 28pt;

  color: white;

  padding: 0 0 0.5em 1em;
}

.merite-links-inner-block {
  display: flex;
  align-items: flex-end;

  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 28pt;

  color: white;

  padding: 0 0 0.5em 1em;
}

@media only screen and (max-width: 600px) {
  .merite-links-row {
    flex-direction: column;
  }

  .merite-links-inner {
    margin: 0 0 var(--links-container-spacing) 0;
    min-height: 25vh;

    border-radius: 0.5em;
  }

  .merite-links-row {
    margin: 0;
  }

  .merite-links-container-outer .merite-container {
    margin: 0 5vw 0 5vw;
  }

  .merite-links-row div {
    font-size: 18pt;
  }

  .merite-links-inner-block {
    font-family: "Meta Pro";
    font-size: 18pt !important;
  }
}</style>