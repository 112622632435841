export default {
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lu"])},
  "slider-merite-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eng international Jugendorganisatioun, oppe fir all Jonken tëst 14 a 25 Joer."])},
  "slider-faq-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fannt hei déi heefegst gestallte Froen"])},
  "slider-home-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your\npassion,\npurpose and\nplace in the world"])},
  "page-usefullinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nëtzlech Links"])},
  "home-get-engaged-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matmaachen!"])},
  "home-get-involved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maach mat"])},
  "home-get-involved-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Mérite Jeunesse matzemaachen, bedeit mat an d’Boot vun deem weltwäit gréisste Jugendprogramm ze klammen."])},
  "home-latest-news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lescht Neiegkeeten"])},
  "home-card-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "home-card-text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wëlls däi Mérite maachen? Da fann hei all déi néideg Infos!"])},
  "home-card-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Leaders"])},
  "home-card-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wëlls als Tuteur/Tutrice aktiv ginn? Da fann hei all déi néideg Infos!"])},
  "home-card-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Units"])},
  "home-card-text-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wëlls, dass deng Schoul/Jugendorganisatioun eng Unitéit gëtt? Da fann hei all déi néideg Infos!"])},
  "home-card-title-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoring & Support"])},
  "home-card-text-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Mérite Jeunesse ënnerstëtzt déi Jonk a mir brauchen är Hëllef fir Projeten ze realiséieren an deene Jonken ze hëllefen hir Bestëmmung, Passioun a Plaz an der Welt ze fannen."])},
  "home-card-title-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources Centre"])},
  "home-card-text-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hei fannt Dir all wichteg an nëtzlech Dokumenter."])},
  "home-agenda-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächst Eventer"])},
  "home-agenda-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hei fannt Dir eis Agenda mat de wichtegsten Datumer"])},
  "meet-the-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons Équipp"])},
  "merite-in-numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mérite Jeunesse an Zuelen"])},
  "merite-numers-active-participants-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1000"])},
  "merite-numers-active-participants-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv Participanten pro Joer"])},
  "merite-numers-active-tutors-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["140"])},
  "merite-numers-active-tutors-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuteurs"])},
  "merite-numers-units-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["41"])},
  "merite-numers-units-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units"])},
  "merite-numers-award-years-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31"])},
  "merite-numers-award-years-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joer Award zu Luxembourg"])},
  "agenda-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda"])},
  "agenda-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hei fannt Dir eis Agenda mat de wichtegsten Datumer"])},
  "resources-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources Centre"])},
  "resources-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hei fannt Dir all wichteg an nëtzlech Dokumenter"])},
  "participants-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "participants-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wëlls däi Mérite maachen? Da fann hei all déi néideg Infos!"])},
  "participants-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firwat matmaachen?"])},
  "participants-paragraph-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et gëtt vill Grënn fir beim Programm aktiv ze ginn:"])},
  "participants-paragraph-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vum éischte bis de leschten Dag ass et ee richtegt Abenteuer"])},
  "participants-paragraph-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du léiers vill Leit kennen an am Team ze funktionéieren"])},
  "participants-paragraph-1-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du léiers nei Saachen a bass och kierperlech aktiv"])},
  "participants-paragraph-1-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wäerts Spaass hu mat dengem Programm, deen s du dir selwer zesummestelle kanns"])},
  "participants-paragraph-1-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kanns dech un neien Erfarungen an Erliefnisser erfreeën"])},
  "participants-paragraph-1-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du entdecks Talenter an dir un déi s du ni gegleeft häss"])},
  "participants-paragraph-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du méchs Saachen, déi dir Freed maachen an déi zu denger Passioun ginn"])},
  "participants-paragraph-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kriss ee Certificat, dee weltwäit unerkannt ass"])},
  "participants-paragraph-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stell dech der Erausfuerderung a maach mat beim Mérite Jeunesse!"])},
  "participants-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wéi matmaachen?"])},
  "participants-paragraph-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fir dech beim Mérite anzeschreiwen kanns du hei erausfannen ob deng Schoul oder eng Organisatioun wou s du aktiv bass och eng Unitéit bei eis ass."])},
  "participants-paragraph-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An denger Unitéit sichs du dir een Tuteur aus, deen dech wärend dem Programm begleet an ënnerstëtzt. Wann s de bis däi Programm zesummegestallt hues an dech umelle wëlls, muss de den ausgefëllte Formulaire bei dengem Tuteur ofginn."])},
  "participants-paragraph-2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann s du keng Unitéit fënns, da mell dech beim Nationalbureau a vläicht fanne mir ee Freelance Tuteur, deen dech ënnerstëtze wëll."])},
  "participants-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D’Struktur vum Award"])},
  "participants-paragraph-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Programm vum Mérite Jeunesse hëlleft dir dech selwer an deng Talenter ze entdecken. Et gi verschidde Voleten an dräi ënnerschiddlech Niveauen. Du kanns dir däin Niveau an all deng Aktivitéite selwer eraussichen."])},
  "participants-paragraph-3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déi dräi Niveauen:"])},
  "participants-framework-header-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze"])},
  "participants-framework-header-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sëlwer"])},
  "participants-framework-header-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
  "participants-framework-header-1-label-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestalter: 14"])},
  "participants-framework-header-1-label-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestdauer: 6 Méint"])},
  "participants-framework-header-2-label-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestalter: 15"])},
  "participants-framework-header-2-label-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestdauer: 6 Méint"])},
  "participants-framework-header-3-label-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestalter: 16"])},
  "participants-framework-header-4-label-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestdauer: 12 Méint"])},
  "participants-framework-header-1-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Niveau Bronze muss du an engem Volet eng Aktivitéit iwwer 6 Méint maachen an déi aner iwwer 3 Méint."])},
  "participants-framework-header-1-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D’Expeditioun dauert 1 Nuecht an 2 Deeg."])},
  "participants-framework-header-2-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann s du nom Bronze mam Sëlwer weiderfiers, muss du deng Aktivitéite wärend 6 Méint maachen. Falls du direkt Sëlwer méchs a kee Bronze hues, muss du eng Aktivitéit, wärend 12 Méint maachen an déi aner wärend 6 Méint."])},
  "participants-framework-header-2-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D’Expeditioun dauert 2 Nuechten an 3 Deeg."])},
  "participants-framework-header-3-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann s du nom Sëlwer mam Gold weiderfiers, muss du deng Aktivitéite wärend 12 Méint maachen. Falls du direkt Gold méchs a kee Sëlwer hues, muss du eng Aktivitéit wärend 18 Méint maachen an déi aner wärend 12 Méint."])},
  "participants-framework-header-3-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D’Expeditioun dauert 3 Nuechten a 4 Deeg."])},
  "participants-skill-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talent"])},
  "participants-skill-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benevolat"])},
  "participants-skill-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
  "participants-skill-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expeditioun"])},
  "participants-skill-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet Résidentiel Gold "])},
  "participants-skill-description-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktioun"])},
  "participants-skill-description-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wéi laang"])},
  "participants-skill-description-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiller"])},
  "participants-skill-1-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiel dir een neit oder alt Hobby aus, dat s de léieren oder verbessere wëlls. Et kann alles sinn, ausser ee Sport"])},
  "participants-skill-1-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Woch eng Stonn aktiv sinn"])},
  "participants-skill-1-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een neit Instrument léieren oder eent perfektionéieren"])},
  "participants-skill-1-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sangen"])},
  "participants-skill-1-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theater"])},
  "participants-skill-1-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schach"])},
  "participants-skill-1-items-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éischt Hëllef"])},
  "participants-skill-1-items-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlécken ziichten"])},
  "participants-skill-1-items-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journalistesch Schreiwe léieren"])},
  "participants-skill-2-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Benevolat solls du deng Zäit sënnvoll notzen a fir d'Gesellschaft investéieren ouni dofir bezuelt ze ginn. Falls de awer dofir bezuelt gëss, muss de d'Sue spenden"])},
  "participants-skill-2-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Woch eng Stonn aktiv sinn"])},
  "participants-skill-2-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leit hëllefen (an enger Crèche, engem Altersheim an engem Foyer du jour,..)"])},
  "participants-skill-2-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dreck oprafen"])},
  "participants-skill-2-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Déiere kucken (am Déierenasyl, als Dogsitter,…"])},
  "participants-skill-2-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv hëllefen an enger Jugend Associatioun, de Pompjeeën, de Scouten, Caritas, Croix-Rouge…"])},
  "participants-skill-2-items-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Trainer am Sport oder enger anerer Aktivitéiten hëllefen"])},
  "participants-skill-3-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An dësem Volet solls de dech sportlech betätege fir eng Balance zwëschent Kierper a Geescht ze fannen an ee gesonde Liewensstil ze developpéieren."])},
  "participants-skill-3-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Woch eng Stonn aktiv sinn"])},
  "participants-skill-3-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Zort vu Ballsport"])},
  "participants-skill-3-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lafe goen"])},
  "participants-skill-3-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golf"])},
  "participants-skill-3-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danzen"])},
  "participants-skill-3-items-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yoga, Pilates"])},
  "participants-skill-3-items-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kampfsport"])},
  "participants-skill-4-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et ass deen eenzege Volet, deen als Team absolvéiert muss ginn. D'Expéditioun soll d'Participante fir d'Natur an d'Ëmwelt sensibiliséieren a gläichzäiteg hëllefen ee gewëssen Teamgeescht ze entwéckelen.Dëse Volet komplettéiers du a 4 Schrëtt:"])},
  "participants-skill-4-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'Dauer vun denger Expeditioun hänkt vun dengem Niveau of"])},
  "participants-skill-4-items-ol-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparatiouns-Atelier"])},
  "participants-skill-4-items-ol-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparatiounsexpeditioun"])},
  "participants-skill-4-items-ol-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Expeditioun "])},
  "participants-skill-4-items-ol-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ee Rapport"])},
  "participants-skill-4-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Fouss"])},
  "participants-skill-4-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Vëlo"])},
  "participants-skill-4-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mam Kayak"])},
  "participants-skill-4-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mam Päerd"])},
  "participants-skill-5-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei dësem Volet solls de eng sënnvoll Aktivitéit an engem dir friemen Ëmfeld mat dir frieme Leit maachen"])},
  "participants-skill-5-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op mannst 4 Nuechten a 5 Deeg"])},
  "participants-skill-5-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ee Sproochecours am Ausland"])},
  "participants-skill-5-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An engem Summercamp hëllefen"])},
  "participants-skill-5-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["European Youth Parliament"])},
  "participants-skill-5-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Ëmweltprojet am Ausland"])},
  "participants-skill-5-items-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["..."])},
  "participants-framework-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Expeditioun muss 3 Wochen am viraus am Nationalbüro deklaréiert an acceptéiert ginn."])},
  "participants-framework-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Projet Residentiel Gold muss 6 Wochen am viraus ugemellt an acceptéiert ginn."])},
  "participants-framework-paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Mérite Jeunesse behält sech d’Recht vir ee Projet Residentiel Gold ze refuséieren, falls een net als passend ageschat gëtt."])},
  "participants-framework-paragraph-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Mérite Jeunesse iwwerhëlt keng Verantwortung fir Expeditiounen oder Projet Residentiels Gold, déi net richteg deklaréiert goufen."])},
  "participants-title-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fir d'Elteren"])},
  "participants-parents-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net alles, wat een am Liewe brauch, léiert een an der Schoul. Jonker brauchen och Erfarungen ausserhalb vum Klassesall fir responsabel, engagéiert an zefridde Matbierger ze ginn."])},
  "participants-parents-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als non-formal Bildungsstruktur, kann den Award eng wichteg Roll spille fir deene Jonke Méiglechkeeten ze bidden, wichteg Kompetenzen ze léieren, hir Chancen um Aarbechtsmarché ze verbesseren an hir Kreativitéit an Innovatioun ze fërderen."])},
  "participants-parents-paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Mérite Jeunesse ass Deel vun enger weltwäiter Organisatioun. Dëse weltwäit gläiche Programm ass ee perséinlechen Challenge a kann un den Interessi vun all Jonkem ugepasst ginn. Et ass ee balancéierte Programm, dee komplett fräiwëlleg ass an d'Entwécklung vun de Jonke fuerdert. "])},
  "participants-parents-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zougänglech fir jiddereen"])},
  "participants-parents-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super fir an de CV"])},
  "participants-parents-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et ass ee Prozess a kee Präis"])},
  "participants-parents-paragraph-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Jugendlechen tëscht 14-24 Joer ka beim Mérite matmaachen, egal wéi ee Geschlecht, kulturellen Hannergrond, reliéis oder politesch Iwwerzeegungen oder physesch Viraussetzungen. Een Award kritt een duerch perséinlech Fortschrëtter, déi ee mécht, wat bedeit, dass et och fir Jonker mat engem Handicap guer kee Problem ass um Programm deelzehuelen."])},
  "participants-parents-paragraph-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann ee studéiere geet, eng Léier oder een Austausch mécht gesäit et gutt aus, wann ee beim Programm vum Mérite matgemaach huet. Et weist, dass ee sech och a senger Fräizäit engagéiert huet an Aktivitéiten duerchgezunn huet. Et gëtt engem och d’Méiglechkeet iwwert sech an seng Passiounen ausserhalb vun der Schoul ze schwätzen."])},
  "participants-parents-paragraph-2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Mérite ass ee Prozess vu perséinlechem a sozialen Developpement an d'Aktivitéite vun de verschiddene Voleten hëllefen dëse Prozess ze ënnerstëtzen. Duerch déi nei Erfarungen entdeckt een nei a verstoppten Talenter a Fäegkeeten."])},
  "participants-parents-paragraph-2-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eis Ambitioun ass, dass all Jonken tëschent 14-24 Joer aus Lëtzebuerg d’Méiglechkeet soll hunn, fir beim Mérite Jeunesse  kënnen deelzehuelen."])},
  "awardleaders-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Leaders"])},
  "awardleaders-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wëlls als Tuteur/Tutrice aktiv ginn? Da fann hei all déi néideg Infos!"])},
  "awardleaders-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firwat matmaachen?"])},
  "awardleaders-paragraph-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'Roll vum Tuteur ass et d'Participanten z'encadréieren an ze motivéieren. Si stinn am direkte Kontakt mam Participant a sinn do fir si z’ënnerstëtzen a realistesch Ziler festzesetzen."])},
  "awardleaders-paragraph-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiddereen, dee motivéiert ass, déi Jonk duerch de Mérite Jeunesse Programm ze begleeden, kann Tuteur ginn. Et kann een als Tuteur an enger Unitéit täteg sinn oder als Freelance déi Jonk encadréieren."])},
  "awardleaders-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wéi matmaachen? "])},
  "awardleaders-paragraph-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fir als Tuteur aktiv ze ginn, muss een déi néideg Formatioun ofschléissen. Falls een och wëll déi Jugendlech beim Volet Expeditioun begleeden, muss een nach zousätzlech d'Formatioun zum Sujet Expeditioun ofschléissen."])},
  "awardleaders-paragraph-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Béid Formatioune ginn ee Mol d’Joer op Lëtzebuergesch an op Englesch ugebueden."])},
  "awardleaders-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuteur"])},
  "awardleaders-paragraph-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een Tuteur ass ee Benevole, deen déi Jugendlech duerch de Programm begleet. All Unitéit muss mindestens zwee Tuteuren hunn, déi sech déi verschidden Aufgaben opdeelen."])},
  "awardleaders-paragraph-3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ee vun den Tuteuren ass och de Coordinateur a këmmert sech dorëms, dass de Programm gutt leeft an ass d’Haaptkontaktpersoun fir den Nationalbureau."])},
  "awardleaders-title-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabel Expeditioun"])},
  "awardleaders-paragraph-4-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir d’Expeditioun lass geet, plangt dee Responsabel vum Grupp, zesumme mat deene Jonken d’Expeditioun. Mä och wärend der Expeditioun ass de Responsabelen an der Géigend an trefft sech op mannst eemol den Dag mat de Participante fir ze kucken, ob se gutt an der Zäit sinn, ob et jidderengem gutt geet, ob se am Team funktionéieren, asw. "])},
  "awardleaders-paragraph-4-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An no der Expeditioun mécht en een Debriefing mat deene Jonken a kuckt hire Rapport."])},
  "awardleaders-title-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatioun"])},
  "awardleaders-paragraph-5-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D’Formatioune vum Mérite Jeunesse riichte sech u Leit aus dem Enseignement, Educateuren an all Erwuessenen, dee mat Jonke schafft oder schaffe wëll. Et kann een aus enger Schoul oder Organisatioun sinn, déi ee Partenariat mam Mérite Jeunesse ofgeschloss huet, oder genau heirunner interesséiert wier. "])},
  "awardleaders-paragraph-5-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et ass awer och méiglech sech als Freelance Tuteur z’engagéieren an domadder deene Jonken, déi keng Unitéit fannen, een Encadrement ubidden."])},
  "awardleaders-paragraph-5-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann Dir Froen huet, kontaktéiert den Nationalbüro."])},
  "awardunits-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Units"])},
  "awardunits-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wëlls, dass deng Schoul/Jugendorganisatioun eng Unitéit gëtt? Da fann hei all déi néideg Infos!"])},
  "awardunits-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firwat matmaachen?"])},
  "awardunits-paragraph-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eng Unitéit ze ginn, bedeit mat an d’Boot vun deem weltwäit gréisste Jugendprogramm ze klammen."])},
  "awardunits-paragraph-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déi eenzegaarteg Flexibilitéit vum Mérite mécht et einfach fir de Programm deene verschiddene Kulturen a Gesellschaften unzepassen. D’ Grondprinzippie bleiwen ëmmer déi selwecht, mee d’Duerchféierung an d’Aktivitéite variéiere jee no Bedürfnis vun deene Jonken aus deem jeeweilege Land."])},
  "awardunits-paragraph-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op Grond vun dëser Flexibilitéit a Bestännegkeet vum Mérite Jeunesse, konnt de Programm an deene leschte 60 Joer konstant wuessen, sech weiderentwéckelen an huet sech mëttlerweil an iwwer 130 Länner etabléiert."])},
  "awardunits-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virdeeler fir d’Unitéiten:"])},
  "awardunits-benefits-list-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dir kënnt déi Aktivitéiten, déi Dir de Jonken schonn elo offréiert, an de Programm vum Mérite mat afléisse loossen."])},
  "awardunits-benefits-list-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dir kënnt déi Jonk individuell ënnerstëtzen a fërderen an dëst duerch ee Programm, deen zënter Joren funktionéiert, international unerkannt ass an een noweisbar positiven Impakt huet."])},
  "awardunits-benefits-list-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dir erméiglecht et deene Jonken sech konstruktiv Ziler ze setzen, déi op eegenen Interessen a Fäegkeete baséieren, an hinnen hëllefen hir Fräizäit sënnvoll ze notzen."])},
  "awardunits-benefits-list-1-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dir ënnerstëtzt d’Entwécklung vun der perséinlecher a sozialer Responsabilitéit bei deene Jonken."])},
  "awardunits-benefits-list-1-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déi Tuteuren, déi sech fir déi Jonk engagéieren kënnen duerch de Programm hir organisatoresch a berufflech Kompetenzen weider ausbauen."])},
  "awardunits-benefits-list-1-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et entsteet een Netz vu Kontakter mat aneren Tuteuren an Organisatiounen."])},
  "awardunits-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wéi matmaachen?"])},
  "awardunits-how-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann Dir interesséiert sidd, eng Unitéit ze ginn, muss Dir verschidden Etappe realiséieren:"])},
  "awardunits-how-list-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei engem Treffe mat engem Mataarbechter aus dem Nationalbureau kritt dir Erklärungen zum Programm an wéi eng Optiounen et ginn fir et an Ärem Etablissement oder Organisatioun ze lancéieren"])},
  "awardunits-how-list-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op mannst zwou Persounen absolvéieren d'Formatioun Tuteur"])},
  "awardunits-how-list-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den Accord de Partenariat gëtt ënnerschriwwen"])},
  "awardunits-how-list-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elo sidd Dir eng Unitéit a kënnt de Programm vum Mérite Jeunesse offiziell ubidden"])},
  "awardunits-how-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et ass net méi wéi dat. Falls Dir interesséiert sidd, eng Unitéit ze ginn, zéckt net eis ze kontaktéieren op: "])},
  "supportus-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support us"])},
  "supportus-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dir wëllt de Mérite Jeunesse ënnerstëtzen? Dann hutt Dir Iech vläicht déi heite Froe scho gestallt!"])},
  "supportus-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support us"])},
  "supportus-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net alles, wat een am Liewe brauch, léiert een an der Schoul. Jonker brauchen och Erfarungen ausserhalb vum Klassesall fir responsabel, engagéiert an zefridde Matbierger ze ginn. "])},
  "supportus-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Mérite Jeunesse ass eng Fondatioun, déi Jonker bei der Realisatioun vun engem individuellen an ofwiesslungsräiche Programm ënnerstëtzt. Eist Zil ass et, all Jonkem zu Lëtzebuerg, d’Méiglechkeet ze ginn, beim Mérite Jeunesse matzemaachen. An dëst Zil areeche mir just mat Ärer Hëllef."])},
  "supportus-paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal wéi grouss oder kleng Ären Don ass, all Don bedeit een Ënnerscheed fir déi Jonk, déi am Programm aktiv sinn. Et ass mat Ärer Hëllef, wou déi Jonk kënnen op d’Erausfuerderunge vun der moderner Welt preparéiert ginn."])},
  "supportus-paragraph-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dir kënnt Ären Don iwwer Digicash oder iwwer Virement maachen:"])},
  "supportus-heading-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Daten"])},
  "supportus-label-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann d’Zomm vun all ären Donen iwwert d’Joer bei méi wéi 120 Euro läit, kann dësen Don vun de Steieren ofgesat ginn. Fir all Don kritt dir vun eis een Zertifikat ausgestallt."])},
  "supportus-label-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agreéiert a conventionnéiert vum"])},
  "supportus-label-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mat dem grousszügege Support vun"])},
  "faq-description-text-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please keep in mind that the Award is a marathon and not a sprint. In circumstances like these, some activities need to be postponed and other activities can be done from home."])},
  "faq-description-text-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bass Participant? Dann hues du dir vläicht déi heite Froe scho gestallt!"])},
  "faq-description-text-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Äert Kand wëll beim Mérite Jeunesse matmaachen? Dann hutt Dir Iech vläicht déi heite Froe scho gestallt!"])},
  "faq-description-text-4-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bass Tuteur/Tutrice? Dann hues du dir vläicht déi heite Froe scho gestallt!"])},
  "faq-description-text-5-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dir sidd interesséiert eng Unitéit ze ginn? Dann hutt Dir Iech vläicht déi heite Froe scho gestallt!"])},
  "faq-description-text-6-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dir wëllt de Mérite Jeunesse ënnerstëtzen? Dann hutt Dir Iech vläicht déi heite Froe scho gestallt!"])},
  "faq-section-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COVID 19"])},
  "faq-section-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant"])},
  "faq-section-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elteren"])},
  "faq-section-title-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuteuren"])},
  "faq-section-title-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unitéiten"])},
  "faq-section-title-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsors and Supporters"])},
  "faq-button-text-close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Froen verstoppen"])},
  "faq-button-text-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Froen uweisen"])},
  "faq-section-1-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How should I contact my Award Leader or Activity Coach while school and youth organizations are closed?"])},
  "faq-section-1-1-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may have an email address or contact number of your Award Leader or Activity Coach you can use to call or send a text. If not, please ask the National Office to put you in touch with your Award Leader."])},
  "faq-section-1-1-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You might also consider setting up a messaging group on social media with other Award participants, so you can share ideas, motivate each other, and stay in touch during this time."])},
  "faq-section-1-1-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please keep in mind that every physical contact should be avoided as far as possible."])},
  "faq-section-1-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I cannot continue my physical activity because the club has closed and/or my team’s training sessions are cancelled. How should I proceed to continue with my Physical section?"])},
  "faq-section-1-2-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please remember to continue your physical activity only if you are healthy."])},
  "faq-section-1-2-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your regular activity is not on now, there are many ways you can continue maintaining relevant strength and fitness. Whilst mass gatherings must be avoided, you could consider walking or running in your local area – or even doing a workout (or practicing your sport) in your garden. Apps like Strava or Google Fit can help you monitor your activities and provide evidence for your Assessor."])},
  "faq-section-1-2-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are also lots of fitness sessions and classes that can be followed online (YouTube) and done within your home; from Pilates to learning dance routines, boot camps to HIIT workouts. "])},
  "faq-section-1-2-description-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matter which option is most suitable for you, please talk to your Award Leader in advance, ask for his/her approval, and clarify how you will document your activity."])},
  "faq-section-1-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I continue with my Voluntary Service section?"])},
  "faq-section-1-3-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are many possibilities to continue your Voluntary Service section depending on your activity. You might ask your Award Leader or Activity Coach, if there are things you can do from home, like creating a social media campaign or preparing administrative paperwork."])},
  "faq-section-1-3-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this option is not suitable for your activity, you might consider changing your activity. During the pandemic, you can offer your help and, for example, offer grocery shopping to people at risk. This clearly counts as activity for your Voluntary Service section."])},
  "faq-section-1-3-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please speak to your Award Leader about these options. If there is no other possibility, you can pause your activity. Please keep in mind that a pause should be the very last option. Before taking a final decision, talk with your Award Leader about every possibility."])},
  "faq-section-1-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My neighbor is self-isolating; can I help do their shopping for my Voluntary Service section?"])},
  "faq-section-1-4-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you cannot continue your usual activity, this clearly is a great option and a sign of solidarity. "])},
  "faq-section-1-4-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this is an option for you now, please keep the sanitary rules and recommendations of the government in mind. Before changing your activity, please seek approval from your Award Leader."])},
  "faq-section-1-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I continue my Skills activity from home?"])},
  "faq-section-1-5-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your usual activity for this section is cancelled, you can consider what you can do from home for this section. If you are playing an instrument or singing, you might intensify your practicing at home. The most common activities for this section can easily be done from home. Ask your Award Leader or Activity Coach, for suitable activities and possible alternatives. You might also consider looking for online tutorials or online learning classes. If this is not an option for you, you can do some research about your project. "])},
  "faq-section-1-5-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before taking a final decision, please speak to your Award Leader or Activity Coach to seek their approval and to fix a suitable way of recording your progress."])},
  "faq-section-1-6-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I wanted to start my Skills section now. Which possibilities do I have?"])},
  "faq-section-1-6-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For this section, there are many possible activities, which can be done from home. Wouldn’t it be nice to learn how to cook or to bake? Or how to sew? If you are playing an instrument, you can practice at home. You can also consider learning a new language or learning how to draw/paint. You can also consider taking an online course on a certain subject."])},
  "faq-section-1-6-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A complete listing of every possible activity for the Skills section is available under this link: https://merite.jeunesse.lu/sites/default/files/pages/files/DofE-programme-ideas.pdf"])},
  "faq-section-1-6-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before starting your activity, please speak to your Award Leader and ask for approval."])},
  "faq-section-1-7-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Adventurous Journey is cancelled. What should I do now?"])},
  "faq-section-1-7-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you planned an Adventurous Journey for April, it has to be postponed. Most Adventurous Journeys will be organized later this year. The responsible Assessor will inform you as soon as possible."])},
  "faq-section-1-7-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are leaving your Award Unit, before you had the possibility to catch up on the Adventurous Journey, ask your Assessor, if you still can join the expedition. If this option is not suitable for you, you might consider taking part in the next national expedition. If not, please contact the National Office and together we will find a solution for your situation."])},
  "faq-section-1-8-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I registered for the national expedition. What should I do now?"])},
  "faq-section-1-8-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your registration is still valid, just as the registration for the preparation workshop planned for 28thMarch 2020. If the national expedition has to be postponed, we will inform you as soon as possible via email and the new date will be published on our website."])},
  "faq-section-1-8-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The preparation workshop will probably take place together with the practice expedition. More information will follow as soon as possible."])},
  "faq-section-1-9-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I had to cancel my Gold Residential Project. What should I do now?"])},
  "faq-section-1-9-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you had planned a Gold Residential Project for the upcoming weeks, it probably has been cancelled. Your planned project might be postponed to a later date and you can catch up on it as soon as the situation has calmed down. Try to prepare as much as possible from home. Please speak with your Award Leader or the person responsible of the project about your possibilities. "])},
  "faq-participants-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wou schreiwen ech mech an?"])},
  "faq-participants-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Tuteur vun denger Unitéit. Eng Lëscht vun den Unitéite fënns de hei. "])},
  "faq-participants-8-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ech wëll de Mérite maachen, mä meng Schoul oder Jugendorganisatioun ass keng Unitéit beim Mérite."])},
  "faq-participants-8-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falls deng Schoul oder Jugendorganisatioun net genannt ass, kanns de dech am Nationalbureau mellen. Si kënnen dir weiderhëllefen."])},
  "faq-participants-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss ech all Volet zur gläicher Zäit maachen?"])},
  "faq-participants-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee. Du kanns dat opdeelen an ee Volet nom anere maachen."])},
  "faq-participants-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wéi vill kascht de Mérite?"])},
  "faq-participants-3-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'Inscriptioun kascht pro Niveau 5 €. Wanns de als Direkt Einsteiger mam Sëlwer ufänke wëlls, kascht et 10 €. Wanns de als Direkt Einsteiger mam Gold ufänke wëlls, kascht et 15 €. Dës Montante si vum Nationalbüro festgeluecht a gëlle fir all Participant."])},
  "faq-participants-3-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allerdéngs verrechne vereenzelt Unitéite zousätzlech Käschte fir d'Betreiung vun de Participanten froen an aner Unitéiten iwwerhuelen d’Aschreiwungskäschte fir all hir Participanten. Aus deem Grond kann et sinn, dass verschidden Unitéiten, ënnerschiddlech Präisser froen."])},
  "faq-participants-3-3-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'Aschreiwungskäschte kënne vum Office Social iwwerholl ginn. Nieft den Aschreiwungskäschte kann et sinn, dass de eng Memberskaart fir däi Sport oder Talent muss bezuelen. Och d'Expeditioun ass deelweis mat Käschte verbonnen."])},
  "faq-participants-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meng Unitéit organiséiert keng Expeditioun. Wou schreiwen ech mech fir de Preparatiounsatelier an d'Nationalexpeditioun an?"])},
  "faq-participants-4-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am Ressources Centre fënns de déi entspriechend Umeldungsformulairen. Gëff déi w.e.g. bei dengem Tuteur of. Hien/ Si leed d'Inscriptioun un den Nationalbureau weider."])},
  "faq-participants-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ech sinn an de Scouten. Muss ech de Virbereedungsatelier fir d'Expeditioun trotzdeem maachen?"])},
  "faq-participants-5-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee. Aus Grënn vun Teambuilding géife mir awer recommandéieren, dass ee sech zesumme mat senger Ekipp op d'Expeditioun virbereet."])},
  "faq-participants-6-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss ech um Niveau Sëlwer a Gold och een Atelier an eng Prépa maachen, wann ech de Bronze an de Sëlwer gemaach hunn?"])},
  "faq-participants-6-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann s du d'Fortbeweegungsmethod net changéiers, muss de den Atelier net op all Niveau maachen. Falls de awer eng länger Paus zwëschen de verschiddene Niveaux hues, réit den Nationalbureau dozou, den Atelier opzefrëschen. Eng Prépa ass op all Niveau obligatoresch. Déi aktuell Covid Situatioun mécht do um Niveau Bronze a Sëlwer eng Ausnam iwwert ee limitéierten Zäitraum."])},
  "faq-participants-7-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dierf ech ee Projet Résidentiel zesumme mat menge Kolleege plangen?"])},
  "faq-participants-7-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Projet Résidentiel soll an enger friemer Géigend a mat frieme Leit stattfannen. Am beschte Fall kenns de keen, deen dobäi ass. Falls ee vun denge Frënn dee selwechte Projet maache wëll, ass dat ok. Allerdéngs musst dir  dann a verschiddene Gruppen agedeelt ginn."])},
  "faq-parents-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mäi Kand wëll de Mérite maachen. Wisou soll ech dat ënnerstëtzen?"])},
  "faq-parents-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Mérite fërdert déi individuell Entwécklung vun de Jonken. Et ass ee komplett individuelle Programm, dee sech de Jonken sou zesummestellt, wéi hien/hatt dat wëll. Bei verschiddene Voleten muss dee Jonke seng Komfortzone verloossen a léiert sech esou besser kennen a sammelt wichteg Erfarunge fir d'Liewen."])},
  "faq-parents-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wa mäi Kand sech beim Mérite umellt, huet hien/ hatt dann nach genuch Zäit fir d’Schoul? "])},
  "faq-parents-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann hien/ hatt jo dräi Aktivitéite gläichzäiteg mécht, ass eventuell net méi genuch Zäit fir d’Hausaufgaben an d’Prüfungen uerdentlech virzebereeden."])},
  "faq-parents-2-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Programm vum Mérite Jeunesse ass flexibel a léist sech un d ‘Besoine vun all Participant upassen. Äert Kand muss och net all Volet zur selwechter Zäit maachen, mä kann eng Aktivitéit no der anerer maachen. Wann een eng Kéier wierklech keng Zäit huet, kann een och eng Paus vun enger Woch maachen, fir sech op seng Schoul ze konzentréieren. Déi Woch muss een dann einfach méi spéit nohuelen."])},
  "faq-parents-2-3-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausserdeem ass et wichteg, dass déi Jonk een Ausgläich zur Schoul hunn. Duerch deen individuellen an awer gläichzäiteg ofwiesslungsräiche Programm, léieren d’Participanten aner Kopetenzen, déi hinnen am spéidere Liewen virunhëllefe kënnen. "])},
  "faq-parents-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie betreit mäi Kand?"])},
  "faq-parents-3-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Participant gëtt vun engem forméierten Tuteur encadréiert. Den Tuteur ass dacks een Enseignant oder een Éducateur aus dem SEPAS oder Jugendhaus. Fir de beschtméiglechsten Encadrement ze garantéieren, muss all Tuteur eng Formatioun bei eis maachen. Déi Persounen, déi d'Expeditioune betreien, mussen nach eng méi spezifesch Formatioun maachen."])},
  "faq-parents-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ass mäi Kand iwwert de Mérite assuréiert?"])},
  "faq-parents-4-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jo, soubal den ënnerschriwwenen Umeldungsziedel beim Tuteur ofginn ass, ass de Participant fir all seng Aktivitéite bei eis verséchert. Sinn dem Participant seng Aktivitéite mat engem extreme Risiko verbonnen, kënne mir dëst allerdéngs net assuréieren."])},
  "faq-parents-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann ee seng Aktivitéit wiesselen, wann een net zefridden mat der erausgesichter Aktivitéit ass?"])},
  "faq-parents-5-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et kann ëmmer nees virkommen, dass engem eng Aktivitéit net esou gefält ewéi geduecht an dat ass och an der Rei. Wann et wierklech net dat richtegt fir een ass, kann een d’Aktivitéit wiesselen nodeems ee Récksprooch mat sengem Tuteur geholl huet. An deem Fall muss een och net de ganze Volet vu vir ufänken. Allerdéngs soll dëst eng Ausnam sinn, well d’Zil ass et d’Ausdauer an d’Duerchzéie vu sengen Aktivitéiten ze fërderen."])},
  "faq-leaders-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wou muss ech mech melle fir Tuteur ze ginn?"])},
  "faq-leaders-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mir bidden op mannst 1 Mol am Joer eng Tuteursformatioun op Lëtzebuergesch an eng op Englesch un. Du muss dech iwwert den Nationalbureau umellen. Zousätzlech kanns de dech och nach iwwert den IFEN umellen."])},
  "faq-leaders-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss ech an enger Unitéit aktiv sinn, fir Tuteur ze ginn?"])},
  "faq-leaders-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee. Du kanns och gären als Freelance Tuteur aktiv sinn oder an denger Schoul/ Organisatioun nofroen, ob si eng Unitéit wëlle ginn."])},
  "faq-leaders-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ech si Scout a wëll d'Expeditiounen a menger Unitéit selwer organiséieren. Muss ech d'Zousazformatioun maachen?"])},
  "faq-leaders-3-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee. Du kanns dech gären an eiser Formatioun umellen, fir ee besseren Abléck an den Oflaf vun enger Mérite Expeditioun ze kréien, mä et ass net obligatoresch. Informéier den Nationalbureau iwwert deng Scoutserfarung, iers de ufänks Expeditiounen ze organiséieren."])},
  "faq-leaders-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wisou mussen d'Umeldunge fir den Preparatiounsatelier a fir d'Nationalexpeditioun iwwert den Tuteur lafen?"])},
  "faq-leaders-4-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann d'Umeldungen iwwert den Tuteur lafen, weess dësen automatesch Bescheed, dass seng Participante beim Atelier an/ oder der Nationalexpeditioun ugemellt sinn."])},
  "faq-leaders-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wou fannen ech déi néideg Dokumenter fir d'Umeldungen an d'Deklaratiounen?"])},
  "faq-leaders-5-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Dokumenter sinn am Ressources Centre disponibel."])},
  "faq-units-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat sinn d'Virdeeler fir meng Schoul oder Organisatioun, wa mir de Mérite ubidden?"])},
  "faq-units-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Mérite huet Virdeeler fir déi jonk Participanten an och fir d'Tuteuren. "])},
  "faq-units-1-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déi Jonk léiere sech wärend dem Programm besser kennen a kréien duerch de Certficat eng speziell Mise en valeur."])},
  "faq-units-1-3-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'Tuteure betreien d'Participanten an iwwerhuelen doduerch d’Roll vun engem Mentor. Fir déi meescht engagéiert Mataarbechter oder Erwuessen ass dat eng immens spannend Erfarung, well se déi Jonk och dacks aneschters wouerhuelen, wéi am normalen Alldag."])},
  "faq-units-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U wie muss ech mech wenden fir eng Unitéit ze ginn?"])},
  "faq-units-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanns du interesséiert bass eng Unitéit ze ginn, mell dech op info(at)merite.lu. Déi Persoun, déi fir däin Typ vun Organisatioun zoustänneg ass, mellt sech esou séier wéi méiglech fir déi eenzel Demarchen ze erklären."])},
  "faq-units-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kascht et eis eppes eng Unitéit ze ginn?"])},
  "faq-units-3-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee, et fale keng Käschten u fir eng Unitéit ze ginn. Et ass awer virzegesinn, dass verschidden Aktivitéiten, wéi zum Beispill d'Tuteursformatiounen an d'Expeditiounen, mat Käschte verbonne sinn. Och ginn et Unitéiten déi d’Aschreiwungsfraisen vun den Jonken, déi jee no Niveau bei 5-15€ pro Jugendlechen leien, iwwerhuelen."])},
  "faq-sponsors-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wisou soll ech de Mérite ënnerstëtzen?"])},
  "faq-sponsors-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Mérite ass ee Programm bei deem déi Jonk sech hir eegen Ziler setze kënnen, hir Komfortzone verloossen an iwwert sech selwer erauswuessen. Et ass eng charakterbildend Experienz. Fir deene Jonken dee beschtméiglechen Encadrement kënnen ze bidden, si mir op Är Donen ugewisen."])},
  "faq-sponsors-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat geschitt mat mengem Don?"])},
  "faq-sponsors-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann dir iech fir een Don decidéiert, kritt dir natierlech matgedeelt firwat är Sue genotzt ginn. "])},
  "faq-sponsors-2-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mat den Done finanzéiere mir nëmmen  Aktivitéiten wéi d'Nationalexpeditiounen, d'Betreiung vun den Expeditiounen op Gold Niveau an d'Organisatioun vun der Zeremonie Remise du Mérite."])},
  "merite-description-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Mérite Jeunesse Luxembourg gehéiert zur weltwäit féierender Organisatioun The Duke of Edinburgh’s International Award, déi an iwwer 130 Länner aktiv ass."])},
  "merite-description-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Mérite Jeunesse ass oppe fir all Jonken am Alter vun 14-24 Joer, egal wéi säi Geschlecht, kulturellen Hannergrond, reliéis oder politesch Iwwerzeegung oder physesch Viraussetzung ass. Et ass ee flexibelen an ofwiesslungsräiche Programm, bei deem ee sech perséinlech Ziler setzt an déi ouni Zäitlimitt erreeche kann."])},
  "merite-description-paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fir matzemaachen muss du a 4 verschiddene Beräicher aktiv ginn: ee Benevolat maachen, ee Sport ausüben, een Talent léieren an eng Expeditioun maachen. Du sichs dir deng Aktivitéite selwer eraus a stells dir esou ee Programm zesummen deen ausgeglach ass an deen dir Spaass mécht. "])},
  "merite-description-paragraph-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et muss een net alles zur gläicher Zäit maachen. De Programm soll et dir erméigleche méi Selbstvertrauen ze kréien, Responsabilitéit ze iwwerhuelen, Neies ze entdecken an un deng Fäegkeeten an Talenter ze gleewen."])},
  "merite-description-paragraph-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lies méi iwwert den International Award: www.intaward.org"])},
  "team-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "team-sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beräicher"])},
  "team-job-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrativ Assistentin"])},
  "team-job-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secrétaire générale"])},
  "team-job-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responabel fir Kommunikatioun an National Aktivitéiten"])},
  "team-job-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert Expéditions (Freelance)"])},
  "team-text-astrid-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestioun vun de Formatiounen, den Expeditioune an de Gold Projeten"])},
  "team-text-astrid-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support fir d’Online Record Book"])},
  "team-text-astrid-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ënnerstëtzung beim Eventmanagement"])},
  "team-text-astrid-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabel fir d’Comptabilitéit an d’Statistik"])},
  "team-text-astrid-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabel fir d’Qualitéit an d’Entwécklung"])},
  "team-text-christine-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatioune mam CA a mam MENJE"])},
  "team-text-christine-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatioune mat den internationalen a privaten Unitéiten"])},
  "team-text-christine-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatioune mam IAF an de Sponsoren"])},
  "team-text-christine-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabel fir déi englesch Formatiounen"])},
  "team-text-christine-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabel fir d’Comptabilitéit an d’Statistik"])},
  "team-text-christine-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabel fir d’Qualitéit an d’Entwécklung"])},
  "team-text-christine-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestioun vun de Gold Projeten"])},
  "team-text-christine-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventmanagement"])},
  "team-text-jil-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatioune mat den ëffentlechen Unitéiten (ëffentlech Schoulen, Jugendhäiser an Internater)"])},
  "team-text-jil-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabel fir déi lëtzebuergesch Formatioun"])},
  "team-text-jil-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestioun vun den Expeditiounen"])},
  "team-text-jil-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabel vun der Kommunikatioun"])},
  "team-text-jil-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventmanagement & Workshops"])},
  "team-text-ben-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D’Sécherheetsaschätzung vun den Expeditiounen an den Unitéiten"])},
  "team-text-ben-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expeditiounen um Niveau Gold"])},
  "team-text-ben-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ënnerstëtzung bei der Preparatioun"])},
  "team-text-ben-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisioun vun der Expeditioun"])},
  "team-text-ben-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ënnerstëtzung vun den Tuteure bei der Ëmsetzung vun den Expeditiounen"])},
  "merite-conseil-text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ee Verwaltungsrot, mat S.A.R. dem Prënz Guillaume als President, leet d’Fondatioun Mérite Jeunesse."])},
  "merite-conseil-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membere vum Verwaltungsrot an hir Funktioun"])},
  "team-timing-astrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méindes bis Donneschdes (nëmme moies)"])},
  "team-timing-christine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dënschdes bis Freides"])},
  "team-timing-jil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méindes bis Freides"])},
  "team-conseil-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S.A.R. Prënz Guillaume"])},
  "team-conseil-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["President"])},
  "team-conseil-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vice President"])},
  "team-conseil-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trésorier"])},
  "team-conseil-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secrétaire"])},
  "team-conseil-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evenementer"])},
  "team-conseil-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommunikatioun a Marketing"])},
  "team-conseil-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalgestioun"])},
  "team-conseil-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoring"])},
  "team-conseil-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualitéitsgestioun"])},
  "team-conseil-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressources humaines"])},
  "team-conseil-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projets spéciaux"])},
  "team-conseil-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expeditiounen a Formatiounen"])},
  "form-orb-selectlang-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sproochpräferenz"])},
  "form-orb-selectlang-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englesch"])},
  "form-orb-selectlang-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lëtzebuergesch"])},
  "form-orb-selecttime-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seance"])},
  "form-orb-selecttime-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seance 1: 15:30-16:30 op Lëtzebuergesch"])},
  "form-orb-selecttime-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seance 2: 17:00-18:00 op Englesch"])},
  "form-exp-unitlist-none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit ass net an der Lescht"])},
  "form-exp-food-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mëttegiessen um Samschdeg"])},
  "form-exp-food-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(D'Fraisen fir d'Mëttegiesse sinn an deene Fraise vun 50€ mat dran)"])},
  "form-exp-food-2-a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(D’Fraisen fir d’Mëttegiesse musse selwer bezuelt ginn)"])},
  "form-exp-food-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jo"])},
  "form-exp-food-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
  "form-exp-food-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidd Dir Vegetarier/ Veganer?"])},
  "form-exp-food-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
  "form-exp-food-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegetarier"])},
  "form-exp-food-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veganer"])},
  "form-exp-food-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hutt Dir Liewensmëttelallergien?"])},
  "form-disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wann Dir Iech fir dëst Evenement umellt, sidd Dir automatesch averstanen, dass Fotoe an/ oder Videoe vun Iech gemaach a vun der Fondatioun Mérite Jeunesse publizéiert ginn. Dës Fotoen an/ oder Videoe kënne fir Publikatiounen oder fir d’Promotioun vum Programm op de soziale Medie benotzt ginn, grad ewéi fir geprinte Publikatiounen, vum Mérite Jeunesse op eng onbestëmmten Zäit genotzt ginn."])},
  "form-template-unitnotlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ass är Unitéit net an der Lëscht?"])}
}