<template>
  <BlockVolet :title="schema.title" :color="pbvoletcolor" :icon="generateLink(schema.image.url)">
    <div v-html="generateText()"></div>
  </BlockVolet>
</template>

<script>
import BlockVolet from "@/components/blocks/BlockVolet.vue";
import { marked } from 'marked';

export default {
  name: "pb-participantsvolet",
  components: {
    BlockVolet,
  },
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      pbvoletcolor: "red",
    };
  },
  mounted() {
    this.generateColor();
  },
  updated() {
    this.generateColor();
    this.generateText();
  },
  methods: {
    generateLink(link) {
      console.log("generate link");
      if (link.startsWith("/")) {
        return process.env.VUE_APP_MERITECMS_URL + link;
      } else {
        return link;
      }
    },
    generateText() {
      return marked(this.schema.text);
    },
    generateColor() {
      if (this.schema.color == "benevolat") {
        this.pbvoletcolor = "var(--charte-color-benevolat)";
      } else if (this.schema.color == "talent") {
        this.pbvoletcolor = "var(--charte-color-talent)";
      } else if (this.schema.color == "sport") {
        this.pbvoletcolor = "var(--charte-color-sport)";
      } else if (this.schema.color == "residentiel") {
        this.pbvoletcolor = "var(--charte-color-residentiel)";
      } else if (this.schema.color == "expedition") {
        this.pbvoletcolor = "var(--charte-color-expedition)";
      } else {
        this.pbvoletcolor = "var(--charte-color-talent)";
      }
    },
  },
};
</script>

<style></style>
