<template>
  <div v-for="(schema, index) in typeSchema" :key="index">
    <component :key="index" :is="schema.type" :schema="schema"></component>
  </div>
</template>

<script>
import pbslider from "@/components/pagebuilder/pb-slider.vue";
import pbheading from "@/components/pagebuilder/pb-heading.vue";
import pbsitetext from "@/components/pagebuilder/pb-sitetext.vue";
import pbagenda from "@/components/pagebuilder/pb-agenda.vue";
import pbcontactform from "@/components/pagebuilder/pb-contactform.vue";
import pbblocktextimage from "@/components/pagebuilder/pb-blocktextimage.vue";
import pbblocktext from "@/components/pagebuilder/pb-blocktext.vue";
import pbblockimagetextoffset from "@/components/pagebuilder/pb-blockimagetextoffset.vue";
import pbtextdual from "@/components/pagebuilder/pb-textdual.vue";
import pbresources from "@/components/pagebuilder/pb-resources.vue";
import pbunits from "@/components/pagebuilder/pb-units.vue";
import pbcards from "@/components/pagebuilder/pb-cards.vue";
import pbspacer from "@/components/pagebuilder/pb-spacer.vue";
import pbgallery from "@/components/pagebuilder/pb-gallery.vue";
import pbmeritezuelen from "@/components/pagebuilder/pb-meritezuelen.vue";
import pbmeriteteam from "@/components/pagebuilder/pb-meriteteam.vue";
import pbmeriteconseil from "@/components/pagebuilder/pb-meriteconseil.vue";
import pbdonors from "@/components/pagebuilder/pb-donors.vue";
import pbleadertextblock from "@/components/pagebuilder/pb-leadertextblock.vue";
import pbdonate from "@/components/pagebuilder/pb-donate.vue";
import pbform from "@/components/pagebuilder/pb-formparticipants.vue";
import pbformtutornew from "@/components/pagebuilder/pb-formtutors.vue";
import pbformtraining from "@/components/pagebuilder/pb-formtraining.vue";
import pbformrentree from "@/components/pagebuilder/pb-form-rentree.vue";
import pbformtemplate from "@/components/pagebuilder/pb-formtemplate.vue";
import pbformsponsors from "@/components/pagebuilder/pb-formsponsors.vue";
import pbparticipantstextblock from "@/components/pagebuilder/pb-participantstextblock.vue";
import pbparticipantsvolet from "@/components/pagebuilder/pb-participantsvolet.vue";
import pbparticipantstext from "@/components/pagebuilder/pb-participantstext.vue";
import pbnews from "@/components/pagebuilder/pb-news.vue";
import pbcalendar from "@/components/pagebuilder/pb-calendar.vue";
import pbfaq from "@/components/pagebuilder/pb-faq.vue";
import pblinks from "@/components/pagebuilder/pb-links.vue";
import pbfooter from "@/components/pagebuilder/pb-footer.vue";
import pbalumni from "@/components/pagebuilder/pb-alumni.vue";
import pbalumniregister from "@/components/pagebuilder/pb-alumniregister.vue";
import pbimagelink from "@/components/pagebuilder/pb-imagelink.vue";

export default {
  name: "pb",
  components: {
    pbslider,
    pbheading,
    pbsitetext,
    pbagenda,
    pbcards,
    pbcontactform,
    pbresources,
    pbblocktextimage,
    pbblocktext,
    pbblockimagetextoffset,
    pbtextdual,
    pbunits,
    pbmeritezuelen,
    pbmeriteteam,
    pbmeriteconseil,
    pbdonors,
    pbdonate,
    pbleadertextblock,
    pbparticipantstextblock,
    pbparticipantsvolet,
    pbparticipantstext,
    pbspacer,
    pbnews,
    pbcalendar,
    pbfaq,
    pblinks,
    pbfooter,
    pbform,
    pbformtutornew,
    pbformsponsors,
    pbformrentree,
    pbgallery,
    pbformtraining,
    pbformtemplate,
    pbalumni,
    pbalumniregister,
    pbimagelink,
  },
  props: {
    typeSchema: {
      type: Array,
    },
  },
};
</script>

<style></style>
