<template>
  <div class="merite-blockimagetext-offset-container">
    <div class="merite-blockimagetext-offset-inner">
      <div class="merite-blockimagetext-offset-flex" :style="backgroundImage"></div>
      <div class="merite-blockimagetext-offset-flex"></div>
    </div>
    <div class="merite-blockimagetext-grey-container-overflow">
      <div class="merite-blockimagetext-grey-container-flex-overflow"></div>
      <div class="merite-blockimagetext-grey-container-flex-overflow">
        <div class="merite-blockimagetext-grey-container-flex-overflow-inner">
          <SiteHeading :title="title" :color="color" />
          <slot name="description"></slot>
        </div>
      </div>
    </div>
    <div class="merite-blockimagetext-grey-container">
      <slot name="main"></slot>
    </div>
  </div>
</template>

<script>
import SiteHeading from "@/components/widgets/SiteHeading.vue";

export default {
  name: "BlockImageTextOffset",
  props: {
    title: {
      type: String,
      default: "undefined",
    },
    color: {
      type: String,
      default: "#FF0000",
    },
    background: {
      type: String,
      default: "undefined",
    },
  },
  components: {
    SiteHeading,
  },
  computed: {
    backgroundImage() {
      return {
        background: "url(" + this.background + ")",
        "background-position": "right",
        "background-size": "cover",
        "background-position": "right",
      };
    },
  },
};
</script>

<style>
.merite-blockimagetext-offset-container {
  margin: 20vh 0 0 0;
}

.merite-blockimagetext-offset-inner {
  display: flex;
  height: 40vh;
}

.merite-blockimagetext-offset-flex {
  flex: 1;
}

.merite-blockimagetext-offset-flex:nth-child(1) {
  margin: -20vh 0 20vh 0;
  z-index: 100;
}

.merite-blockimagetext-offset-flex:nth-child(2) {
  flex-grow: 2;
}

.merite-blockimagetext-grey-container {
  margin: 0 0 0 15vw;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 5em;
  padding-top: 4em;

  color: var(--charte-color-grey-2);
  background-color: var(--charte-color-grey-1);

  display: flex;
}

.merite-blockimagetext-grey-container-overflow {
  display: flex;
  margin: -40vh 0 0 15vw;

  min-height: 20vh;
}
.merite-blockimagetext-grey-container-flex-overflow:nth-child(2) {
  padding-left: 4vw;
  flex-grow: 2;
}

.merite-blockimagetext-grey-container-flex-overflow {
  flex: 1;

  background-color: var(--charte-color-grey-1);

  font-smooth: 1em;
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  color: var(--charte-color-grey-2);
}

.merite-blockimagetext-grey-container-flex-overflow .merite-siteheading-container {
  margin: 0 0 4em -15vw;
  transform: translateY(-150%);
}

.merite-blockimagetext-grey-container-flex-overflow-inner {
  padding: 0 20vw 0 0;
}

@media only screen and (max-width: 600px) {
  .merite-blockimagetext-offset-flex {
    flex-direction: column !important;
    min-height: 40vh;
  }

  .merite-blockimagetext-offset-inner {
    flex-direction: column !important;
  }

  .merite-blockimagetext-grey-container-overflow {
    margin-top: 0 !important;
  }

  .merite-blockimagetext-grey-container-overflow {
    margin: 0;
  }

  .merite-blockimagetext-grey-container {
    margin: 0;
  }

  .merite-blockimagetext-grey-container-flex {
    flex-direction: column !important;
  }
  .merite-siteheading-container {
    margin-left: 0 !important;
  }

  .merite-blockimagetext-grey-container-flex-overflow {
    padding: 0 !important;
  }

  .merite-blockimagetext-grey-container-flex-overflow:nth-child(1) {
    background-color: red !important;
    flex: 0;
  }

  .merite-blockimagetext-grey-container-flex-overflow-inner {
    padding-right: 0;
  }
}
</style>