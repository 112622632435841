<template>
  <div class="merite-siteheading-container">
    <div
      :id="uid"
      class="merite-siteheading-title-inner animate__animated"
      :style="fontColor"
      v-html="title"
    ></div>
    <div class="merite-siteheading-title-line" :style="lineColor"></div>
  </div>
</template>

<script>
import $ from "jquery";
import AnimationScroller from "@/lib/AnimationScroller";

let uuid = 0;

export default {
  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
  name: "SiteHeadingInner",
  mixins: [AnimationScroller],
  props: {
    title: {
      type: String,
      default: "Undefined",
    },
    color: {
      type: String,
      default: "#FF0000",
    },
    animation: {
      type: String,
      default: "none",
    },
  },
  data() {
    return {
      uid: "merite-siteheading-" + this.uuid,
    };
  },
  computed: {
    fontColor() {
      return {
        color: this.color,
      };
    },
    lineColor() {
      return {
        "background-color": this.color,
      };
    },
  },
  mounted() {
    let element = $("#merite-siteheading-" + this.uuid);
    this.viewportAnimationScroller(element, this.animation);
  },
};
</script>

<style>
.merite-siteheading-container {
  width: intrinsic;
  width: -moz-max-content;
  width: -webkit-max-content;

  margin: 8em 0 4em 0;
}

.merite-siteheading-title-inner {
  margin: 0 0 0 10vw;
  font-size: 2em;

  font-family: "Meta Pro";
  font-weight: 500;

  text-transform: uppercase;
}

.merite-siteheading-title-line {
  width: 100%;
  height: 10px;
}
</style>