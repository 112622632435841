<template>
  <BlockTextImage :title="schema.title" color="var(--charte-color-sport)" :background="generateLink(schema.image.formats.medium.url)">
    {{ schema.text }}
  </BlockTextImage>

  <SiteHeadingExpandableFAQ
    :titleOpen="$t('faq-button-text-open')"
    :titleClose="$t('faq-button-text-close')"
    color="var(--charte-color-sport)"
  >
    <BlockVoletClean :title="faq.question" color="var(--charte-color-sport)" v-for="(faq, index) in schema.faqsection" :key="index">
      <div v-html="faq.answer"></div>
    </BlockVoletClean>
  </SiteHeadingExpandableFAQ>
</template>

<script>
import BlockTextImage from "@/components/blocks/BlockTextImage.vue";
import SiteHeadingExpandableFAQ from "@/components/widgets/SiteHeadingExpandableFAQ.vue";
import BlockVoletClean from "@/components/blocks/BlockVoletClean.vue";

export default {
  name: "pb-faq",
  components: {
    BlockTextImage,
    SiteHeadingExpandableFAQ,
    BlockVoletClean,
  },
  props: {
    schema: {
      type: Object,
    },
  },
  created() {},
  methods: {
    generateLink(link) {
      console.log("generate link");
      if (link.startsWith("/")) {
        return process.env.VUE_APP_MERITECMS_URL + link;
      } else {
        return link;
      }
    },
  },
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .merite-siteheading-expandable-faq-flex {
    margin-top: 2em;
  }
}
</style>
