<template>
  <div class="pb-meritezuelen">
    <SiteHeading :title="$t('merite-in-numbers')" color="var(--charte-color-talent)" />
    <TextTileContainer>
      <TextTile
        :primaryText="$t('merite-numers-active-participants-value')"
        :secondaryText="$t('merite-numers-active-participants-field')"
        imageLocation="../assets/tiles/image-24.jpg"
      />
      <TextTile
        :primaryText="$t('merite-numers-active-tutors-value')"
        :secondaryText="$t('merite-numers-active-tutors-field')"
        imageLocation="../assets/tiles/image-44.jpg"
      />
    </TextTileContainer>
    <TextTileContainer>
      <TextTile
        :primaryText="$t('merite-numers-units-value')"
        :secondaryText="$t('merite-numers-units-field')"
        imageLocation="../assets/tiles/image-20.jpg"
      />
      <TextTile
        :primaryText="$t('merite-numers-award-years-value')"
        :secondaryText="$t('merite-numers-award-years-field')"
        imageLocation="../assets/tiles/image-11.jpg"
      />
    </TextTileContainer>
  </div>
</template>

<script>
import SiteHeading from "@/components/widgets/SiteHeading.vue";

import TextTileContainer from "@/components/widgets/TextTileContainer.vue";
import TextTile from "@/components/widgets/TextTile.vue";

export default {
  name: "pb-meritezuelen",
  components: {
    SiteHeading,
    TextTile,
    TextTileContainer,
  },
};
</script>

<style>
</style>