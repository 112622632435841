<template>
  <div class="merite-blockvolet">
    <div class="merite-blockvolet-inner" :style="border" @click="descriptionOpen = !descriptionOpen">
      <div class="merite-blockvolet-inner-icon" :style="iconImage"></div>
      <h2 class="merite-blockvolet-inner-title">{{ title }}</h2>

      <img src="/assets/icons/chevron_down.svg" class="merite-blockvolet-inner-chevron" :class="{ chevronRotate: descriptionOpen }" />
    </div>

    <div class="merite-blockvolet-content" :style="borderFull" :class="{ voletActive: descriptionOpen }">
      <slot v-if="descriptionOpen"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockVolet",
  props: {
    title: {
      type: String,
      default: "#ff0000",
    },
    color: {
      type: String,
      default: "#ff0000",
    },
    icon: {
      type: String,
      default: "undefined",
    },
  },
  data() {
    return {
      descriptionOpen: false,
      show: true,
    };
  },
  computed: {
    iconImage() {
      return {
        background: "url(" + this.icon + ")",
        "background-position": "center",
        "background-size": "contain",
        "background-repeat": "no-repeat",
      };
    },
    border() {
      return {
        "border-left": "2px solid " + this.color,
      };
    },
    borderFull() {
      return {
        "border-left": "2px solid " + this.color,
        "border-bottom": "2px solid " + this.color,
      };
    },
  },
};
</script>

<style>
.merite-blockvolet {
  margin: 2em 15vw 2em 15vw;
  overflow: hidden;
}

.merite-blockvolet-inner:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.merite-blockvolet-inner-icon {
  margin: 0 0 0 2em;
  width: 100px;
  height: 3.5em;
}

.merite-blockvolet-inner-title {
  padding: 0;
  margin: 0 0 0 1em;

  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 16pt;

  color: var(--charte-color-grey-2);
}

.merite-blockvolet-inner {
  padding: 0.5em 0 0.5em 0;

  position: relative;
  display: flex;
  align-items: center;

  background-color: var(--charte-color-grey-1);

  transition: background-color 200ms;

  z-index: 100;
}

.merite-blockvolet-content:not(.voletActive) {
  border: 2px solid transparent !important;
}

.merite-blockvolet-content {
  margin: 0;

  padding: 0 10em 0 10em;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;

  overflow-y: visible;

  transition: all 200ms;

  white-space: pre-line;
}

.voletActive {
  padding: 3em 10em 3em 10em;
}

.descriptionOpen {
  background-color: red;
}

.merite-blockvolet-content h3 {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 14pt;
  margin: 1em 0 0 0;
}

.merite-blockvolet-content p {
  margin: 0;
}

.merite-blockvolet-content ul {
  margin: -1em 0 0 0;
}

.merite-blockvolet-inner-chevron {
  width: 2em;

  position: absolute;
  right: 0;

  margin-right: 2em;
  transition: transform 400ms;
}

.chevronRotate {
  transform: rotateZ(180deg);
}

@media only screen and (max-width: 600px) {
  .merite-blockvolet {
    margin: 2em 5vw 2em 5vw;
  }

  .merite-blockvolet-inner-icon {
    margin: 0;
  }

  .merite-blockvolet-inner-title {
    font-size: 12pt;
  }

  .merite-blockvolet-content {
    padding: 0 2em 0 2em;
  }

  .voletActive {
    padding: 3em 2em 3em 2em;
  }

  .merite-blockvolet-content h3 {
    margin-bottom: 0;
  }
}
</style>