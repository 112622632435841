<template>
  <div class="merite-gradient-container" :style="bgColor">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "GradientContainer",
  props: {
    primaryColor: {
      type: String,
      default: "#FF0000",
    },
    secondaryColor: {
      type: String,
      default: "#00FF00",
    },
    angle: {
      type: String,
      default: "0",
    },
  },
  computed: {
    bgColor() {
      return {
        background:
          "linear-gradient(" +
          this.angle +
          "deg, " +
          this.secondaryColor +
          ", " +
          this.primaryColor +
          ")",
      };
    },
  },
};
</script>

<style>
.merite-gradient-container {
  width: 100%;
}
</style>