<template>
  <BlockImageTextOffset :title="schema.title" color="var(--charte-color-residentiel)"
    :background="generateLink(schema.image[0].formats.medium.url)">
    <template v-slot:description>
      <div v-html="generateText1()"></div>

      <div class="merite-supportus-box-container">
        <div class="merite-supportus-box-flex">
          <h2 class="merite-supportus-heading">{{ $t("supportus-heading-1") }}</h2>
          <p class="merite-supportus-paragraph">
            Mérite Jeunesse Luxembourg<br />
            IBAN LU59 1111 2137 4756 0000<br />
            BIC: CCPLLULL
          </p>
        </div>
        <div class="merite-supportus-box-flex">
          <h2 class="merite-supportus-heading">Payconiq</h2>
          <a href="https://payconiq.com/T/1/61BB1E71DFB2940006F7B793?D=Thank%20you%20for%20your%20donation">
            <img src="https://portal.payconiq.com/qrcode?f=svg&c=https://payconiq.com/T/1/61BB1E71DFB2940006F7B793"
              class="merite-supportus-qrcode" />

            <!--
            https://portal.payconiq.com/qrcode?f=svg&c=https://payconiq.com/T/1/61BB1E71DFB2940006F7B793
            https://payconiq.com/T/1/61BB1E71DFB2940006F7B793?D=Thank%20you%20for%20your%20donation
            -->
          </a>
        </div>
      </div>
      <div class="merite-supportus-paragraph" v-html="generateText2()"></div>
    </template>
  </BlockImageTextOffset>
</template>

<script>
import BlockImageTextOffset from "@/components/blocks/BlockImageTextOffset.vue";
import { marked } from 'marked';

export default {
  name: "pb-donate",
  components: { BlockImageTextOffset },
  props: {
    schema: {
      type: Object,
    },
  },
  updated() {
    this.generateText1();
    this.generateText2();
  },
  methods: {
    generateLink(link) {
      console.log("generate link");
      if (link.startsWith("/")) {
        return process.env.VUE_APP_MERITECMS_URL + link;
      } else {
        return link;
      }
    },
    generateText1() {
      return marked(this.schema.text1);
    },
    generateText2() {
      return marked(this.schema.text2);
    },
  },
};
</script>

<style>
.merite-supportus-box-container {
  display: flex;

  margin: 4em 0 0 0;
}

.merite-supportus-box-flex {
  flex: 1;

  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.merite-supportus-box-flex {
  padding: 2em 0 0 2em;
}

.merite-supportus-box-flex:nth-child(1) {
  margin: 0 0.5em 0 0;

  border-top: 5px solid #a7a9ac;
}

.merite-supportus-box-flex:nth-child(2) {
  margin: 0 0 0 0.5em;

  border-top: 5px solid #6b1f7c;
}

.merite-supportus-box-flex:nth-child(1) .merite-supportus-heading {
  color: #a7a9ac;
}

.merite-supportus-box-flex:nth-child(2) .merite-supportus-heading {
  color: #6b1f7c;
}

.merite-supportus-paragraph {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;

  padding-bottom: 2em;
  padding-top: 4em;
}

.merite-supportus-qrcode {
  width: 80%;
  padding-bottom: 4em;
}

@media only screen and (max-width: 600px) {
  .merite-supportus-box-container {
    flex-direction: column;
  }

  .merite-supportus-box-flex:nth-child(1) {
    margin: 0 0 0 0;

    border-top: 5px solid #a7a9ac;
  }

  .merite-supportus-box-flex:nth-child(2) {
    margin: 2em 0 0 0;

    border-top: 5px solid #6b1f7c;
  }
}
</style>
