<template>
  <div class="merite-meritejeunesse-spacer"></div>
</template>

<script>
export default {
  name: "pb-spacer",
};
</script>

<style>
.merite-meritejeunesse-spacer {
  height: 8em;
}
</style>