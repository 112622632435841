<template>
  <div class="pb-slider">
    <SiteSliderDynamic
      :imageLocation="generateLink(schema.background[0].formats.large.url)"
      :imageOverlayColor="pbslidercolor"
      :buttonColor="pbslidercolor"
    >
      <template v-slot:title>
        <h1 class="merite-pbslider-heading animate__animated">{{ schema.header }}</h1>
        <p class="merite-pbslider-paragraph animate__animated" v-if="schema.subheading != ''">
          {{ schema.subheading }}
        </p>
      </template>
      <template v-slot:subtitle v-if="schema.actiontext != null">
        <router-link :to="{ name: schema.actionlink }"
          ><button class="merite-pbslider-engage-button" :style="engagestyle">
            {{ schema.actiontext }}
          </button></router-link
        >
      </template>
    </SiteSliderDynamic>
  </div>
</template>

<script>
import SiteSliderDynamic from "@/components/widgets/SiteSliderDynamic.vue";
export default {
  name: "pb-slider",
  components: {
    SiteSliderDynamic,
  },
  data() {
    return {
      pbslidercolor: "red",
    };
  },
  props: {
    schema: {
      type: Object,
    },
  },
  mounted() {
    this.generateColor();
  },
  updated() {
    this.generateColor();
  },
  methods: {
    generateLink(link) {
      console.log("generate link");
      if (link.startsWith("/")) {
        return process.env.VUE_APP_MERITECMS_URL + link;
      } else {
        return link;
      }
    },
    generateColor() {
      if (this.schema.color == "benevolat") {
        this.pbslidercolor = "var(--charte-color-benevolat)";
      } else if (this.schema.color == "talent") {
        this.pbslidercolor = "var(--charte-color-talent)";
      } else if (this.schema.color == "sport") {
        this.pbslidercolor = "var(--charte-color-sport)";
      } else if (this.schema.color == "residentiel") {
        this.pbslidercolor = "var(--charte-color-residentiel)";
      } else if (this.schema.color == "expedition") {
        this.pbslidercolor = "var(--charte-color-expedition)";
      } else {
        this.pbslidercolor = "var(--charte-color-talent)";
      }

      this.schema.header = this.schema.header.replace(/\\n/g, "\n");
      if (this.schema.subheading) {
        this.schema.subheading = this.schema.subheading.replace(/\\n/g, "\n");
      }
    },
  },
  computed: {
    engagestyle() {
      return {
        color: this.pbslidercolor,
      };
    },
  },
};
</script>

<style>
.merite-pbslider-heading {
  color: white;

  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 4em;

  text-transform: uppercase;

  margin: 0;
  padding: 0;

  position: relative;
  z-index: 10;

  white-space: pre-line;
}

.merite-pbslider-paragraph {
  color: white;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;

  margin: 0 0 1.5em 0;
  padding: 0;

  animation-delay: 200ms;
  white-space: pre-line;
}

.merite-pbslider-engage-button {
  margin: 2em 0 0 0;
  padding: 0.75em 2em 0.75em 2em;
  background-color: white;

  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 18pt;

  border: none;

  text-transform: uppercase;
}

.merite-pbslider-engage-button:hover {
  cursor: pointer;
}

/* Responsive design */
@media only screen and (max-width: 600px) {
  .merite-pbslider-heading {
    font-size: 2.5em;
  }
}
</style>
