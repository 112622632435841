<template>
  <div class="pb-agenda">
    <div class="merite-greycontainer">
      <div class="merite-greycontainer-flex-container">
        <div class="merite-greycontainer-flex single">
          <img :src="generateLink(schema.image[0].formats.large.url)" class="merite-greycontainer-photo" />
          <div class="merite-grey-container-subtitle">{{ schema.caption }}</div>
        </div>
        <div class="merite-greycontainer-flex double">
          <SiteHeadingInner :title="schema.title" color="var(--charte-color-expedition)" />

          <SiteTextInner>
            {{ schema.text }}
          </SiteTextInner>
        </div>
      </div>
    </div>
    <AgendaNext></AgendaNext>
  </div>
</template>

<script>
import AgendaNext from "@/components/widgets/Agenda/AgendaNext.vue";

import SiteHeadingInner from "@/components/widgets/SiteHeadingInner.vue";
import SiteTextInner from "@/components/widgets/SiteTextInner.vue";

export default {
  name: "pb-agenda",
  components: {
    AgendaNext,
    SiteHeadingInner,
    SiteTextInner,
  },
  props: {
    schema: {
      type: Object,
    },
  },
  methods: {
    generateLink(link) {
      if (link.startsWith("/")) {
        return process.env.VUE_APP_MERITECMS_URL + link;
      } else {
        return link;
      }
    },
  },
};
</script>

<style>
.merite-greycontainer {
  background-color: var(--charte-color-grey-1);
  margin: 8em 0 0 0;
}

.merite-greycontainer-flex-container {
  display: flex;
}

.merite-greycontainer-flex {
  flex: 1;
}

.merite-greycontainer-flex.single {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.merite-greycontainer-flex.double {
  flex-grow: 2;
}

.merite-greycontainer-photo {
  width: 100%;
  margin: -4em 0 0 0;
  padding: 0;
}

.merite-grey-container-subtitle {
  background-color: var(--charte-color-expedition);
  margin: 0 0 0 15vw;
  padding: 1.5em 0 0.75em 0;

  text-align: center;

  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 28pt;

  text-transform: uppercase;

  color: white;
}

.merite-greycontainer-agenda-link {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 14pt;
  margin: 0 0 0 10vw;
  padding: 0;

  text-decoration: none;
  color: var(--charte-color-grey-2);
}

.merite-greycontainer-agenda-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .merite-greycontainer-flex-container {
    flex-direction: column;
  }
}
</style>
