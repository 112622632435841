<template>
  <Footer></Footer>
</template>

<script>
import Footer from "@/components/widgets/Footer.vue";

export default {
  name: "pb-footer",
  components: {
    Footer,
  },
};
</script>

<style>
</style>