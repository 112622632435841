import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import { VueReCaptcha } from "vue-recaptcha-v3";

import axios from "axios";
import VueAxios from "vue-axios";
/*
const express = require("express");
const bodyParser = require("body-parser");
const exphbs = require("express-handlebars");
*/

createApp(App)
  .use(i18n)
  .use(router)
  .use(VueAxios, axios)
  .use(VueReCaptcha, {
    siteKey: "6LeV3RscAAAAAItPdeGA4h1ymppHi2BfLZGx7Wiy",
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true,
    },
  })
  .mount("#app");
