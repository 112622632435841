<template>
  <router-view :key="$route.fullPath">
    <CookieNotice></CookieNotice>
    <div class="merite-cms-container">
      <pb :typeSchema="typeSchema"></pb>
    </div>
  </router-view>
</template>

<script>
import pb from "@/components/pagebuilder/pb.vue";
import CookieNotice from "@/components/widgets/CookieNotice.vue";
import { useRoute } from "vue-router";

export default {
  name: "app",
  components: {
    pb,
    CookieNotice,
  },
  data() {
    return {
      typeSchema: [],
    };
  },
  setup() {},
  mounted() {},
  updated() {
    const route = useRoute();
    this.typeSchema = route.params.typeSchema;
  },
};
</script>

<style>
</style>
