<template>
  <BlockImageTextOffset :title="schema.title" color="var(--charte-color-benevolat)"
    :background="generateLink(schema.image[0].formats.large.url)">
    <template v-slot:description>
      <div v-html="generateText0()"></div>
    </template>
    <template v-slot:main>
      <div class="merite-awardleader-container">
        <div class="merite-text-triple-container">
          <div class="merite-text-triple-flex">
            <div class="merite-text-triple-textbackground red">
              <h2 class="merite-text-triple-title">{{ schema.subtitle1 }}</h2>
            </div>
          </div>
          <div class="merite-text-triple-flex">
            <p class="merite-text-triple-paragraph" v-html="generateText1()"></p>
          </div>
        </div>

        <div class="merite-text-triple-container">
          <div class="merite-text-triple-flex">
            <div class="merite-text-triple-textbackground red">
              <h2 class="merite-text-triple-title">{{ schema.subtitle2 }}</h2>
            </div>
          </div>
          <div class="merite-text-triple-flex">
            <p class="merite-text-triple-paragraph" v-html="generateText2()"></p>
          </div>
        </div>
      </div>
    </template>
  </BlockImageTextOffset>
</template>

<script>
import BlockImageTextOffset from "@/components/blocks/BlockImageTextOffset.vue";
import { marked } from 'marked';

export default {
  name: "pb-leadertextblock",
  components: {
    BlockImageTextOffset,
  },
  props: {
    schema: {
      type: Object,
    },
  },
  updated() {
    this.generateText0();
    this.generateText1();
    this.generateText2();
  },
  methods: {
    generateLink(link) {
      console.log("generate link");
      if (link.startsWith("/")) {
        return process.env.VUE_APP_MERITECMS_URL + link;
      } else {
        return link;
      }
    },
    generateText0() {
      return marked(this.schema.text);
    },
    generateText1() {
      return marked(this.schema.subtext1);
    },
    generateText2() {
      return marked(this.schema.subtext2);
    },
  },
};
</script>

<style>
.merite-text-triple-container {
  margin: 8em 0 4em 0;
  display: flex;
}

.merite-text-triple-flex {
  flex: 1;
}

.merite-text-triple-flex:nth-child(2) {
  margin: 0 0 0 2em;
  flex-grow: 2;
}

.merite-text-triple-textbackground {
  padding: 1em;

  display: inline-block;
}

.merite-text-triple-textbackground.red {
  background-color: var(--charte-color-benevolat);
}

.merite-text-triple-flex .merite-text-triple-title {
  margin: 0;

  font-family: "Meta Pro";
  font-weight: 400;
  font-size: 22pt;

  color: white;
}

.merite-text-triple-paragraph {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;

  padding: 0 20em 0 0;

  color: var(--charte-color-grey-2);
}

@media only screen and (max-width: 600px) {
  .merite-text-triple-paragraph {
    padding: 0;
  }

  .merite-text-triple-container {
    display: block;
  }

  .merite-text-triple-textbackground {
    display: block;
  }

  .merite-text-triple-flex {
    margin: 0 10vw 0 10vw !important;
  }

  .merite-blockimagetext-grey-container-flex-overflow-inner {
    margin: 0 10vw 0 10vw;
  }

  .merite-blockimagetext-grey-container-flex-overflow-inner .merite-siteheading-container {
    margin-left: -10vw !important;
  }
}
</style>
