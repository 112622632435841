<template>
  <HorizontalCardContainer>
    <HorizontalCard
      v-for="(card, index) in schema.card"
      :key="index"
      :textTitle="card.title"
      :textParagraph="card.text"
      :imageLocation="generateLink(card.image.formats.large.url)"
      :linksArray="card.links"
    ></HorizontalCard>
  </HorizontalCardContainer>
</template>

<script>
import HorizontalCardContainer from "@/components/widgets/HorizontalCardContainer.vue";
import HorizontalCard from "@/components/widgets/HorizontalCard.vue";

export default {
  name: "pb-cards",
  components: {
    HorizontalCardContainer,
    HorizontalCard,
  },
  props: {
    schema: {
      type: Object,
    },
  },
  methods: {
    generateLink(link) {
      console.log("generate link");
      if (link.startsWith("/")) {
        return process.env.VUE_APP_MERITECMS_URL + link;
      } else {
        return link;
      }
    },
  },
};
</script>

<style></style>
