<template>
  <div class="pb-donors">
    <Container>
      <div class="merite-supportus-donors-container">
        <div class="merite-supportus-donors-flex">
          {{ $t("supportus-label-2") }}
          <img src="/assets/logos/ministere.png" class="merite-support-donors-image" />
        </div>
        <div class="merite-supportus-donors-flex">
          {{ $t("supportus-label-3") }}
          <img src="/assets/logos/bgl.png" class="merite-support-donors-image" />
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "@/components/widgets/Container.vue";

export default {
  name: "pb-donors",
  components: {
    Container,
  },
};
</script>

<style>
.merite-supportus-donors-container {
  display: flex;
}

.merite-supportus-donors-flex {
  flex: 1;
  border-left: 1px solid var(--charte-color-residentiel);

  padding: 2em 2em 1em 2em;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;
}

.merite-supportus-donors-flex:nth-child(1) {
  margin: 0 1em 0 0;
}

.merite-supportus-donors-flex:nth-child(2) {
  margin: 0 0 0 1em;
}

.merite-support-donors-image {
  margin: 2em 0 0 0;
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .merite-supportus-donors-container {
    display: block;
  }

  .merite-supportus-donors-flex {
    margin: 0 0 2em 0 !important;
  }
}
</style>