<template>
  <transition>
    <div class="merite-overlay-container" v-if="overlayVisible" @keyup="navigateKeys">
      <div class="merite-overlay-close" v-on:click="overlayVisible = false">X</div>
      <div class="merite-overlay-image">
        <div class="merite-overlay-navigation lt" @click="overlayPreviousImage()">&lt;</div>
        <div class="merite-overlay-navigation gt" @click="overlayNextImage()">&gt;</div>
        <img :src="getCurrentImage" />
      </div>
    </div>
  </transition>

  <div class="gallery-container">
    <div class="merite-gallery-navigation lt" v-on:click="galleryPageDown()" v-if="part > 0">
      <img src="/assets/icons/chevron_down.svg" />
    </div>
    <div class="merite-gallery-navigation gt" v-on:click="galleryPageUp()" v-if="showNext()">
      <img src="/assets/icons/chevron_down.svg" />
    </div>
    <Container>
      <div class="merite-gallery-container" v-if="!isMobile()">
        <div class="merite-gallery-image-container">
          <div class="merite-gallery-image-item" v-for="galleryImage in getGalleryImages" :key="galleryImage">
            <div
              v-if="galleryImage != null"
              class="merite-gallery-image"
              v-bind:style="{ backgroundImage: 'url(https://mcms.imagify.design' + galleryImage[1].formats.medium.url + ')' }"
              v-on:click="showImage(galleryImage[0])"
            ></div>
          </div>
        </div>

        <div class="merite-gallery-image-container">
          <div class="merite-gallery-image-item" v-for="galleryImage in getGalleryImagesNext" :key="galleryImage">
            <div
              v-if="galleryImage != null"
              class="merite-gallery-image"
              v-bind:style="{ backgroundImage: 'url(https://mcms.imagify.design' + galleryImage[1].formats.medium.url + ')' }"
              v-on:click="showImage(galleryImage[0])"
            ></div>
          </div>
        </div>
      </div>
    </Container>

    <div class="merite-gallery-mobile-container" v-if="isMobile()">
      <div class="merite-gallery-image-item-mobile" v-for="galleryImage in getGalleryImagesAll" :key="galleryImage">
        <div
          v-if="galleryImage != null"
          class="merite-gallery-image-mobile"
          v-bind:style="{ backgroundImage: 'url(https://mcms.imagify.design' + galleryImage[1].formats.medium.url + ')' }"
          v-on:click="showImage(galleryImage[0])"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockText from "@/components/blocks/BlockText.vue";
import Container from "@/components/widgets/Container.vue";

export default {
  name: "pb-blocktext",
  components: {
    BlockText,
    Container,
  },
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      overlayVisible: false,
      pbblocktextcolor: "red",
      part: 0,
      selectedImage: 0,
    };
  },
  mounted() {},
  updated() {},
  computed: {
    getGalleryImages() {
      let part = this.part;
      let galleryMax = 4;
      let galleryMaxFull = 8;
      let galleryData = this.schema.images;
      let galleryDataPart = [];

      for (var i = part * galleryMaxFull; i < (part + 1) * galleryMaxFull - galleryMax; i++) {
        if (i > galleryData.length - 1) {
          galleryDataPart.push([i, { formats: { medium: { url: "" } } }]);
        } else {
          galleryDataPart.push([i, galleryData[i]]);
        }
      }

      return galleryDataPart;
    },
    getGalleryImagesNext() {
      let part = this.part;
      let galleryMax = 4;
      let galleryMaxFull = 8;
      let galleryData = this.schema.images;
      let galleryDataPart = [];

      for (var i = part * galleryMaxFull + galleryMax; i < (part + 1) * galleryMaxFull; i++) {
        if (i > galleryData.length - 1) {
          galleryDataPart.push([i, { formats: { medium: { url: "asd" } } }]);
        } else {
          galleryDataPart.push([i, galleryData[i]]);
        }
      }

      return galleryDataPart;
    },
    getGalleryImagesAll() {
      let galleryData = this.schema.images;
      let galleryDataPart = [];

      for (var i = 0; i < galleryData.length; i++) {
        galleryDataPart.push([i, galleryData[i]]);
      }

      return galleryDataPart;
    },
    getCurrentImage() {
      return "https://mcms.imagify.design" + this.schema.images[this.selectedImage].formats.large.url;
    },
  },
  mounted() {
    document.addEventListener("keyup", this.navigateKeys);
  },
  methods: {
    isMobile() {
      if (window.innerWidth < 600) {
        return true;
      } else {
        return false;
      }
    },
    galleryPageUp() {
      let maxParts = this.schema.images.length / 8;
      if (this.part < Math.floor(maxParts)) {
        this.part++;
      }
    },
    galleryPageDown() {
      if (this.part > 0) {
        this.part--;
      }
    },
    showNext() {
      let maxParts = this.schema.images.length / 8;
      if (this.part < Math.floor(maxParts)) {
        return true;
      } else {
        return false;
      }
    },
    showImage(image) {
      this.selectedImage = image;
      this.overlayVisible = true;
    },
    overlayNextImage() {
      if (this.selectedImage < this.schema.images.length - 1) {
        this.selectedImage++;
      }
    },
    overlayPreviousImage() {
      if (this.selectedImage > 0) {
        this.selectedImage--;
      }
    },

    navigateKeys(event) {
      if (event.keyCode == 37) {
        this.overlayPreviousImage();
      } else if (event.keyCode == 39) {
        this.overlayNextImage();
      }
    },
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.merite-gallery-container {
  margin: 5vh 0 5vh 0;
}

.merite-gallery-image-container {
  display: flex;
}

.merite-gallery-image-item {
  width: 25%;

  height: 20vh;

  padding: 0.5vw;
}

.merite-gallery-image {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.merite-overlay-container {
  background-color: rgb(25, 25, 25);

  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 500;
}

.merite-overlay-close {
  padding: 1.5vw;
  color: white;

  position: absolute;
  top: 0;
  right: 0;

  font-family: sans-serif;

  background-color: rgb(35, 35, 35);
  cursor: pointer;
}

.gallery-container {
  position: relative;
}

.merite-gallery-navigation {
  position: absolute;
  top: 50%;
  cursor: pointer;
}

.merite-gallery-navigation.lt {
  left: 10vw;
}

.merite-gallery-navigation.gt {
  right: 10vw;
}

.merite-gallery-navigation.lt img {
  transform: rotateZ(90deg);
  height: 4vh;
}

.merite-gallery-navigation.gt img {
  transform: rotateZ(-90deg);
  height: 4vh;
}

.merite-overlay-image {
}

.merite-overlay-image img {
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  max-width: 80%;

  max-height: 80%;
}

.merite-overlay-navigation {
  position: absolute;
  top: 50%;
  cursor: pointer;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.merite-overlay-navigation.lt {
  left: 5vw;
  color: white;
  font-size: 20pt;
}

.merite-overlay-navigation.gt {
  right: 5vw;
  color: white;
  font-size: 20pt;
}

@media only screen and (max-width: 600px) {
  .merite-gallery-image-container {
    display: block;
    overflow-x: scroll;
  }

  .merite-gallery-image-item {
    width: 100%;
    height: 40vh;
  }

  .merite-gallery-navigation {
    display: none;
  }
}

.merite-gallery-mobile-container {
  display: block;
  white-space: nowrap;
  overflow-x: scroll;
  margin-bottom: 10vh;
}
.merite-gallery-image-item-mobile:first-child {
  margin-left: 10vw;
}
.merite-gallery-image-item-mobile:last-child {
  margin-right: 10vw;
}
.merite-gallery-image-item-mobile {
  display: inline-block;
  width: 80vw;
  height: 50vh;
  margin-right: 10px;
}
.merite-gallery-image-mobile {
  width: 100%;
  height: 100%;

  background-position: center;
  background-size: cover;
}
</style>
