<template>
  <div class="merite-text-container">
    <div :id="uid" class="merite-text-inner-container animate__animated">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import AnimationScroller from "@/lib/AnimationScroller";

let uuid = 0;
export default {
  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
  name: "SiteText",
  mixins: [AnimationScroller],
  props: {},
  data() {
    return {
      uid: "merite-text-" + this.uuid,
    };
  },
  mounted() {
    let element = $("#merite-text-" + this.uuid);
    this.viewportAnimationScroller(element, "animate__fadeInUp");
  },
};
</script>

<style>
.merite-text-container {
  max-width: 50%;
}

.merite-text-inner-container {
  margin: 0em 1em 4em 15vw;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  white-space: pre-line;
}

/* Responsive design */
@media only screen and (max-width: 600px) {
  .merite-text-container {
    max-width: 100%;
  }

  .merite-text-inner-container {
    margin: 0em 15vw 4em 15vw;
  }
}
</style>