import { createRouter, createWebHistory } from "vue-router";

let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, "$1");

const defaultRoutes = [
  {
    path: "/",
    name: "home",
    component: () => import("../App.vue"),
  },
  {
    path: "/participants",
    name: "participants",
    component: () => import("../App.vue"),
  },
  {
    path: "/awardleaders",
    name: "awardleaders",
    component: () => import("../App.vue"),
  },
  {
    path: "/awardunits",
    name: "awardunits",
    component: () => import("../App.vue"),
  },
  {
    path: "/awardunitsjoin",
    name: "awardunitsjoin",
    component: () => import("../App.vue"),
  },
  {
    path: "/meritejeunesse",
    name: "meritejeunesse",
    component: () => import("../App.vue"),
  },
  {
    path: "/resourcecenter",
    name: "resourcecenter",
    component: () => import("../App.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../App.vue"),
  },
  {
    path: "/supportus",
    name: "supportus",
    component: () => import("../App.vue"),
  },
  {
    path: "/agenda",
    name: "agenda",
    component: () => import("../App.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../App.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../App.vue"),
  },
  {
    path: "/legal",
    name: "legal",
    component: () => import("../App.vue"),
  },
  {
    path: "/alumni",
    name: "alumni",
    component: () => import("../App.vue"),
  },
];

let routes = defaultRoutes;

var router = createRouter({
  history: createWebHistory(locale.trim().length && locale != "/" ? "/" + locale : undefined),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 };
  },
});

router.typeSchema = [];

fetch(process.env.VUE_APP_MERITECMS_URL + "page-news?_locale=" + locale)
  .then((res) => res.json())
  .then((data) => {
    for (var i = 0; i < data.length; i++) {
      var pageDesc = data[i].description;

      router.addRoute({ path: "/news/" + pageDesc, name: "/news/" + pageDesc, component: () => import("../App.vue") });
    }
  });

router.beforeEach((to, from, next) => {
  let locale = window.location.pathname.replace(/^\/([^\/]+).*/i, "$1");
  var targetpage = to.name;

  if (targetpage == undefined) {
    targetpage = window.location.pathname.split("/");
    targetpage = targetpage[targetpage.length - 1];
    console.log(targetpage);
  }

  fetch(process.env.VUE_APP_MERITECMS_URL + "pages?_locale=" + locale)
    .then((res) => res.json())
    .then((data) => {
      var pagefound = false;
      for (var p = 0; p < data.length; p++) {
        var page = data[p];
        if (page.description == targetpage) {
          pagefound = true;

          // Find components
          var components = page.main;
          var typeSchema = [];
          for (var c = 0; c < components.length; c++) {
            let comp = components[c].__component.substring(13);
            while (comp.indexOf("-") > -1) {
              console.log(comp);
              comp = comp.replace("-", "");
            }
            components[c].type = comp;
            typeSchema.push(components[[c]]);
          }

          to.params.typeSchema = typeSchema;
          console.log(typeSchema);
          next();
        }
      }
      if (pagefound == false) {
        fetch(process.env.VUE_APP_MERITECMS_URL + "page-news?_locale=" + locale)
          .then((res) => res.json())
          .then((data) => {
            var pagefound = false;
            for (var p = 0; p < data.length; p++) {
              var page = data[p];
              if (page.description == targetpage) {
                console.log("found news page");
                pagefound = true;

                // Find components
                var components = page.main;
                var typeSchema = [];
                for (var c = 0; c < components.length; c++) {
                  components[c].type = components[c].__component.substring(13).replace("-", "");
                  typeSchema.push(components[[c]]);
                }

                to.params.typeSchema = typeSchema;

                next();
              }
            }
            if (pagefound == false) {
              console.log("Error 404");
              next();
            }
          });
      }
    });
});

if (window.location.pathname == "" || window.location.pathname == "/" || (locale != "lu" && locale != "en" && locale != "fr")) {
  window.location.href = "/lu/";
}

export default router;
