<template>
  <div class="merite-agenda-popup-container">
    <div class="merite-agenda-popup-close" @click="this.$emit('closePopup')">x</div>

    <h2 class="merite-agenda-popup-date">{{ date }}</h2>
    <h2 class="merite-agenda-popup-title">{{ title }}</h2>
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: "AgendaPopup",
  props: {
    title: {
      type: String,
      default: "undefined",
    },
    date: {
      type: String,
      default: "undefined",
    },
    text: {
      type: String,
      default: "undefined",
    },
  },
};
</script>

<style>
.merite-agenda-popup-container {
  position: fixed;

  left: 50%;
  top: 50%;

  width: 25vw;

  padding: 2em;

  transform: translate(-50%, -50%);

  background-color: white;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);

  z-index: 500;
}

.merite-agenda-popup-container {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  color: #3c3c3b;

  white-space: pre-line;
}

.merite-agenda-popup-container h3 {
  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 14pt;

  margin: 0;

  color: #3c3c3b;
}

.merite-agenda-popup-container p {
  margin: 0;
}
.merite-agenda-popup-date {
  margin: 1em 0 0 0;
  padding: 0;

  font-family: "Meta Pro";
  font-weight: 100;
  font-size: 28pt;

  color: #3c3c3b;
}

.merite-agenda-popup-title {
  margin: 0.1em 0 0 0;
  padding: 0 0 0.5em 0;

  font-family: "Meta Pro";
  font-weight: 500;
  font-size: 28pt;
  line-height: 30pt;

  color: #3c3c3b;
}

.merite-agenda-popup-exerp {
  margin: 2em 0 2em 0;
  padding: 0;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  color: #3c3c3b;
}

.merite-agenda-popup-close {
  position: absolute;

  right: 0;
  top: 0;

  width: 2.5em;
  height: 2.5em;

  background-color: var(--charte-color-grey-2);

  display: flex;

  align-items: center;
  justify-content: center;

  color: white;
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 16pt;
}
.merite-agenda-popup-close:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .merite-agenda-popup-container {
    left: 10%;
    width: 80%;

    transform: translate(-5%, -50%);

    height: 90%;
    overflow-y: scroll;
  }

  .merite-agenda-popup-title {
    word-wrap: break-word;
  }
}
</style>