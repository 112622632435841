<template>
  <div class="merite-blockvolet-clean">
    <div class="merite-blockvolet-clean-inner" :style="border" @click="descriptionOpen = !descriptionOpen">
      <h2 class="merite-blockvolet-clean-inner-title">{{ title }}</h2>

      <img src="/assets/icons/chevron_down.svg" class="merite-blockvolet-clean-inner-chevron" :class="{ chevronRotate: descriptionOpen }" />
    </div>

    <div class="merite-blockvolet-clean-content" :style="borderFull" :class="{ voletActiveClean: descriptionOpen }">
      <slot v-if="descriptionOpen"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockVolet",
  props: {
    title: {
      type: String,
      default: "#ff0000",
    },
    color: {
      type: String,
      default: "#ff0000",
    },
  },
  data() {
    return {
      descriptionOpen: false,
      show: true,
    };
  },
  computed: {
    border() {
      return {
        "border-left": "2px solid " + this.color,
      };
    },
    borderFull() {
      return {
        "border-left": "2px solid " + this.color,
        "border-bottom": "2px solid " + this.color,
      };
    },
  },
};
</script>

<style>
.merite-blockvolet-clean {
  margin: 2em 15vw 2em 15vw;
  overflow: hidden;
}

.merite-blockvolet-clean-inner:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.merite-blockvolet-clean-inner-icon {
  margin: 0 0 0 2em;
  width: 100px;
  height: 3.5em;
}

.merite-blockvolet-clean-inner-title {
  padding: 0.5em 0 0.5em 0;
  margin: 0 0 0 1em;

  width: 80%;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 14pt;

  color: var(--charte-color-grey-2);
}

.merite-blockvolet-clean-inner {
  padding: 0.5em 0 0.5em 0;

  position: relative;
  display: flex;
  align-items: center;

  background-color: var(--charte-color-grey-1);

  transition: background-color 200ms;

  z-index: 100;
}

.merite-blockvolet-clean-content {
  margin: 0;

  padding: 0 2em 0 2em;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;

  overflow-y: visible;

  transition: all 200ms;

  white-space: pre-line;
}

.voletActiveClean {
  padding: 2em;
}

.descriptionOpen {
  background-color: red;
}

.merite-blockvolet-clean-content h3 {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 14pt;
}

.merite-blockvolet-clean-inner-chevron {
  width: 2em;

  position: absolute;
  right: 0;

  margin-right: 2em;
  transition: transform 400ms;
}

.chevronRotate {
  transform: rotateZ(180deg);
}
</style>