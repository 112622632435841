<template>
  <div class="pb-meriteconseil">
    <div class="merite-conseil-table-container">
      <div class="merite-conseil-table-flex">
        <table cellspacing="0">
          <tr>
            <td>{{ $t("team-conseil-0") }}</td>
            <td>{{ $t("team-conseil-1") }}</td>
          </tr>
          <tr>
            <td>Georges Metz</td>
            <td>{{ $t("team-conseil-2") }}</td>
          </tr>
          <tr>
            <td>Claude Baumann</td>
            <td>{{ $t("team-conseil-3") }}</td>
          </tr>
          <tr>
            <td>Pierre Matagne</td>
            <td>{{ $t("team-conseil-4") }}</td>
          </tr>
          <tr>
            <td>Gilles Bley</td>
            <td>{{ $t("team-conseil-5") }}</td>
          </tr>
          <tr>
            <td>Maurits van Rijckevorsel</td>
            <td>{{ $t("team-conseil-8") }}</td>
          </tr>
        </table>
      </div>
      <div class="merite-conseil-table-flex">
        <table cellspacing="0">
          <!--
          <tr>
            <td>Martine Deprez</td>
            <td>{{ $t("team-conseil-7") }}</td>
          </tr>
          -->
          <tr>
            <td>Djuna Bernard</td>
            <td>{{ $t("team-conseil-9") }}</td>
          </tr>
          <tr>
            <td>Nathalie Felten</td>
            <td>{{ $t("team-conseil-11") }}</td>
          </tr>
          <tr>
            <td>Carmen Schares</td>
            <td>{{ $t("team-conseil-10") }}</td>
          </tr>
          <tr>
            <td>Marc Crochet</td>
            <td>{{ $t("team-conseil-6") }}</td>
          </tr>
          <tr>
            <td>Yves Legil</td>
            <td>{{ $t("team-conseil-12") }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pb-meriteconseil",
};
</script>

<style>
.merite-conseil-table-container {
  display: flex;
  margin: 0 15vw 4em 15vw;
}

.merite-conseil-table-flex {
  flex: 1;
}

.merite-conseil-table-flex table {
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;

  color: var(--charte-color-grey-2);

  width: 100%;

  margin: 0;
  padding: 0;
}

.merite-conseil-table-flex td {
  width: 50%;
  line-height: 16pt;
  padding: 1em 2em 1em 2em;
}

.merite-conseil-table-flex td:nth-child(1) {
  color: var(--charte-color-talent);
  font-weight: 400;
}

.merite-conseil-table-flex tr:nth-child(even) {
  background-color: var(--charte-color-grey-1);
}

@media only screen and (max-width: 600px) {
  .merite-conseil-table-container {
    flex-direction: column;
  }

  .merite-conseil-table-container {
    margin: 0 0 4em 0;
  }
}
</style>
