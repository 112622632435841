<template>
  <div class="merite-participants-subtext">
    <div v-html="generateText()"></div>
  </div>
</template>

<script>
import { marked } from 'marked';

export default {
  name: "pb-participantstext",
  components: {},
  props: {
    schema: {
      type: Object,
    },
  },
  updated() {
    this.generateText();
  },
  methods: {
    generateText() {
      return marked(this.schema.text);
    },
  },
};
</script>

<style>
.merite-participants-subtext {
  background-color: var(--charte-color-grey-1);

  padding: 2em 4em 2em 4em;
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  padding-right: 50%;

  margin: 0 0 0 15vw;
}

@media only screen and (max-width: 600px) {
  .merite-participants-subtext {
    background-color: var(--charte-color-grey-1);

    padding: 2em 10vw 2em 10vw;
    margin: 0 0 0 0;
  }
}
</style>