<template>
  <div class="merite-outer-container">
    <div class="merite-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {},
};
</script>

<style>
.merite-outer-container {
  display: flex;
  width: 100%;
}

.merite-container {
  margin: 0 15vw 0 15vw;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .merite-container {
    margin: 0 10vw 0 10vw;
  }
}
</style>