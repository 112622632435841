<template>
  <SiteHeading :uid="schema.id" :title="schema.title" :color="pbheadingcolor" animation="animate__fadeInLeft" />
</template>

<script>
import SiteHeading from "@/components/widgets/SiteHeading.vue";

export default {
  name: "pb-heading",
  components: {
    SiteHeading,
  },
  props: {
    schema: {
      type: Object,
    },
  },
  data() {
    return {
      pbheadingcolor: "red",
    };
  },
  mounted() {
    this.generateColor();
  },
  updated() {
    this.generateColor();
  },
  methods: {
    generateColor() {
      if (this.schema.color == "benevolat") {
        this.pbheadingcolor = "var(--charte-color-benevolat)";
      } else if (this.schema.color == "talent") {
        this.pbheadingcolor = "var(--charte-color-talent)";
      } else if (this.schema.color == "sport") {
        this.pbheadingcolor = "var(--charte-color-sport)";
      } else if (this.schema.color == "residentiel") {
        this.pbheadingcolor = "var(--charte-color-residentiel)";
      } else if (this.schema.color == "expedition") {
        this.pbheadingcolor = "var(--charte-color-expedition)";
      } else {
        this.pbheadingcolor = "var(--charte-color-talent)";
      }
    },
  },
};
</script>

<style>
</style>